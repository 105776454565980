import React, { useState, useMemo, useEffect } from 'react';
import {
  Checkbox,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Tooltip
} from 'antd';
import intl from 'react-intl-universal';
import { connect } from 'dva';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';

import styles from './AgencyGroup.less';
import { IconButton } from '../IconButton';
import currencySymbolMap from 'common/currencySymbolMap';
import { AGENCY_NAME_TO_PLATFORM } from 'utils/constants';
import { Post } from 'utils/request';
import {
  AGENCY_EVENT_ROLLING_PAYMENT_STATUS,
  AGENCY_LOCAL_STORE_PAYMENT_ROLLING_TASK
} from '../constants';
import DSersAlert from '../DSersAlert';

const PaymentButton = props => {
  return (
    <IconButton type="primary" {...props} className={styles.paymentButton}>
      <img
        width="99"
        height="24"
        src="https://img.dsers.com/shopify/order/makepay/paypal.png"
      />
    </IconButton>
  );
};

const StripePaymentButton = props => {
  return (
    <IconButton type="primary" {...props} className={styles.stripeButton}>
      <img
        // width="99"
        height="28"
        src="https://img.dsers.com/shopify/order/makepay/stripe.png"
      />
    </IconButton>
  );
};

const OrderItem = React.memo(
  ({ data, selected, onSelectChange, paymentCurrency, disabled }) => {
    return (
      <React.Fragment>
        {data.items.map((sp, index) => {
          const shippingMethod =
            sp.freights?.find(i => i.serviceName === sp.logistics_company)
              ?.company || 'Shipping method';

          return (
            <tr key={index}>
              {index === 0 ? (
                <td rowSpan={data.orderNameRowSpan}>
                  {
                    <Checkbox
                      value={data.id}
                      checked={selected}
                      onChange={onSelectChange}
                      disabled={disabled}
                    />
                  }
                </td>
              ) : null}
              {index === 0 ? (
                <td rowSpan={data.orderNameRowSpan}>{data.orderName}</td>
              ) : null}
              <td>
                <img src={sp.sku_img} width="64" height="64" />
              </td>
              <td>
                {currencySymbolMap[sp.incomeCurrency]} {sp.income}
              </td>
              <td
                className={classNames({ [styles.error]: !parseFloat(sp.cost) })}
              >
                {currencySymbolMap[paymentCurrency]} {sp.cost}
              </td>
              <td>{data.country}</td>
              <td>{shippingMethod}</td>
              <td
                className={classNames({
                  // [styles.error]: !parseFloat(sp.freight_cost)
                })}
              >
                {currencySymbolMap[paymentCurrency]} {sp.freight_cost}
              </td>
              <td>
                {sp.shipping_time
                  ? `${sp.shipping_time} ${intl.get(
                      'order.order_product.shipping_dialog.days'
                    )}`
                  : '--'}
              </td>
              <td>{sp.platform_order_id}</td>
            </tr>
          );
        })}
      </React.Fragment>
    );
  }
);

const TableSummary = React.memo(({ data, paymentCurrency }) => {
  return (
    <tr className={styles.summaryRow}>
      <td colSpan="2">{data?.totalItemCount} items</td>
      <td colSpan="2">
        {data.diffCurrencyIncomes.map(i => {
          return (
            <p key={i.currency}>
              {currencySymbolMap[i.currency]} {i.income.toFixed(2)}
            </p>
          );
        })}
      </td>
      <td>
        {currencySymbolMap[paymentCurrency]} {data.productCost.toFixed(2)}
      </td>
      <td colSpan="2">
        <p style={{ height: 48 }}></p>
      </td>
      <td>
        {currencySymbolMap[paymentCurrency]} {data.shippingCost.toFixed(2)}
      </td>
      <td colSpan="2" style={{ textAlign: 'right' }}>
        {intl.get('order.confirm.total_cost')}:{' '}
        {currencySymbolMap[paymentCurrency]} {data.totalCost.toFixed(2)}
      </td>
    </tr>
  );
});

const MakePaymentAgencyGroup = ({
  data,
  agencyList,
  showRefreshModal,
  onPaymentStart,
  onlyShowOrderDetail = false,
  initPaymentAmount = '0'
}) => {
  const [paymentAmount, setPaymentAmount] = useState(initPaymentAmount);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showEditAmount, setShowEditAmount] = useState(false);

  const handleSelectedChange = e => {
    const { checked, value } = e.target;
    if (checked) {
      if (selectedOrders.includes(value)) return;
      const newSelectedOrders = [...selectedOrders];
      newSelectedOrders.push(value);
      setSelectedOrders(newSelectedOrders);
    } else {
      const selectedIndex = selectedOrders.findIndex(i => i === value);
      if (selectedIndex > -1) {
        const newSelectedOrders = [...selectedOrders];
        newSelectedOrders.splice(selectedIndex, 1);
        setSelectedOrders(newSelectedOrders);
      }
    }
  };

  const agencyInfo = useMemo(() => {
    console.log(agencyList?.find(i => `${i.id}` === `${data.agency_id}`));
    return agencyList?.find(i => `${i.id}` === `${data.agency_id}`);
  }, [data.agency_id, agencyList]);

  const columns = useMemo(() => {
    return [
      { title: '' },
      { title: intl.get('order.confirm.table_title.order') },
      { title: intl.get('order.confirm.table_title.product') },
      { title: intl.get('order.confirm.table_title.income') },
      { title: intl.get('order.confirm.table_title.product_cost') },
      { title: intl.get('order.confirm.table_title.country') },
      { title: intl.get('order.confirm.table_title.shipping_method') },
      { title: intl.get('order.confirm.table_title.shipping_fee') },
      { title: intl.get('order.confirm.table_title.shipping_time') },
      { title: intl.get('order.search_type.agency_order_no') }
    ];
  }, []);

  const orderRenderData = useMemo(() => {
    return data.data.map(i => {
      let income = 0;
      let productCost = new BigNumber(0);
      let shippingCost = new BigNumber(0);
      let costFromOrder = new BigNumber(0);
      const { AgencyOrders } = i;
      // console.log(data, '分组数据');

      const agencyOrderMap = {};
      const countedAgencyOrderMap = {};

      AgencyOrders?.forEach(i => {
        if (i.Status === 5) {
          return;
        }
        agencyOrderMap[i.Id] = {
          ...i,
          manuallyUploadShippingCost: !!i?.Items?.find(item => {
            return item.IsImportPayment;
          })
        };
      });

      const items = i?.paymentChildren?.reduce((prev, child) => {
        child?.sp?.forEach((spItem, index) => {
          if (
            (spItem.status === 3 ||
              (spItem.status >= 3 && onlyShowOrderDetail)) &&
            spItem.platform_order_id
          ) {
            prev.push({
              ...spItem,
              income: child.count,
              incomeCurrency: i.currency,
              cost: spItem.cost || '0',
              shipping_time: spItem?.freights?.find(
                shipping => shipping.serviceName === spItem.logistics_company
              )?.time
            });
            productCost = productCost.plus(
              BigNumber(spItem.cost || '0').times(spItem.quantity)
            );

            const agencyOrderInfo = agencyOrderMap[spItem.platform_order_id];

            if (agencyOrderInfo && agencyOrderInfo.manuallyUploadShippingCost) {
              shippingCost = shippingCost.plus(BigNumber(spItem.freight_cost));
            } else if (
              agencyOrderInfo &&
              !countedAgencyOrderMap[spItem.platform_order_id]
            ) {
              shippingCost = shippingCost.plus(
                BigNumber(agencyOrderInfo.ShippingFee || '0')
              );

              costFromOrder = costFromOrder.plus(
                BigNumber(agencyOrderInfo.OrderFee || '0')
              );
              countedAgencyOrderMap[spItem.platform_order_id] = true;
            }

            // productCost += spItem.quantity * spItem.cost;
            // shippingCost += 1 * spItem.freight_cost;
          }
        });
        income += 1 * child.count;
        return prev;
      }, []);
      const orderNameRowSpan = items.length;
      const orderName = i?.orderNumber;
      const cost = productCost.plus(shippingCost);
      return {
        orderName,
        orderNameRowSpan,
        country: i.ext?.address?.country,
        items,
        id: i.id,
        income,
        cost,
        shippingCost,
        productCost,
        incomeCurrency: i.currency,
        costFromOrder
      };
    });
  }, [data.data]);

  const selectAllProps = useMemo(() => {
    return {
      indeterminate:
        selectedOrders.length && selectedOrders.length < orderRenderData.length,
      checked: selectedOrders.length === orderRenderData.length
    };
  }, [selectedOrders, orderRenderData]);

  const handleSelectAll = e => {
    const { checked } = e.target;
    if (checked) {
      setSelectedOrders(orderRenderData.map(i => i.id));
    } else {
      setSelectedOrders([]);
    }
  };

  const summaryData = useMemo(() => {
    const diffCurrencyIncomes = [];
    let shippingCost = BigNumber(0);
    let productCost = BigNumber(0);
    let totalItemCount = 0;
    let totalCost = BigNumber(0);

    orderRenderData.forEach(i => {
      if (!selectedOrders.includes(i.id)) {
        return;
      }
      console.log(i);
      //   shippingCost += i.shippingCost;
      shippingCost = shippingCost.plus(i.shippingCost);
      //   productCost += i.productCost;
      productCost = productCost.plus(i.productCost);

      totalCost = totalCost.plus(i.costFromOrder);

      totalItemCount += i.items?.length || 0;
      const currencyExsits = diffCurrencyIncomes.find(
        incomeItem => incomeItem.currency === i.incomeCurrency
      );
      if (currencyExsits) {
        currencyExsits.income += i.income;
      } else {
        diffCurrencyIncomes.push({
          currency: i.incomeCurrency,
          income: i.income
        });
      }
    });
    // const totalCost = shippingCost.plus(productCost);
    return {
      diffCurrencyIncomes,
      shippingCost,
      productCost,
      totalCost,
      totalItemCount
    };
  }, [selectedOrders, orderRenderData]);

  useEffect(() => {
    // 初始化全选
    setSelectedOrders(orderRenderData.map(i => i.id));
  }, []);

  useEffect(() => {
    if (!onlyShowOrderDetail) {
      setPaymentAmount(summaryData?.totalCost?.toFixed(2));
    }
  }, [summaryData?.totalCost]);

  const paymentCurrency = useMemo(() => {
    return data.agencyCurrencys?.[0];
  }, []);

  const checkStripeAuthStatus = () => {
    return agencyInfo.stripe_status === 1;
  };

  const handlePayment = async (paymentMethod = 'paypal') => {
    if (paymentLoading) return;
    if (paymentMethod === 'stripe' && !agencyInfo.stripe_account_email) {
      Modal.confirm({
        icon: null,
        title: intl.get('setting.agency.agencyNoAuthModalTitle'),
        content: intl.get('setting.agency.agencyNoAuthModalContent'),
        okText: intl.get('setting.agency.agencyNoAuthModalGetSupport'),
        cancelButtonProps: { style: { display: 'none' } },
        centered: true,
        onOk: () => {
          window.open('https://www.messenger.com/t/DSersOfficial', 'blank');
        }
      });
      return;
    }

    // if (paymentMethod === 'paypal' && !agencyInfo?.pay_pal) {
    //   Modal.confirm({
    //     iconType: 'info',
    //     title: '尚未设置该Agency Paypal收款账号',
    //     content:
    //       '您尚未为该Agency设置Paypal收款账号，请在setting中设置后即可使用Paypal向您的Agency付款。如有更多疑问，请联系DSers支持团队'
    //   });
    //   return;
    // }

    setPaymentLoading(true);
    const textMap = {
      paypal: 'PayPal',
      stripe: 'Stripe'
    };
    try {
      const platformType =
        AGENCY_NAME_TO_PLATFORM[agencyInfo.agency_type] || 13;
      const agencyId = `${agencyInfo.id}`;
      const actionOrders = [];
      const agencyOrderIdMap = {};
      const amount = BigNumber(paymentAmount)
        .times(100)
        .toFixed();
      orderRenderData.forEach(i => {
        if (!selectedOrders.includes(i.id)) return;
        i.items?.forEach(paymentSpItem => {
          const orderId = paymentSpItem.platform_order_id;
          if (agencyOrderIdMap[orderId]) {
            return;
          } else {
            agencyOrderIdMap[orderId] = true;
            actionOrders.push({ order_id: orderId });
          }
        });
      });

      const params = {
        orders: actionOrders,
        // amount: amount,
        agency_id: agencyId,
        platform: platformType,
        payment_method: paymentMethod
      };

      const inPaymentUIParams = {
        agencyInfo: { ...agencyInfo },
        orders: [...selectedOrders],
        amount: paymentAmount,
        currency: paymentCurrency,
        start_at: +new Date()
      };

      console.log(params);

      //   onPaymentStart(inPaymentUIParams, params);
      //   setSelectedOrders([]);

      const res = await Post('/supply/order/make-payment', {
        data: params,
        cancelMessage: true
      });

      if (res.code === 2000) {
        if (res.data.payment_id && res.data.redirect_url) {
          onPaymentStart(inPaymentUIParams, params);
          setSelectedOrders([]);
          // const currentPaymentId = localStorage.getItem(
          //   AGENCY_LOCAL_STORE_PAYMENT_ROLLING_TASK
          // );
          // if (
          //   !currentPaymentId &&
          //   parseInt(currentPaymentId) < parseInt(res.data.payment_id)
          // ) {
          //   localStorage.setItem(
          //     AGENCY_LOCAL_STORE_PAYMENT_ROLLING_TASK,
          //     res.data.payment_id
          //   );
          // }
          window.EVENT.emit(AGENCY_EVENT_ROLLING_PAYMENT_STATUS);
          if (paymentMethod === 'stripe') {
            window.open(res.data.redirect_url + '&from=agency_order', 'blank');
          } else {
            window.open(res.data.redirect_url, 'blank');
          }
        }
      } else {
        message.error(
          `Failed to create payment in ${textMap[paymentMethod] ||
            'PayPal'}, please try again.`
        );
      }
    } catch (error) {
      console.log(error);
      message.error(
        `Failed to create payment in ${textMap[paymentMethod] ||
          'PayPal'}, please try again.`
      );
    }
    setPaymentLoading(false);
  };

  const handleEditAgency = () => {
    window.open('/app/settings?id=14', 'blank');
    showRefreshModal();
  };

  const handlePaymentAmountChange = e => {
    console.log(e);
    // const { value } = e.target;
    // console.log(value);
    // if(!parseFloat(value)) {
    //     setPaymentAmount('0.00');
    // } else {
    //     setPaymentAmount(value);
    // }
    if (parseFloat(e)) {
      setPaymentAmount(parseFloat(e).toFixed(2));
    } else {
      setPaymentAmount('0.00');
    }
  };

  return (
    <div
      className={classNames(styles.agencyGroup, {
        [styles.disabled]: onlyShowOrderDetail
      })}
    >
      <table className={styles.dsersOrderConfirmTable}>
        <thead>
          <tr>
            {columns.map((i, index) => {
              if (index === 0)
                return (
                  <th key="0">
                    <Checkbox
                      checked={selectAllProps.checked}
                      indeterminate={selectAllProps.indeterminate}
                      onChange={handleSelectAll}
                      disabled={onlyShowOrderDetail}
                    />
                  </th>
                );
              return <th key={index}>{i.title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {orderRenderData.map(i => {
            return (
              <OrderItem
                key={i.id}
                data={i}
                selected={selectedOrders?.includes(i.id)}
                disabled={onlyShowOrderDetail}
                onSelectChange={handleSelectedChange}
                paymentCurrency={paymentCurrency}
              />
            );
          })}
        </tbody>
        <tfoot>
          <TableSummary data={summaryData} paymentCurrency={paymentCurrency} />
        </tfoot>
      </table>

      <div className={classNames(styles.agencyInfo, styles.withPaymentBtn)}>
        <div>
          {!(
            onlyShowOrderDetail ||
            agencyInfo?.pay_pal ||
            agencyInfo?.stripe_account_email
          ) ? (
            <DSersAlert
              style={{ marginBottom: 8 }}
              type="warning"
              visible
              description="Your supplier does not provide any payment method, please contact your supplier to add a payment method for your item"
            />
          ) : null}
          {!onlyShowOrderDetail &&
          paymentAmount == 0 &&
          selectedOrders?.length ? (
            <DSersAlert
              style={{ marginBottom: 8 }}
              type="warning"
              visible
              description="Your supplier did not provide an order quotation, please contact your supplier to provide a quotation and then make payment"
            />
          ) : null}
        </div>
        <div style={{ width: '100%', padding: '8px 0' }}>
          You will make payment to :
        </div>
        <div className={styles.agencyInfoRow}>
          <div className={styles.infoItem}>
            {intl.get('order.agency_orders.payment.agency_info_name')}:{' '}
            <span className={styles.boldText}>
              {agencyInfo?.agency_name || 'Unknown Agency'}
            </span>
          </div>
          <div className={styles.infoItem}>
            {intl.get('order.agency_orders.payment.agency_info_amount_v2')}:{' '}
            <Popover
              trigger="click"
              visible={showEditAmount}
              getPopupContainer={p => p.parentNode || document.body}
              onVisibleChange={e => {
                !e && setShowEditAmount(e);
              }}
              content={
                <div>
                  <InputNumber
                    max="999999999999999"
                    min="0"
                    value={paymentAmount}
                    step="0.01"
                    onChange={handlePaymentAmountChange}
                  />
                </div>
              }
            >
              <span
                className={classNames(styles.amountText, {
                  [styles.error]: parseFloat(paymentAmount) < 1
                })}
              >
                {currencySymbolMap[paymentCurrency]} {paymentAmount}
              </span>
            </Popover>
            {/* <span
                onClick={() => setShowEditAmount(true)}
                className={classNames(
                  'material-icons notranslate',
                  styles.editIcon
                )}
              >
                edit
              </span> */}
          </div>
        </div>
        {/* {console.log(agencyInfo)} */}
        {agencyInfo?.pay_pal ? (
          <div className={styles.agencyInfoRow}>
            <div className={styles.infoItem}>
              {intl.get('order.agency_orders.payment.agency_info_paypal')}:{' '}
              <span className={styles.boldText}>{agencyInfo?.pay_pal}</span>
              {/* <Tooltip
                title={intl.get('order.agency_orders.payment.edit_agency_info')}
                getPopupContainer={p => p.parentNode || document.body}
              >
                <span
                  onClick={handleEditAgency}
                  className={classNames(
                    'material-icons notranslate',
                    styles.editIcon
                  )}
                >
                  edit
                </span>
              </Tooltip> */}
            </div>
            {/* <PaymentButton
            onClick={handlePayment}
            loading={paymentLoading}
            disabled={!parseFloat(paymentAmount)}
          /> */}
          </div>
        ) : null}

        {agencyInfo?.stripe_account_email ? (
          <div className={styles.agencyInfoRow}>
            <div className={styles.infoItem}>
              {/* {intl.get('order.agency_orders.payment.agency_info_paypal')}:{' '} */}
              {intl.get('setting.agency.agencyStripe')}:{' '}
              <span className={styles.boldText}>
                {agencyInfo?.stripe_account_email}
              </span>
            </div>
            {/* <PaymentButton
            onClick={handlePayment}
            loading={paymentLoading}
            disabled={!parseFloat(paymentAmount)}
          /> */}
          </div>
        ) : null}
      </div>

      {!onlyShowOrderDetail ? (
        <div className={styles.paymentOperate}>
          {agencyInfo?.stripe_account_email ? (
            <Tooltip
              overlayClassName={styles.tabBtnTip}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              title={
                parseFloat(paymentAmount) < 1
                  ? intl.get('order.agency_orders.payment.payment_1', {
                      type: 'Stripe'
                    })
                  : null
              }
            >
              <span
                style={{
                  position: 'relative',
                  height: 36,
                  display: 'inline-block'
                }}
              >
                {!parseFloat(paymentAmount) ||
                parseFloat(paymentAmount) < 1 ||
                !selectedOrders.length ? (
                  <span
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      zIndex: 1,
                      cursor: 'not-allowed'
                    }}
                  ></span>
                ) : null}

                <StripePaymentButton
                  onClick={() => handlePayment('stripe')}
                  loading={paymentLoading}
                  disabled={
                    !parseFloat(paymentAmount) ||
                    parseFloat(paymentAmount) < 1 ||
                    !selectedOrders.length
                  }
                />
              </span>
            </Tooltip>
          ) : null}
          {agencyInfo?.pay_pal ? (
            <Tooltip
              overlayClassName={styles.tabBtnTip}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              title={
                parseFloat(paymentAmount) < 1
                  ? intl.get('order.agency_orders.payment.payment_1', {
                      type: 'Paypal'
                    })
                  : null
              }
            >
              <span
                style={{
                  position: 'relative',
                  height: 36,
                  display: 'inline-block'
                }}
              >
                {!parseFloat(paymentAmount) ||
                parseFloat(paymentAmount) < 1 ||
                !selectedOrders.length ? (
                  <span
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      zIndex: 1,
                      cursor: 'not-allowed'
                    }}
                  ></span>
                ) : null}
                <PaymentButton
                  onClick={() => handlePayment('paypal')}
                  loading={paymentLoading}
                  disabled={
                    !parseFloat(paymentAmount) ||
                    parseFloat(paymentAmount) < 1 ||
                    !selectedOrders.length
                  }
                />
              </span>
            </Tooltip>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default connect(({ global, loading }) => ({
  loading: loading.models.order,
  agencyList: global.agencyManagementDataMerchant
  // confirmLoading: loading.effects['order/markAgencyOrderAsPaidAction']
}))(React.memo(MakePaymentAgencyGroup));
