import { Modal, Upload, Button, message, Spin, Select, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { Get, Post } from 'utils/request';
import { read, utils as xlsxUtils } from 'xlsx';
import { AGENCY_EVENT_CHECK_UPLOAD_ORDER_TASK } from '../constants';

import styles from './UploadExcel.less';
import { mergeTasks } from './utils';
const excelMineTypes = [
  'text/csv',
  'application/csv',
  'text/comma-separated-values',
  'application/csv',
  'application/excel',
  'application/vnd.msexcel',
  'text/anytext',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const uploadProps = {
  showUploadList: false,
  multiple: false,
  accept:
    '.csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

const { Dragger } = Upload;

const readFileToArrayBuffer = async BlobOrFile => {
  const fileReader = new FileReader();
  return new Promise(resolve => {
    fileReader.addEventListener('load', e => {
      resolve(e.target.result);
    });
    fileReader.readAsArrayBuffer(BlobOrFile);
  });
};

const matchPyamentColumns = [
  {
    key: 'agency_order_id',
    label: 'order.agency_orders.upload_agency_order_id',
    require: true
  },
  {
    key: 'shopify_product_id',
    label: 'order.agency_orders.upload_product_id',
    require: true
  },
  {
    key: 'shopify_product_sku',
    label: 'order.agency_orders.upload_sku_id',
    require: true
  },
  {
    key: 'payment_currency',
    label: 'order.agency_orders.upload_payment_currency',
    require: true
  },
  {
    key: 'product_cost',
    label: 'order.agency_orders.upload_product_cost',
    require: true
  },
  {
    key: 'freight_cost',
    label: 'order.agency_orders.upload_shipping_cost',
    require: true
  }
];

const matchTNColumns = [
  {
    key: 'agency_order_id',
    label: 'order.agency_orders.upload_agency_order_id',
    require: true
  },
  {
    key: 'shopify_product_id',
    label: 'order.agency_orders.upload_product_id',
    require: true
  },
  {
    key: 'shopify_product_sku',
    label: 'order.agency_orders.upload_sku_id',
    require: true
  },
  // {
  //   key: 'shipping_carrier',
  //   label: 'order.agency_orders.upload_shipping_carrier'
  // },
  // {
  //   key: 'shipping_url',
  //   label: 'order.agency_orders.upload_custom_shipping_url'
  // },
  {
    key: 'tracking_number',
    label: 'order.agency_orders.upload_tracking_number',
    require: true
  }
];

const defaultMatchKeys = {
  3: {
    agency_order_id: 'Agency Order ID',
    shopify_product_id: 'Item product ID',
    shopify_product_sku: 'Item SKU ID',
    payment_currency: 'Purchase currency',
    product_cost: 'Purchase cost',
    freight_cost: 'Shipping cost'
  },
  4: {
    agency_order_id: 'Agency Order ID',
    shopify_product_id: 'Item product ID',
    shopify_product_sku: 'Item SKU ID',
    tracking_number: 'Tracking No'
  }
};

const cacheMatchedColunms = (cacheData, status) => {
  try {
    localStorage.setItem(
      'agency_excel_column_match_' + status,
      JSON.stringify(cacheData)
    );
  } catch (error) {}
};

const getCachedMatchColunms = status => {
  return defaultMatchKeys[status];
  let res = {};
  try {
    res =
      JSON.parse(localStorage.getItem('agency_excel_column_match_' + status)) ||
      {};
  } catch (error) {
    console.log(error);
  }
  return res;
};
// 1
const getHistoryMatchRules = async type => {};

const configs = {
  uploadUrl: {
    3: '/ord/supply/action/payment/import',
    4: '/ord/supply/action/tracking/import'
  }
};

const UploadExcelContent = React.memo(
  ({ onUploadSuccess, onClose, status }) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [selectedColunms, setSelectedColunms] = useState({});
    const matchColumns = React.useMemo(() => {
      return status === 3 ? matchPyamentColumns : matchTNColumns;
    }, [status]);

    const handleUpload = async () => {
      if (loading) return;
      setLoading(true);
      const formData = new FormData();
      formData.append('file', files[0]);
      matchColumns.forEach(i => {
        formData.append(i.key, selectedColunms[i.key]);
      });
      cacheMatchedColunms(selectedColunms, status);
      try {
        const res = await Post(configs.uploadUrl[status], { data: formData });
        if (res.code === 2000) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          typeof onUploadSuccess === 'function' && onUploadSuccess(res);
          mergeTasks([{ id: res.data, type: status, status: 1 }]);
          window.EVENT.emit(AGENCY_EVENT_CHECK_UPLOAD_ORDER_TASK);
          onClose();
        } else {
          message.error('Upload failed');
        }
      } catch (error) {
        console.log(error);
        message.error('upload failed');
      }
      setLoading(false);
    };
    const handleFileChange = e => {
      console.log(e);
      if (!excelMineTypes.includes(e.file.type)) {
        message.error('Invalid excel file format:' + e.type);
        setFiles(files);
        return false;
      }
      const isRemoved = e.file.status === 'removed';
      setFiles(isRemoved ? [] : [e.file]);
    };

    const disabeldSelectOptions = React.useMemo(() => {
      return Object.values(selectedColunms).reduce((prev, current) => {
        prev[current] = true;
        return prev;
      }, {});
    }, [selectedColunms]);

    const allowUpload = React.useMemo(() => {
      return !matchColumns.find(i => !selectedColunms[i.key] && i.require);
    }, [selectedColunms, matchColumns]);

    useEffect(() => {
      const currentFile = files[0];
      const loadingFileColumns = async () => {
        console.log(currentFile);
        if (currentFile.size > 1024 * 1024 * 5) {
          currentFile.status = 'error';
          setFiles([currentFile]);
          message.error('File size limit 5MB');
          setColumns([]);
          setSelectedColunms({});
          return;
        }
        setLoading(true);
        try {
          const arrayBuffer = await readFileToArrayBuffer(currentFile);
          const fileData = read(arrayBuffer);
          const sheetOne = fileData.Sheets[fileData.SheetNames[0]];
          const sheetOneJsonData = xlsxUtils.sheet_to_json(sheetOne, {
            defval: ''
          });
          if (sheetOneJsonData[0]) {
            const titles = Object.keys(sheetOneJsonData[0]);
            console.log(titles);
            setColumns(titles);
            const cacheData = getCachedMatchColunms(status);
            let initMatchData = {};

            for (const field of matchColumns) {
              const cachedKey = cacheData[field.key];
              const cachedKeyStillExsits =
                cachedKey?.toLowerCase()?.trim() &&
                titles.find(
                  title =>
                    title?.toLowerCase()?.trim() ===
                    cachedKey?.toLowerCase()?.trim()
                );
              if (cachedKeyStillExsits) {
                initMatchData[field.key] = cachedKeyStillExsits;
              } else {
                initMatchData = {};
                break;
              }
            }

            if (!matchColumns.every(i => !!initMatchData[i.key])) {
              try {
                const historyRules = await Post(
                  '/ord/supply/action/agency/record',
                  { data: { record_type: status === 3 ? 1 : 2 } }
                );
                console.log(historyRules);

                if (historyRules.code === 2000 && historyRules.data) {
                  for (const cacheData of historyRules.data) {
                    console.log('outer loop');
                    for (const field of matchColumns) {
                      const cachedKey = cacheData[field.key];
                      const cachedKeyStillExsits =
                        cachedKey?.toLowerCase()?.trim() &&
                        titles.find(
                          title =>
                            title
                              ?.toLowerCase()
                              ?.trim()
                              ?.replace(/ /g, '') ===
                            cachedKey
                              ?.toLowerCase()
                              ?.trim()
                              ?.replace(/ /g, '')
                        );
                      if (cachedKeyStillExsits) {
                        initMatchData[field.key] = cachedKeyStillExsits;
                      } else {
                        initMatchData = {};
                        break;
                      }
                    }
                    if (matchColumns.every(i => !!initMatchData[i.key])) {
                      break;
                    }
                  }
                }
              } catch (error) {}
            }

            // const initMatchData = matchColumns.reduce((prev, current) => {
            //   const cachedKey = cacheData[current.key];
            //   // const cachedKeyStillExsits = titles.includes(cachedKey);
            //   const cachedKeyStillExsits = cachedKey?.toLowerCase()?.trim() && titles.find(
            //     title => title?.toLowerCase()?.trim() === cachedKey?.toLowerCase()?.trim()
            //   );
            //   if (cachedKeyStillExsits) {
            //     prev[current.key] = cachedKeyStillExsits;
            //   }
            //   return prev;
            // }, {});
            setSelectedColunms(initMatchData);
          } else {
            // console.log(error);
            currentFile.status = 'error';
            setFiles([currentFile]);
            message.error('Read columns failed');
            setColumns([]);
            setSelectedColunms({});
          }
        } catch (error) {
          console.log(error);
          currentFile.status = 'error';
          setFiles([currentFile]);
          message.error('Invalid excel file format');
          setColumns([]);
          setSelectedColunms({});
        }
        setLoading(false);
      };
      if (currentFile) {
        loadingFileColumns();
      } else {
        // 移除文件清空所有信息
        setColumns([]);
        setSelectedColunms({});
      }
    }, [files?.[0]]);
    // console.log(files);

    const removeFiles = e => {
      e.stopPropagation();
      setFiles([]);
    };

    return (
      <div>
        <Spin spinning={loading}>
          <div className={styles.subTitle}>
            {intl.get(
              status === 3
                ? 'order.agency_orders.upload_payment_sub_title'
                : 'order.agency_orders.upload_tn_sub_title'
            )}
          </div>
          <Dragger
            fileList={files}
            // showUploadList={false}
            beforeUpload={() => false}
            onChange={handleFileChange}
            {...uploadProps}
            className={classNames(styles.uploadArea, {
              [styles.withFiles]: !!files[0]
            })}
            // showUploadList={false}
          >
            {!files[0] ? (
              <div className={styles.uploadEmpty}>
                <span className="material-icons notranslate">add</span>
                <span>{intl.get('order.agency_orders.upload_file_tip')}</span>
              </div>
            ) : (
              <div
                className={classNames(styles.fileList, {
                  [styles.errorFile]: files[0]?.status === 'error'
                })}
              >
                <span className={styles.fileName}>
                  <span
                    style={{ marginRight: 8 }}
                    className="material-icons notranslate"
                  >
                    folder
                  </span>
                  <span className={styles.fileNameContent}>{files[0].name}</span>
                </span>
                <Tooltip title="delete">
                  <span
                    onClick={removeFiles}
                    className="material-icons notranslate"
                  >
                    delete
                  </span>
                </Tooltip>
              </div>
            )}
          </Dragger>
          {columns.length ? (
            <div>
              <div className={styles.matchColunmsTitle}>
                {intl.get('order.agency_orders.uplaod_match_column')}
              </div>
              {matchColumns.map(i => {
                return (
                  <div className={styles.formRow}>
                    <div className={styles.label}>
                      {intl.get(i.label)}
                      {i.require ? (
                        <span style={{ color: 'red' }}>*</span>
                      ) : null}
                    </div>
                    <Select
                      // open
                      value={selectedColunms[i.key]}
                      showSearch
                      dropdownMatchSelectWidth={false}
                      placeholder={intl.get(
                        'order.agency_orders.upload_select_placeholder'
                      )}
                      allowClear
                      onChange={e =>
                        setSelectedColunms({ ...selectedColunms, [i.key]: e })
                      }
                    >
                      {columns.map(col => {
                        return (
                          <Select.Option
                            disabled={disabeldSelectOptions[col]}
                            value={col}
                          >
                            {col}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className={styles.operation}>
            <Button
              disabled={!allowUpload}
              // data="dx"
              onClick={handleUpload}
              type="primary"
            >
              {intl.get('order.agency_orders.upload_confirm')}
            </Button>
          </div>
        </Spin>
      </div>
    );
  }
);

const UploadExcel = React.memo(
  ({ visible, onUploadSuccess, onClose, status }) => {
    // console.log(status);
    // console.log(cacluateTimezone, moment.tz.guess(), '-------------------');
    return (
      <Modal
        destroyOnClose
        footer={null}
        title={intl.get(
          status === 3
            ? 'order.agency_orders.upload_payment_title'
            : 'order.agency_orders.upload_tn_title'
        )}
        visible={visible}
        onCancel={onClose}
        maskClosable={false}
        centered
        // width={680}
      >
        <UploadExcelContent
          onClose={onClose}
          status={status}
          onUploadSuccess={onUploadSuccess}
        />
      </Modal>
    );
  }
);

export default UploadExcel;
