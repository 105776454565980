import { Modal, DatePicker, Button, Radio, message } from 'antd';
import moment from 'moment-timezone';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'dva';
import qs from 'querystring';
import debounce from 'lodash/debounce';

// import '../modalConfirm.css';

import styles from './DownloadExcel.less';
import { Post } from 'utils/request';
import { AGENCY_EVENT_CHECK_EXPORT_ORDER_TASK } from '../constants';
import { mergeTasks } from './utils';
import { supplyPlatformEquals } from 'routes/AgencyOrder/util';

const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;

const downloadDSersFile = async (path, extParams = {}) => {
  let user = localStorage.getItem('dser-pro-authority');
  const params = {
    token: user,
    ...extParams
  };
  let url = `${window.DSERS_API_CONFIG.HOST}/${path}?${qs.stringify(params)}`;
  let response = await fetch(url, {
    method: 'post'
  });

  response.text().then(res => {
    if (
      res.includes('msg') &&
      response.headers.get('content-type') != 'vnd.ms-excel'
    ) {
      let error = JSON.parse(res);
      if (error.code === 205) {
        message.error(intl.get('csv.token_error'));
      } else {
        message.error(error.msg);
      }
    } else {
      window.open(url);
    }
  });
};

const createExportTask = async params => {
  return Post('/ord/supply/action/export', { data: params });
};

const getTimeStr = time => {
  const day = parseInt(time / 60 / 60 / 24);
  const hour = parseInt((time / 60 / 60) % 24);
  const minute = parseInt((time / 60) % 60);
  const seconds = parseInt(time % 60);
  return (
    (day ? `${day} days ` : '') +
    (hour ? `${hour} hours` : '') +
    (minute ? `${minute} minutes` : '') +
    (seconds ? `${seconds} seconds` : '')
  );
};

const RangeSelectContent = React.memo(
  ({ onClose, onConfirm, timeZone, selectedOrders, status, orders }) => {
    const [loading, setLoading] = useState(false);
    const nowDate = useMemo(() => {
      return moment()
        .tz(timeZone)
        .endOf('day')
        .unix();
    }, [timeZone]);
    // const [min, max] = useMemo(() => {
    //   return [
    //     moment()
    //       .tz(timeZone)
    //       .subtract(29, 'days')
    //       .startOf('day')
    //       .unix(),
    //     moment()
    //       .tz(timeZone)
    //       .endOf('day')
    //       .unix()
    //   ];
    // }, [timeZone]);
    // console.log(timeZone);

    const [range, setRange] = useState([
      moment()
        .tz(timeZone)
        .subtract(29, 'days')
        .startOf('day'),
      moment()
        .tz(timeZone)
        .endOf('day')
    ]);

    const [[min, max], setRangeLimit] = useState([0, Infinity]);

    const handleStartClick = range => {
      console.log(range);
      if (range.length === 1) {
        setRangeLimit([
          range[0].unix() - 60 * 60 * 24 * 30,
          range[0].unix() + 60 * 60 * 24 * 30
        ]);
      } else {
        setRangeLimit([0, Infinity]);
      }
    };

    const getDisabledDate = date => {
      const number = date.unix();
      return number > max || number < min || number > nowDate;
    };
    const [downloadType, setDownloadType] = useState(
      selectedOrders?.length ? '1' : '2'
    );

    const handleRangeChange = rangeValues => {
      const [start, end] = rangeValues;
      setRange([start, end]);
    };
    // console.log(selectedOrders);
    const handleConfirm = async () => {
      const [start, end] = range;
      if (loading) return;

      const actionOrders = [];
      const actionOrderIdMap = {};

      selectedOrders?.forEach(i => {
        console.log(i, selectedOrders)
        // if(!selectedOrders.includes(i.id)) {
        //   return;
        // }
        
        i.childrens?.forEach(child => {
          if (child.is_black_list === 2) {
            return;
          }
          if (!supplyPlatformEquals(child.platform_type, 5)) {
            return;
          }
          child?.sp?.forEach(i => {
            if (i.status === status && i.platform_order_id) {
              if(!actionOrderIdMap[i.platform_order_id]) {
                actionOrderIdMap[i.platform_order_id] = true;
                actionOrders.push(i.platform_order_id);
              }
              
            }
          });
        });
      });

      // if (!actionOrders.length && downloadType === '1') {
      //   message.error('Only orders with Agency order ID can be exported');
      //   return;
      // }

      if ((!start || !end) && downloadType === '2') return;
      setLoading(true);
      // console.log(selectedOrders);
      const params =
        downloadType === '1'
          ? {
              status: status,
              export_type: parseInt(downloadType),
              order_ids: selectedOrders?.map(i => i.id),
              // order_ids: actionOrders
            }
          : {
              start: start.unix(),
              end: end.unix(),
              status: status,
              export_type: parseInt(downloadType)
            };
      try {
        const res = await createExportTask(params);
        if ([2010, 2000].includes(res.code) && res.data) {
          mergeTasks([{ id: res.data, status: 1 }]);
          window.EVENT.emit(AGENCY_EVENT_CHECK_EXPORT_ORDER_TASK);
          onClose();
        } else {
        }
        // onClose();
        // }
      } catch (error) {}
      setLoading(false);
    };

    useEffect(() => {
      setRange([
        moment()
          .tz(timeZone)
          .subtract(29, 'days')
          .startOf('day'),
        moment()
          .tz(timeZone)
          .endOf('day')
      ]);
    }, [timeZone]);

    return (
      <div>
        <div className={styles.subTitle}>
          {intl.get('order.agency_orders.download_title')}:
        </div>
        <div className={styles.radioGroup}>
          <RadioGroup
            onChange={e => {
              setDownloadType(e?.target.value);
            }}
            value={downloadType}
            name="export_agency_orders_method"
          >
            <div className={styles.contentLine} style={{ marginBottom: 16 }}>
              <Radio disabled={!selectedOrders?.length} value="1">
                {intl.get('order.agency_orders.download_by_select', {
                  value: selectedOrders?.length || 0
                })}
              </Radio>
            </div>
            <div className={styles.contentLine} style={{ marginBottom: 16 }}>
              <Radio value="2">
                {intl.get('order.agency_orders.download_by_date')}
              </Radio>

              <RangePicker
                onChange={handleRangeChange}
                disabledDate={getDisabledDate}
                onCalendarChange={handleStartClick}
                value={range}
                style={{ marginTop: 16, marginLeft: 24 }}
              />
            </div>
          </RadioGroup>
        </div>
        <div className={styles.subTitle}>
          {intl.get('order.agency_orders.download_as')}:
        </div>
        <div className={styles.radioGroup}>
          <div className={styles.contentLine} style={{ marginBottom: 16 }}>
            <Radio disabled={!selectedOrders?.length} checked value="0">
              {intl.get('order.agency_orders.export_to_file_type_excel')}
            </Radio>
          </div>
        </div>
        <div className={styles.operation}>
          {/* <Button data="dx" onClick={onClose}>
          {intl.get('order.order_product.shipping_dialog.cancel')}
        </Button> */}
          <Button data="dx" onClick={handleConfirm} type="primary">
            {intl.get('order.agency_orders.download_btn')}
          </Button>
        </div>
      </div>
    );
  }
);

const DownloadExcel = React.memo(
  ({ visible, onConfirm, onClose, timeZone, selectedRows, orders, status }) => {
    const cacluateTimezone = useMemo(() => {
      return timeZone || moment.tz.guess();
    }, [timeZone]);
    // console.log(cacluateTimezone, moment.tz.guess(), '-------------------');
    return (
      <Modal
        destroyOnClose
        footer={null}
        title={intl.get('order.agency_orders.download_order_info')}
        visible={visible}
        onCancel={onClose}
      >
        <RangeSelectContent
          selectedOrders={selectedRows}
          timeZone={cacluateTimezone}
          onClose={onClose}
          status={status}
          onConfirm={onConfirm}
          orders={orders}
        />
      </Modal>
    );
  }
);

export default connect(({ global }) => ({
  timeZone: global.timezoneRegion
}))(DownloadExcel);
