/* eslint no-nested-ternary: 0 */
import React from 'react';
import { Icon, Tooltip } from 'antd';
import { connect } from 'dva';
import className from 'classnames';
import intl from 'react-intl-universal';
import { list_V5, getVersion } from 'utils/bannerWhiteList';

import Bundle from './Order/Bundle.js';
import Bogo from './Order/Bogo.js';
import Product from './Order/Product.js';
import Cost from './Order/Cost.js';
import Shipping from './Order/Shipping.js';
import No from './Order/No.js';
import Actions from './Order/Actions.js';
import ActionsLimeTime from './Order/ActionsLimeTime'
import Bind from './Order/Bind.js';
// import Bind from '../Order/Order/Bind';

import reactGaEvent from '../../utils/GAEvent';
import EnableCloseTag from './component/EnableCloseTag';

import orderStyles from './Order.less';
import orderErrorTipIco from '../../assets/order/orderErrorTipIco.png';
import { ActionEditProductChangeItemTip } from 'features'

import {
  notEditOrderStatus,
  getCanceled,
  replaceExtStatus,
  checkChileTaxNumberValid,
  checkPassPortNo,
  supplyPlatformEquals
} from './util.js';

import { computerOrderStatus } from 'utils/global'

const bannerTrickConfig = {
  'track-type': 'productBargain',
  'track-name': 'bannerV2FromOrder',
  'track-metadata-position': 'failedOrder'
};
@connect(({ global, login }) => {
  return {
    unsupportedShipping: global.unsupportedShipping,
    userInfo: login.userInfo,
    conversionFinish: global.conversionFinish,
    agencyList: global.agencyManagementDataMerchant
  };
})
export default class OrderItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      spShowEdit: []
    };
  }

  componentWillMount() {
    const {
      child: { supplierMapType, sp }
    } = this.props;

    if (supplierMapType > 2) {
      const arr = new Array(sp.length).fill(false); // 默认不展示手动换绑
      this.setState({
        spShowEdit: arr
      });
    }
  }

  componentWillReceiveProps(nextsProps) {
    const {
      listData: { showSave }
    } = nextsProps;

    const { showEdit, spShowEdit } = this.state;

    const arr = [];

    spShowEdit.forEach(item => {
      arr.push(item && showSave);
    });

    this.setState({
      showEdit: showEdit && showSave,
      spShowEdit: arr
    });
  }

  componentDidMount() {
    // this.props.onRef(this);
  }

  bindRef(ref) {
    this.child = ref;
  }

  // 显隐 edit
  toggleShowEdit = sonIndex => {
    // type 是不是 批量 edit
    const { showEdit, spShowEdit } = this.state;

    let flag = false;

    if (sonIndex == undefined) {
      flag = !showEdit;
      this.setState({
        showEdit: flag
      });
    } else {
      flag = !spShowEdit[sonIndex];
      spShowEdit[sonIndex] = flag;
      this.setState({
        spShowEdit: [...spShowEdit]
      });
    }

    if (flag) {
      const {
        listData: { id },
        index
      } = this.props;

      this.props.updataExt({
        type: 'bind',
        oid: id,
        bind: undefined,
        sonIndex,
        index
      });
    }

    // toggleBind( listData.id, index );
  };

  showBogo = (child, status, is_base) => {
    if (child.supplierMapType == 3 && is_base) {
      return true;
    }
    let flag = false;
    child.sp.forEach(sp => {
      if (sp.is_base == is_base) {
        if (
          (sp.status == status &&
            !(status === 5 && !child.shopifyFulfillStatus)) ||
          (sp.logistics_infos && status == 9 && child.status != 2048)
        ) {
          flag = true;
          if (!sp.is_base) {
            flag = false;
          }
        }
      }
    });

    let newFlag = false;
    var arr2 = [];
    child.sp.forEach(sp => {
      arr2.push(sp.status);
    });

    newFlag = arr2.every(value => {
      return value == status;
    });

    if (newFlag) {
      flag = newFlag;
    }

    console.log('flag', flag);
    return flag;
  };

  // bogo类型子订单不符合当前状态，但子订单的子订单有符合当前状态的显示简版的子订单
  showLessBogo = (child, status) => {
    let flag = false;
    let flag2 = false;
    child.sp.forEach(sp => {
      if (sp.is_base && sp.status != status) {
        flag = true;
      }
      if (
        sp.is_base == false &&
        // 状态与tab一致
        ((sp.status == status &&
          !(status === 5 && !child.shopifyFulfillStatus)) ||
          (sp.logistics_infos && status == 9))
      ) {
        flag2 = true;
      }
    });
    return flag && flag2;
  };

  // 是否展示bundle
  showBundle = (child, status) => {
    let flag = false;
    child.sp.forEach(sp => {
      // 订单状态等于当前状态 或者 符合awaiting fulfillment tab(status == 9)  但是订单的状态不是9 是4
      if (
        (sp.status == status &&
          !(status === 5 && !child.shopifyFulfillStatus)) ||
        (sp.logistics_infos && status == 9 && child.status != 2048)
      ) {
        flag = true;
      }
    });
    return flag;
  };

  getMoreStatus = () => {
    const { listData, status, child, index } = this.props;

    // 只在 awaiting payment 和 ataiting shipment 展示
    if (![3, 4].includes(status)) {
      return null;
    }

    const {
      detail: { fulfillments = [], refunds = [], line_items }
    } = listData;

    const lineItemQty = line_items[index].quantity;
    let fulQty = 0; // shopify fulfilled 的数量
    let canQty = 0; // shopify canceled 的数量

    fulfillments.forEach(ful => {
      if (ful.status != 'success') {
        return;
      }
      ful.line_items.forEach(lineItem => {
        if (lineItem.id == child.id) {
          fulQty += lineItem.quantity;
        }
      });
    });

    refunds.forEach(ref => {
      if (!ref.refund_line_items) {
        return;
      }
      ref.refund_line_items.forEach(lineItem => {
        if (lineItem.line_item_id == child.id) {
          canQty += lineItem.quantity;
        }
      });
    });

    if (!fulQty && !canQty) {
      return null;
    }

    return (
      <>
        {fulQty == 0 ? null : fulQty == lineItemQty ? (
          <span className={orderStyles.canceledBy}>
            {intl.get('order.shopify_fulfilled')}
          </span>
        ) : (
          <span className={orderStyles.canceledBy}>
            {intl.get('order.shopify_partially_fulfilled')}
          </span>
        )}
        {canQty == 0 ? null : canQty == lineItemQty ? (
          <span className={orderStyles.canceledBy}>
            {intl.get('order.shopify_canceled')}
          </span>
        ) : (
          <span className={orderStyles.canceledBy}>
            {intl.get('order.shopify_partially_canceled')}
          </span>
        )}
      </>
    );
  };

  // 获取 canceled 标志
  getCanceledBy = (
    child,
    tabStatus,
    index = 0,
    shopifyLineItemIndex,
    listData
  ) => {
    if (tabStatus != 6) {
      return null;
    }

    const shopifyCanceled = child.canceledBy;

    const hasFulfillableQuantity = this.hasFulfillableQuantity(
      shopifyLineItemIndex,
      listData
    );

    if (child.status === 8192) {
      return <span className={orderStyles.canceledBy}>Shopify returned</span>;
    }

    if (shopifyCanceled) {
      if (hasFulfillableQuantity) {
        return (
          <span className={orderStyles.canceledBy}>
            {intl.get('order.shopify_partially_refunded')}
          </span>
        );
      }
      return (
        <span className={orderStyles.canceledBy}>
          {intl.get('order.shopify_refunded')}
        </span>
      );
    }

    if (child.AECanceled[index]) {
      return (
        <span className={orderStyles.canceledBy}>
          {intl.get('order.agency_orders.agency_canceled_tag')}
        </span>
      );
    }

    return null;
  };
  // 暂时不用 这里先复用ae cancel逻辑
  getAgencyCanceled = (child, sp, tabStatus) => {
    if (tabStatus != 6) {
      return null;
    }

    if (sp.status == 6 && child.agencyCanceled) {
      return (
        <div>
          <span className={orderStyles.canceledBy}>
            {intl.get('order.agency_orders.agency_canceled_tag')}
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  getAgencyAuthStatusTag = (child, tabStatus) => {
    if (tabStatus !== 3) {
      return null;
    }
    return (
      <div>
        <span className={orderStyles.canceledBy}>
          {intl.get(
            child.agency_auth_status === 1
              ? 'order.agency_orders.agency_auth_status_processing'
              : 'order.agency_orders.agency_auth_status_offline'
          )}
          {child.agency_auth_status === 1 ? (
            <Tooltip
              title={intl.get('order.agency_orders.agency_process_tag_tip')}
              getPopupContainer={e => e.parentNode || document.body}
            >
              <Icon style={{ marginLeft: 4 }} type="question-circle" />
            </Tooltip>
          ) : null}
        </span>
      </div>
    );
  };

  // 获取 AE canceled 标识
  getAECanceled = (child, sp, sonIndex, tabStatus) => {
    if (tabStatus != 6) {
      return null;
    }

    if (sp.status == 6 && child.AECanceled[sonIndex]) {
      return (
        <div>
          <span className={orderStyles.canceledBy}>
            {intl.get('order.ali_canceled')}
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  notFranceCountryMap = {
    '971': 'Guadeloupe',
    '972': 'Martinique',
    '973': 'French Guiana',
    '974': 'Reunion',
    '976': 'Mayotte',
    '977': 'Saint Barthelemy',
    '978': 'Saint Martin',
    '986': 'Wallis And Futuna Islands',
    '987': 'French Polynesia',
    '988': 'New Caledonia'
  };

  // 获取订单更多具体错误细节
  getError12 = (sp, childs) => {
    const {
      listData: {
        Skus = {},
        ext: { address = {}, multiple_platform_error = {} }
      },
      child: { extList = [] },
      supplyPlatform
    } = this.props;
    const err = multiple_platform_error?.agency || {};
    const { country_code = '', zip = '', country } = address;
    const zipTopThree = zip.substring(0, 3); // 邮编前三位
    const prod = Skus[sp.platform_product_id]; // 订单中的商品 从ae抓的信息
    let aeSkuHasChange = true; // ae卖家有没有改sku
    let tip = null; // 返回的错误提示
    let hasAvailQuantity = true; // ae商品有没有库存
    let limitNumber = 0; // 限购数量
    let isTwoError = false; //

    // 位置错误 模糊提示
    if (sp.err.code === 5) {
      tip = (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>
          {intl.get('order.err_may_be_blacklisted')}
          <Tooltip
            placement="top"
            title={<span>{intl.getHTML('order.err_may_be_listed_5')}</span>}
          >
            <Icon
              type="question-circle"
              theme="outlined"
              style={{
                marginLeft: 2,
                transform: 'scale(.9)'
              }}
            />
          </Tooltip>
        </p>
      );
    }

    if(sp?.err?.code === 11 && sp?.err?.msg?.includes?.('订单商品未获取到报价')) {
      return <p className={orderStyles.productTip}>
      Supplier has not provided quotation. Please contact them for pricing.
      </p>
    }

    // 针对法国特殊邮编给错误提示
    if (
      country_code === 'FR' &&
      [
        '971',
        '972',
        '973',
        '974',
        '976',
        '976',
        '978',
        '986',
        '987',
        '988'
      ].includes(zipTopThree)
    ) {
      tip = (
        <p className={orderStyles.productTip}>
          {intl.get('order.err_france', {
            Guadeloupe: this.notFranceCountryMap[zipTopThree]
          })}
        </p>
      );
    }

    // 没有mapping
    if (!sp.platform_product_id && supplyPlatform !== 5) {
      tip = (
        <p className={orderStyles.productTip}>
          {intl.getHTML('order.err_un_mapped')}
        </p>
      );
    }

    extList.forEach(item => {
      if (item.sku == sp.platform_sku && item.quantity == sp.quantity) {
        // 有限购
        if (sp.quantity && item.limit != 0) {
          limitNumber = item.limit;
        }

        // ae商品不卖了
        if (item.status != 'onSelling') {
          tip = (
            <p className={orderStyles.productTip}>
              <i class="material-icons notranslate">error</i>{' '}
              {intl.get('order.err_not_available')}
            </p>
          );
        }
      }
    });

    // 限购提示
    if (limitNumber !== 0) {
      tip = (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.err_limit', { limit: limitNumber })}
        </p>
      );
      isTwoError = true;
    }

    // 19 也是限购
    if (sp.err.code === 19 && limitNumber === 0) {
      tip = (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.err_limit_blur')}
        </p>
      );
      isTwoError = true;
    }

    if (prod) {
      prod.skuPriceList.some(item => {
        // 商品 sku 相等
        if (item.skuAttr === sp.platform_sku) {
          aeSkuHasChange = false;
          // 没库存
          if (!item.skuVal.availQuantity) {
            tip = (
              <p className={orderStyles.productTip}>
                <i class="material-icons notranslate">error</i>{' '}
                {intl.get('order.err_out_of_stock')}
              </p>
            );
            hasAvailQuantity = false;
            return;
          }

          // 库存小于10
          if (item.skuVal.availQuantity < 10) {
            // 没库存
            if (isTwoError) {
              tip = (
                <>
                  {tip}
                  <p className={orderStyles.productTip}>
                    <i class="material-icons notranslate">error</i>{' '}
                    {intl.get('order.err_out_of_stock')}
                  </p>
                </>
              );
            } else {
              // 也许没库存
              tip = (
                <p className={orderStyles.productTip}>
                  <i class="material-icons notranslate">error</i>{' '}
                  {intl.get('order.err_may_be_out_of_stock')}
                </p>
              );
            }
            hasAvailQuantity = false;
            return;
          }
          return true;
        }
      });

      // ae 商品sku变化 并且后台返回的是商品信息有问题
      if (aeSkuHasChange && sp.err.code === 5) {
        tip = (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.getHTML('order.err_sku_change')}
          </p>
        );
      }
    }

    if (sp.err.code === 15 && sp.err.msg === 'Remote service error') {
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.err_ae_fake_failed')}
          {country_code == 'UA'
            ? intl.get('order.err_add_address_ukraine')
            : null}
        </p>
      );
    }

    if (
      sp.err.code === 9 &&
      sp.err.msg ===
        'code: REMOTE_CREATE_ORDER_RESULT_ERROR,msg: create order error'
    ) {
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.err_ae_fake_failed')}
          {country_code == 'UA'
            ? intl.get('order.err_add_address_ukraine')
            : null}
        </p>
      );
    }

    switch (sp.err.code) {
      case 8:
        tip = (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.get('order.ae_account_disabled')}
          </p>
        );
        break;
      case 13:
        tip = (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>
            {intl.get('order.err_out_of_stock')}
          </p>
        );
        break;
      case 14:
        tip = (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.get('order.err_not_shipping')}
          </p>
        );
        break;
      case 15:
        tip = (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.get('order.err_blacklisted')}
          </p>
        );
        break;
      case 16:
        tip = (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.get('order.supplier_account_disabled')}
          </p>
        );
        break;
      case 17:
        tip = (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.get('order.currency_not_supported')}
          </p>
        );
        break;
      case 18:
        tip = (
          <p className={orderStyles.productTip}>
            {' '}
            <i class="material-icons notranslate">error</i>{' '}
            {intl.get('order.cpt_tip')}
          </p>
        );
        break;
      case 20:
        tip = (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.get('order.duplicated_order')}
          </p>
        );
        break;
      default:
    }

    console.log('我的值为', childs.sp, childs.sp.length);

    if (err?.code == 21) {
      if (childs.sp.length == 2) {
        if (childs.sp[0].is_default && !childs.sp[1].is_default) {
          if (childs.sp[0].is_default) {
            tip = (
              <p className={orderStyles.productTip}>
                <i class="material-icons notranslate">error</i>{' '}
                {intl.get('order.ae_code_21_error')}
              </p>
            );
          }
        }
      } else {
        tip = (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.get('order.err_varients_change')}
          </p>
        );
      }
    }

    if (err?.code == 20 && err?.msg == 'ProductIdChanged') {
      tip = (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          <div>
            Due to AliExpress recent interface upgrade, this order may have been
            already successfully placed. Please check on AliExpress to avoid any
            loss. If the order was successful, please fill in the order number
            manually. If the order failed, please place the order again.
            <a href="mailto:support@dserspro.com">Contact support</a>.
          </div>
        </p>
      );
    }

    return tip;
  };

  // 获取商品错误信息
  getProductError = (sp, child, listData, status) => {
    const { err = {} } = sp;
    // shopify删除
    if (!child.shopifyProductId && sp.status != 5) {
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.err_deleted_in_shopify')}
        </p>
      );
    }
    const outerErrorCode = listData?.ext?.multiple_platform_error?.agency?.code;
    // 失败单没有mapping 外层errcode 21代表供应商sku发生变更下单拦截
    if (
      listData?.ext?.multiple_platform_error?.agency?.code == 13 &&
      listData?.ext?.multiple_platform_error?.agency?.msg == 'code: INVENTORY_HOLD_ERROR,msg: ' &&
      status == 7
    ) {
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>
          {intl.get('order.err_out_of_stock')}
        </p>
      );
    }
    // if (sp.status === 7 && !err.code && outerErrorCode !== 21) {
    //   return (
    //     <p className={orderStyles.productTip}>
    //       {intl.getHTML('order.err_un_mapped')}
    //     </p>
    //   );
    // }

    if (sp.status != 7 || !err.code) {
      return null;
    }

    return this.getError12(sp, child);
  };

  getActionEditChangeError = (sp, child, listData) => {
    if (!sp?.actionEditChangeProduct) {
      return null;
    }
    return (
      <ActionEditProductChangeItemTip type={sp?.actionEditChangeProduct} />
    );
  };

  // basic mapping 副供应商下单成功 返回副供应商索引
  getBasicSuccSp = (child, status) => {
    let index = 0;
    let flag = false;

    if (child.supplierMapType == 1) {
      if (child.sp.length == 2) {
        child.sp.forEach((sp, si) => {
          // 4待发货 3待支付 7失败单  4的状态最靠后 所以排序用 4 3 7
          if ([4, 3, 7].includes(sp.status) && sp.is_default) {
            index = si;
            flag = true;
          }

          // 是主供应商 并且 等于当前tab状态 !flag(之前没有匹配到)
          if (sp.is_default && sp.status == status && !flag) {
            index = si;
            flag = true;
          }

          // 是副供应商 并且 等于当前tab状态 !flag(之前没有匹配到)
          if (sp.status == status && !sp.is_default && !flag) {
            index = si;
          }

          // 有订单号说明是最新的状态
          if (!sp.is_default && sp.platform_order_id) {
            flag = true;
            index = si;
          }
        });
        if (status == 7 && child.sp.every(item => item.status == 7)) {
          let orderIndex = child.sp.findIndex(item => !item.is_default);
          if (orderIndex > -1) {
            index = orderIndex;
          }
        }
      }
    }

    // bogo mapping 返回买的商品 买的是主的 赠的是副的
    if (child.supplierMapType == 3) {
      if (child.sp.length == 2) {
        child.sp.forEach((sp, si) => {
          if (sp.is_base) {
            index = si;
          }
        });
      }
    }

    return index;
  };

  // 错误提示
  getQtyZeroTip = errTipItem => {
    let holdErrorTip = intl.get('order.place_via_extension.on_hold_error');
    if (![2,6,7].includes(this.props.status)) {
      return null;
    }
    
    if (this.props.status === 6) {
      if (!this.props.hasCanceledCouldOrder) {
        return null;
      }

      if (
        !this.props.child?.AECanceled?.[0] ||
        this.props.child?.sp?.[0]?.status !== 6 ||
        this.props.child?.shopifyFulfillStatus
      ) {
        return null;
      }
    }

    if (!Array.isArray(errTipItem) || !errTipItem.length) {
      return null;
    }

    return (
      <p className={orderStyles.productTip}>
        <i class="material-icons notranslate">error</i>{' '}
        <div>
          {errTipItem.map((item, index) => {
            let itemAddDot = `${index + 1}.${item}`;
            return index == 0 && errTipItem.length == 1 ? (
              <div
                data-label={
                  item == holdErrorTip ? 'productTipHold' : 'productTip'
                }
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ) : (
              <div
                data-label={
                  item == holdErrorTip ? 'productTipHold' : 'productTip'
                }
                dangerouslySetInnerHTML={{ __html: itemAddDot }}
              />
            );
          })}
        </div>
      </p>
    );
  };

  // sp 是不是 Awaiting fulfillment 状态
  spIsAwaitingFulfillment = sp => {
    const {
      child = {},
      status // tabStatus
    } = this.props;
    let flag = false;

    if (
      sp.logistics_infos &&
      child.status < 2048 &&
      sp.status != 6 &&
      child.status !== 8192
    ) {
      flag = true;
    }

    return flag;
  };

  // 限制该国家不支持的物流
  getShippingTip = sp => {
    // return null;
    const {
      listData: {
        ext: { address = {} }
      },
      unsupportedShipping
    } = this.props;
    const { country_code: countryCode = '' } = address;
    const shippingUnsupport =
      [2, 7].includes(sp.status) &&
      (unsupportedShipping.Global?.[sp.logistics_company] ||
        unsupportedShipping[countryCode]?.[sp.logistics_company]);
    return shippingUnsupport ? (
      <p className={orderStyles.productTip}>
        {intl.getHTML('order.freight_not_supported')}
      </p>
    ) : null;
  };

  // variant被删除提示
  getRemoveVariantTip = (child, listData) => {
    const removeArr = this.props.removedVariants[listData.id];

    if (!replaceExtStatus.includes(this.props.status)) {
      return null;
    }

    if (removeArr == undefined) {
      return null;
    }

    let tip = null;

    removeArr.forEach(item => {
      if (item.variant_id == child.variantId) {
        tip = (
          // <p className={orderStyles.productTip}>
          //   {intl.get('order.variant_delete_tip')}
          // </p>
          <span className={orderStyles.canceledBy}>
            {intl.get('order.variant_delete_tag')}
          </span>
        );
      }
    });

    return tip;
  };

  getChileTaxNumberInvalidTip = (listData, status) => {
    // fulfill 不提示
    if (status === 5) {
      return null;
    }
    // 智利税号必填
    if (
      listData.ext &&
      listData.ext.address &&
      listData.ext.address.country_code === 'CL'
    ) {
      if (!checkChileTaxNumberValid(listData.ext.address.rut_no)) {
        return <p className={orderStyles.productTip}>RUT number is required</p>;
      }
    }

    return null;
  };

  getClearanceInfoInvalidTip = (listData, status) => {
    if (
      listData.ext &&
      listData.ext.address &&
      listData.ext.address.country_code === 'KR'
    ) {
      if (!listData.ext.address.foreigner_passport_no) {
        return (
          <p className={orderStyles.productTip}>
            Customs Clearance Information is required
          </p>
        );
      } else if (!checkPassPortNo(listData.ext.address.foreigner_passport_no)) {
        return (
          <p className={orderStyles.productTip}>
            The format of the Customs Clearance Information is incorrect
          </p>
        );
      }
    }

    return null;
  };

  getAeSplitedOrderTip = (successSp, aeSplitedProductsMap, child) => {
    if (child?.canceledBy === 'shopify') {
      return null;
    }

    if (!aeSplitedProductsMap[successSp?.platform_product_id]) {
      return null;
    }

    return (
      <p className={orderStyles.productTip}>
        {intl.get('order.ae_splited_order')}
      </p>
    );
  };

  sendGA = e => {
    const {
      target: {
        dataset: { ga }
      }
    } = e;

    ga &&
      reactGaEvent({
        category: 'Order',
        action: ga
      });
  };

  pendingTip = () => {
    let tip = null;
    if (this.props.status == 1) {
      tip = (
        <div className={orderStyles.fulfilledIco}>
          <Tooltip
            placement="left"
            title={
              <p>
                {intl.get('order.pending_tip')}
                <a
                  href="https://help.dsers.com/pending-orders-introduction-for-shopify/"
                  target="_blank"
                  data-ga="still pending - here"
                  onClick={this.sendGA}
                >
                  {' '}
                  {intl.get('public.here')}{' '}
                </a>
                {intl.get('order.pending_tip_end')}
              </p>
            }
          >
            <Icon type="info-circle" className={orderStyles.pendingTip} />
          </Tooltip>
        </div>
      );
    }
    return tip;
  };

  // 还有没有可下单的数量
  hasFulfillableQuantity = (shopifyLineItemIndex, listData) => {
    const {
      detail: { line_items }
    } = listData;
    return line_items[shopifyLineItemIndex].fulfillable_quantity > 0;
  };

  // 获取bogo mapping 赠品的索引
  getBogoGetIndex = child => {
    let index = 0;
    child.sp.forEach((item, i) => {
      if (!item.is_base) {
        index = i;
      }
    });
    return index;
  };

  getAwaitingCancellationOnAETip = (awaitingCancellationOnAE, sp) => {
    let tip = null;
    if (!sp.platform_sku || !sp.platform_product_id) {
      return tip;
    }

    if (
      awaitingCancellationOnAE[`${sp.platform_product_id}--${sp.platform_sku}`]
    ) {
      tip = (
        <span className={orderStyles.canceledBy}>
          Awaiting cancellation on AliExpress
          <Tooltip title="Order cancellation requested on AliExpress, once supplier confirm the cancellation, the order status will update to Canceled on DSers accordingly.">
            <Icon type="question-circle" />
          </Tooltip>
        </span>
      );
    }
    return tip;
  };

  getEditSpTip = (tabIndex, sp) => {
    if (![2, 6, 7].includes(tabIndex)) {
      return null;
    }
    if (!sp.is_edit) {
      return null;
    }
    return (
      <>
        <span className={orderStyles.canceledBy}>
          {intl.get('order.dsers_action_edit')}
        </span>
        <Tooltip overlay={intl.get('order.edit_sp_tooltip')}>
          <Icon style={{ marginLeft: 5 }} type="question-circle" />
        </Tooltip>
      </>
    );
  };

  computedIndex = (child, status) => {
    var inx = 0;
    var newArr = [];
    child.sp.map((sp, index) => {
      newArr.push(sp.status);
      if (sp.status == status) {
        inx = index;
      }
    });

    var flags = false;
    flags = newArr.every(value => {
      return value == status;
    });

    if (flags) {
      inx = 0;
    }

    if (
      child.supplierMapType == 1 &&
      inx == 0 &&
      !child.sp[0]?.platform_order_id
    ) {
      child.sp.forEach((item, index) => {
        if (item?.platform_order_id) {
          inx = index;
          return;
        }
      });
    }
    return inx;
  };
  /**
   * 显示供应商信息
   * @param {*} info 供应商信息基本信息
   * @param {*} left 左侧的距离
   * @param {*} isFromAe 是否来源于AE
   */
  renderTitleSupplier = (info, left, isFromAe) => {
    const { supplyPlatform } = this.props;
    // 不来源于AE 则无信息展示
    if (isFromAe == '' && supplyPlatform !== 5) {
      return;
    }
    if (!info?.store_name) {
      return (
        <div
          className={orderStyles.shopifyDetailNone}
          style={{ marginLeft: `${left}px` }}
        >
          ——
        </div>
      );
    }

    return (
      <a
        className={orderStyles.shopifyDetail}
        style={{ marginLeft: `${left}px` }}
        //  href={info?.store_url}
        target="_blank"
      >
        {info?.store_name}
      </a>
    );
  };

  renderAgencyTitleSupplier = (child, sp, left) => {
    const { agencyList } = this.props;
    if (supplyPlatformEquals(child.platform_type, 5)) {
      const agencyId = sp.sale_id;
      const agencyInfo = agencyList?.find(i => `${i.id}` === `${agencyId}`);
      return (
        <span
          className={orderStyles.shopifyDetail}
          style={{ marginLeft: `${left}px` }}
          //  href={info?.store_url}
          target="_blank"
        >
          {agencyInfo ? agencyInfo?.agency_name : 'Unknown agency'}
        </span>
      );
    }
  };

  handleOpenModal = () => {
    this.props.dispatch({
      type: 'global/setConversionModal',
      payload: true
    });
  };

  render() {
    const {
      listData = {},
      child = {},
      index, // lineitem 索引
      status, // tabStatus
      updataExt,
      markAsFulfilled,
      markAsCancel,
      markAsPaid,
      activeBannerItems,
      onBannerClick,
      orderId,
      showMapping,
      userInfo,
      errTipItem,
      key,
      supplyPlatform
    } = this.props;

    if (child.status != 1 && status == 1) {
      return null;
    }

    const userId = userInfo?.user_id || '';
    const isV5 = list_V5.includes(userId);
    const isBannerUser = getVersion(userId);

    const { showEdit, spShowEdit } = this.state;
    let succSpIndex = this.getBasicSuccSp(child, status); // basMap bogMap 用主供应商显示还是用副供应商显示的索引

    let bogoGetIndex = this.getBogoGetIndex(child); // bogo mapping 赠品的索引

    const outerStatus = computerOrderStatus(listData, status);

    // basMap advMap是否显示  这2个Map 每个子订单只显示1个子子订单  advMap succSpIndex就返回0
    if (
      child.supplierMapType <= 3 ||
      child.supplierMapType == 5 ||
      child.supplierMapType == 6
    ) {
      if (status == 3 || status == 9 || status == 6) {
        succSpIndex = this.computedIndex(child, status);
      }

      if (
        child.sp &&
        child.sp[succSpIndex] &&
        child.sp[succSpIndex].status != status &&
        !this.spIsAwaitingFulfillment(child.sp[succSpIndex])
      ) {
        // 状态不对就return
        return null;
      }

      if (
        child.sp &&
        child.sp[succSpIndex] &&
        child.sp[succSpIndex].status == 2 &&
        !child.sp[succSpIndex].is_default &&
        child.status == outerStatus
      ) {
        return null;
      }

      // 订单状态是AwaitingFulfillment 但当前tab不是 就return
      if (this.spIsAwaitingFulfillment(child.sp[succSpIndex]) && status != 9) {
        return null;
      }
    }

    const successSp = child.sp[succSpIndex] || {};

    const moreStatus = this.getMoreStatus(); // Shopify refunded 等标识

    let budleNum =
      child.supplierMapType == 3 && this.showBogo(child, status, true)
        ? 2
        : child.supplierMapType == 4 &&
          child.sp.length > 0 &&
          this.showBundle(child, status)
        ? 1
        : 0;

    return (
      <div
        className={orderStyles.orderItem}
        data-label="order-item-box"
        data-key={child.id}
      >
        {child.supplierMapType == 4 ? (
          // 这块儿展示的是shopify订单信息 不是去ae下单的信息 相当于一个标识
          this.showBundle(child, status) ? (
            <>
              {this.getQtyZeroTip(errTipItem)}
              {/* {child.isPendingFulfillments && status == 2 ? (
                <p className={orderStyles.productTip}>
                  {intl.getHTML('order.qty_zero_tip')}
                </p>
              ) : null} */}
              {/* edit优先级最高 */}
              {child?.sp[0]?.is_edit ? null : (
                <>
                  <Bundle
                    child={child}
                    getCanceledBy={this.getCanceledBy}
                    moreStatus={moreStatus}
                    status={status}
                    useSp={successSp}
                    index={index}
                    listData={listData}
                    bundle={1}
                    flag={true}
                  />
                </>
              )}
            </>
          ) : null
        ) : child.supplierMapType != 3 ||
          (child.supplierMapType == 3 && this.showBogo(child, status, true)) ? (
          // mapping类型是base || advance || bogo主商品(不是赠品) 符合当前状态
          <>
            {child.supplierMapType == 3
              ? this.renderTitleSupplier(
                  child.sp.filter(item => item?.is_base)[0]?.store_info,
                  10,
                  successSp.platform_product_id
                )
              : this.renderAgencyTitleSupplier(child, successSp, 10)}
            {this.getQtyZeroTip(errTipItem)}
            <div
              data-item="order_product"
              className={className(orderStyles.product, {
                [orderStyles.productNoBorder]:
                  child.supplierMapType > 2 && child.extList.length > 0
              })}
            >
              {!this.props.singleErrTips &&
                this.getProductError(successSp, child, listData, status)}
              {this.getActionEditChangeError(
                successSp,
                child,
                listData,
                status
              )}
              {this.getShippingTip(successSp)}
              {this.getChileTaxNumberInvalidTip(listData, status)}
              {this.getAeSplitedOrderTip(
                successSp,
                listData.aeSplitedProductsMap,
                child
              )}
              <div className={orderStyles.productWrap}>
                {child.id in activeBannerItems &&
                this.props.conversionFinish === false ? (
                  <EnableCloseTag
                    data={activeBannerItems[child.id]}
                    spId={child.shopifyProductId}
                    storeId={child.storeId}
                    // title={intl.get('bargain.reward')}
                    title={
                      isV5 ? 'GET 20% OFF' : intl.get('bargain.found_cheaper')
                    }
                    // showClose
                    trickConfig={bannerTrickConfig}
                    onLinkClick={this.handleOpenModal}
                  />
                ) : null}
                <Product
                  supplyPlatform={supplyPlatform}
                  child={child}
                  listData={listData}
                  useSp={successSp}
                  status={status}
                  bundle={budleNum}
                />
                {/* <Cost child={child} useSp={successSp} listData={listData} /> */}
                <Shipping
                  child={child}
                  listData={listData}
                  updataExt={updataExt}
                  index={index}
                  useSp={successSp}
                  status={status}
                  shippingMethod={successSp?.logistics_company}
                  supplyPlatform={supplyPlatform}
                />

                <No
                  child={child}
                  listData={listData}
                  useSp={successSp}
                  onRef={this.bindRef.bind(this)}
                  updataExt={updataExt}
                  index={index}
                  // isShowInput={this.props.isShowInput}
                  status={status}
                  sonIndex={child.supplierMapType == 3 ? 0 : undefined}
                  changeAsync={this.props.changeAsync}
                  changeOrderNoState={this.props.changeOrderNoState}
                  supplyPlatform={supplyPlatform}
                />
                {this.pendingTip()}
                {!notEditOrderStatus.includes(status) &&
                !getCanceled(child, status, child.useSp) ? (
                  <Actions
                    child={child}
                    status={status}
                    toggleShowEdit={this.toggleShowEdit}
                    useSp={successSp}
                    listData={listData}
                    index={index}
                    // sonIndex={succSpIndex}
                    markAsFulfilled={markAsFulfilled}
                    markAsCancel={markAsCancel}
                    markAsPaid={markAsPaid}
                    showMapping={showMapping}
                    updataExt={updataExt}
                    supplyPlatform={supplyPlatform}
                  />
                ) : (
                  <ActionsLimeTime
                    supplyPlatform={supplyPlatform}
                    index={index}
                    child={child}
                    useSp={successSp}
                  />
                )}
              </div>
              <div>
                {this.getCanceledBy(
                  child,
                  status,
                  succSpIndex,
                  index,
                  listData
                )}
                {/* {this.getAgencyAuthStatusTag(child, status)} */}
                {moreStatus}
                {this.getAwaitingCancellationOnAETip(
                  child.awaitingCancellationOnAE,
                  successSp
                )}
                {this.getEditSpTip(status, successSp)}
                {this.getRemoveVariantTip(child, listData)}
              </div>
            </div>
            {showEdit ? (
              <Bind
                child={child}
                sonIndex={succSpIndex}
                listData={listData}
                updataExt={updataExt}
                index={index}
                supplyPlatform={supplyPlatform}
              />
            ) : null}
          </>
        ) : this.showLessBogo(child, status) && child.supplierMapType == 3 ? (
          // bogo 赠品符合当前状态 主商品不符合 这块显示shopify商品信息 标识作用
          <Bogo
            child={child}
            getCanceledBy={this.getCanceledBy}
            moreStatus={moreStatus}
            status={status}
            index={index}
            listData={listData}
            bundle={2}
          />
        ) : null}

        {child.supplierMapType == 3 &&
        this.showBogo(child, status, false) &&
        child.sp[1] ? (
          // bogo 赠品符合当前状态 显示赠品信息
          <>
            {this.renderTitleSupplier(
              child.sp[bogoGetIndex]?.store_info,
              38,
              successSp.platform_product_id
            )}
            <div className={orderStyles.extend}>
              {/* <p className={orderStyles.extendCategory}>
                <span>BOGO</span>
              </p> */}
              <div className={orderStyles.product} data-item="order_product2">
                {this.getProductError(child.sp[bogoGetIndex], child, listData)}
                {this.getShippingTip(child.sp[bogoGetIndex])}
                {this.getChileTaxNumberInvalidTip(listData, status)}
                {this.getAeSplitedOrderTip(
                  child.sp[bogoGetIndex],
                  listData.aeSplitedProductsMap,
                  child
                )}
                <div className={orderStyles.productWrap}>
                  <Product
                    child={child}
                    useSp={child.sp[bogoGetIndex]}
                    status={status}
                    supplyPlatform={supplyPlatform}
                  />
                  {/* <Cost
                    child={child}
                    useSp={child.sp[bogoGetIndex]}
                    listData={listData}
                  /> */}
                  <Shipping
                    child={child}
                    listData={listData}
                    updataExt={updataExt}
                    index={index}
                    useSp={child.sp[bogoGetIndex]}
                    sonIndex={bogoGetIndex}
                    status={status}
                    supplyPlatform={supplyPlatform}
                  />
                  <No
                    child={child}
                    listData={listData}
                    useSp={child.sp[bogoGetIndex]}
                    onRef={this.bindRef.bind(this)}
                    // isShowInput={this.props.isShowInput}
                    sonIndex={bogoGetIndex}
                    index={index}
                    updataExt={updataExt}
                    status={status}
                    changeAsync={this.props.changeAsync}
                    changeOrderNoState={this.props.changeOrderNoState}
                    supplyPlatform={supplyPlatform}
                  />
                  {this.pendingTip()}
                  {/* bodo单 action edit */}
                  {!notEditOrderStatus.includes(status) &&
                  !getCanceled(child, status, child.sp[bogoGetIndex]) ? (
                    <Actions
                      child={child}
                      status={status}
                      toggleShowEdit={this.toggleShowEdit}
                      useSp={child.sp[bogoGetIndex]}
                      sonIndex={bogoGetIndex}
                      index={index}
                      listData={listData}
                      markAsFulfilled={markAsFulfilled}
                      showMapping={showMapping}
                      updataExt={updataExt}
                      supplyPlatform={supplyPlatform}
                    />
                  ) : (
                    <ActionsLimeTime
                      supplyPlatform={supplyPlatform}
                      index={index}
                      child={child}
                      useSp={successSp}
                    />
                  )}
                </div>
                {this.getAECanceled(child, child.sp[1], 1, status)}
                <div>
                  {this.getAwaitingCancellationOnAETip(
                    child.awaitingCancellationOnAE,
                    child.sp[bogoGetIndex]
                  )}
                  {this.getEditSpTip(status, child.sp[bogoGetIndex])}
                  {this.getRemoveVariantTip(child, listData)}
                </div>
              </div>
              {spShowEdit[bogoGetIndex] ? (
                <Bind
                  child={child}
                  listData={listData}
                  updataExt={updataExt}
                  sonIndex={bogoGetIndex}
                  index={index}
                  supplyPlatform={supplyPlatform}
                />
              ) : null}
            </div>
          </>
        ) : null}

        {child.supplierMapType == 4 &&
        child.sp.length > 0 &&
        this.showBundle(child, status) ? (
          // 显示bundle商品
          <div className={orderStyles.extend}>
            {/* <p className={orderStyles.extendCategory}>
              <span>Bundle</span>
            </p> */}
            {child.sp.map((sp, spIndex) => {
              const flag = status == 9 && sp.logistics_infos;

              if (sp.status != status && !flag) {
                return null;
              }

              return (
                <>
                  {this.renderTitleSupplier(
                    sp?.store_info,
                    38,
                    successSp.platform_product_id
                  )}
                  <div
                    className={orderStyles.product}
                    data-item="order_product3"
                  >
                    {this.getProductError(sp, child, listData)}
                    {this.getShippingTip(sp)}
                    {this.getChileTaxNumberInvalidTip(listData, status)}
                    {this.getAeSplitedOrderTip(
                      sp,
                      listData.aeSplitedProductsMap,
                      child
                    )}
                    <div className={orderStyles.productWrap}>
                      <Product
                        child={child}
                        useSp={sp}
                        status={status}
                        supplyPlatform={supplyPlatform}
                      />
                      {/* <Cost child={child} useSp={sp} listData={listData} /> */}
                      <Shipping
                        child={child}
                        listData={listData}
                        updataExt={updataExt}
                        index={index}
                        useSp={sp}
                        sonIndex={spIndex}
                        status={status}
                        supplyPlatform={supplyPlatform}
                      />
                      <No
                        updataExt={updataExt}
                        child={child}
                        listData={listData}
                        useSp={sp}
                        index={index}
                        sonIndex={spIndex}
                        status={status}
                        changeAsync={this.props.changeAsync}
                        changeOrderNoState={this.props.changeOrderNoState}
                        supplyPlatform={supplyPlatform}
                      />
                      {this.pendingTip()}
                      {/* bundle单 actions */}
                      {!notEditOrderStatus.includes(status) &&
                      !getCanceled(child, status, child.sp[1]) ? (
                        <Actions
                          listData={listData}
                          child={child}
                          status={status}
                          toggleShowEdit={this.toggleShowEdit}
                          useSp={sp}
                          sonIndex={spIndex}
                          index={index}
                          markAsFulfilled={markAsFulfilled}
                          showMapping={showMapping}
                          updataExt={updataExt}
                          supplyPlatform={supplyPlatform}
                        />
                      ) : (
                        <ActionsLimeTime
                          supplyPlatform={supplyPlatform}
                          index={index}
                          child={child}
                          useSp={successSp}
                        />
                      )}
                    </div>
                    {this.getAECanceled(child, sp, spIndex, status)}
                    <div>
                      {this.getAwaitingCancellationOnAETip(
                        child.awaitingCancellationOnAE,
                        sp
                      )}
                      {this.getEditSpTip(status, sp)}
                      {this.getRemoveVariantTip(child, listData)}
                    </div>
                  </div>
                  {spShowEdit[spIndex] ? (
                    <Bind
                      child={child}
                      listData={listData}
                      updataExt={updataExt}
                      sonIndex={spIndex}
                      index={index}
                      supplyPlatform={supplyPlatform}
                    />
                  ) : null}
                </>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}
