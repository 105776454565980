import React from 'react';
import { connect } from 'dva';
import { Icon, Modal, Button, Input } from 'antd';
import debounce from 'lodash/debounce';

import intl from 'react-intl-universal';
import { statusMap } from './util.js';
import { getLanguage } from '../../utils/utils';
import { confirmLanguage } from '../../common/sysconfig';
import { placeOrderViaExt } from 'utils/placeOrderViaExt.js';

import orderStyles from './Order.less';

@connect(({ order, loading }) => ({
  order,
  loading: loading.models.order,
  confirmLoading: loading.effects['order/placeAgencyAndTmallAction']
}))
export default class DuplicateOrderConfirm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      hasInputConfirm: false,
      placeLoading: false,
    };
  }

  componentWillMount() {
    const { data } = this.props;

    this.placeOrderList = data;

    this.setState({
      data
    });
  }

  placeOrderList = null;
  currentLanguage = getLanguage();

  inputConfirm = e => {
    this.setState({
      hasInputConfirm:
        e.target.value.toLocaleLowerCase().trim() ==
        confirmLanguage[this.currentLanguage]
    });
  };

  handleOrder = debounce(() => {
    this.placeOrder(0);
    this.setState({
      placeLoading: true,
    })
  }, 300, {leading: true});

  // 点下单
  placeOrder = i => {
    const { status, dupOrderType } = this.props;
    const data = this.placeOrderList;
    const oneOrder = data[i];
    console.log(oneOrder);
    if (!oneOrder.checked || oneOrder.canOrderDisabled) {
      if (data.length > i + 1) {
        this.placeOrder(i + 1);
      } else {
        this.setState({
          placeLoading: false,
        })
        this.props.hidePlaceOrder();
      }
      return;
    }

    const orderParams = this.props.paramsData?.find(i => i.id === oneOrder.id);

    // console.log(orderParams, this.props.paramsData);

    this.props.dispatch({
      type: 'order/placeAgencyAndTmallAction',
      payload: {
        data: {
          // ...orderParams,
          placeParams: { order: { ...orderParams, is_check: true } }
        },
        callback: d => {
          if (dupOrderType === 2) {
            placeOrderViaExt({ ...oneOrder, aeCaptchaSolveType: 2 }).then(
              res => {
                // console.log(res);
                this.props.getExtensionPlaceProcess();
                if (data.length > i + 1) {
                  this.placeOrder(i + 1);
                } else {
                  this.props.hidePlaceOrder();
                }
              }
            );
          } else {
            if (d?.data?.success) {
              this.props.countResult.success++;
            } else {
              this.props.countResult.failed++;
            }
            if (data.length > i + 1) {
              this.placeOrder(i + 1);
            } else {
              this.props.hidePlaceOrder();
              this.setState({
                placeLoading: false,
              })
            }
          }
        }
      }
    });
  };

  render() {
    const { visible, hidePlaceOrder, confirmLoading } = this.props;

    const { data, hasInputConfirm, placeLoading } = this.state;

    return (
      <Modal
        visible={visible}
        width={560}
        footer={false}
        maskClosable={false}
        closable
        onCancel={hidePlaceOrder}
        wrapClassName={orderStyles.duplicateOrderConfirm}
      >
        <div className={orderStyles.main}>
          <div className={orderStyles.icon}>
            <Icon type="question-circle" />
          </div>
          <div className={orderStyles.content}>
            <p className={orderStyles.desc}>
              {/* {intl.get('duplicate_order_confirm.dec1')} */}
              We found that you already placed the order{' '}
              {data.map(order => {
                return <span>{order.detail.name}</span>;
              })}{' '}
              to Agency previously. Do you still want to place them to Agency
              again? This may create duplicated orders.
              {/* {intl.get('duplicate_order_confirm.dec2')} */}
            </p>
            <p className={orderStyles.confirmTip}>
              {/* {intl.get('duplicate_order_confirm.input_confirm')} */}
              Input {confirmLanguage[this.currentLanguage]?.toUpperCase?.()} to
              place the orders to Agency
            </p>
            <div className={orderStyles.input}>
              <Input size="large" onChange={this.inputConfirm} />
            </div>
            <div className={orderStyles.btns}>
              <Button
                size="large"
                disabled={hasInputConfirm == false}
                loading={confirmLoading || placeLoading}
                onClick={this.handleOrder}
              >
                {intl.get('public.yes')}
              </Button>
              <Button type="primary" size="large" disabled={placeLoading} onClick={hidePlaceOrder}>
                {intl.get('public.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
