import { AGENCY_LOCAL_STORE_UPLOAD_TASK_KEY } from '../constants';

export const getTasks = () => {
    // return [{id: 1, status: 3}]
    try {
       const tasks = JSON.parse(localStorage.getItem(AGENCY_LOCAL_STORE_UPLOAD_TASK_KEY) || '[]');
       if(typeof tasks.length !== 'undefined') {
        return tasks;
       }
    } catch (error) {
        return [];
    }
    
}

export const setTasks = (tasks) => {
    localStorage.setItem(AGENCY_LOCAL_STORE_UPLOAD_TASK_KEY, JSON.stringify(tasks));
}

export const mergeTasks = (tasks) => {
    const oldTask = getTasks();
    tasks.forEach(i => {
        const exsits = oldTask.find(old => old.id === i.id);
        if(!exsits) {
            oldTask.push(i);
        } else{
            Object.assign(oldTask, i);
        }
    });
    setTasks(oldTask);
}

// export const getTaskInfo = async ({taskId}) => {
//     return {task_id: '123', status: 1};
// }