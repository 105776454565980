import React from 'react';
import { connect } from 'dva';
import { Form, Input, Row, Col, Select, Icon, Spin } from 'antd';
import TextField from '@material-ui/core/TextField';

import intl from 'react-intl-universal';
import { setLocalStorage, getLocalStorage } from '../../../utils/utils';

import orderProductsStyles from '../OrderProducts.less';
import orderErrorTipIco from '../../../assets/order/orderErrorTipIco.png';
import {
  checkChileTaxNumberValid,
  checkPassPortNo,
  checkAlienPassPortNo
} from '../util';

import './EditAddress.css'
import { getTmallCaiNiaoAddressValidateRules } from 'services/order';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 12
  }
};

@connect(({ order, loading, global }) => ({
  order,
  loading: loading.models.order,
  selectLoading: loading.effects['order/province'],
  worldTelephoneAreaCodeMap: global.worldTelephoneAreaCodeMap,
}))
@Form.create()
export default class EditAddress extends React.PureComponent {
  constructor(props) {
    super(props);
    this.orderSetting = JSON.parse(localStorage.getItem('settingOrderData')) || {};
    this.state = {
      passportInputDisabled1: false,
      passportInputDisabled2: false,
      newRulesLoading: false
    };
  }

  componentWillMount() {
    const { listData: { ext: { address = {} } } } = this.props;
    const { status } = this.props;

    this.prevCountryCode = address.country_code;
    this.cacheAddress = address;
    this.originAddress = { ...address };
    this.countryLists = JSON.parse(localStorage.getItem('countryList')) || [];

    if(status == 7){
      this.getSelectData()
    }
    
  }

  componentDidMount() {
    const { status } = this.props;

    if (status == 7) {
      this.getAERules();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { listData: { ext: { address: { country_code } } } } = nextProps;
    this.prevCountryCode = country_code;
  }

  // 获取AE地址效验规则
  getAERules = async () => {
    // const rules = getLocalStorage(`${this.cacheAddress.country_code}-rules`);
    // if (rules) {
    //   this.aeRules = rules;
    //   this.forceUpdate();
    //   return;
    // }

    if (!this.cacheAddress.country_code) {
      return;
    }
    this.setState({ newRulesLoading: true });
    try {
      const res = await getTmallCaiNiaoAddressValidateRules({
        data: { countryCode: this.cacheAddress.country_code }
      });
      console.log(res);
      if(res.validatorconfigs) {
        this.aeRules = res.validatorconfigs;
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ newRulesLoading: false });
    // 去除多余的请求
    return;
    this.props.dispatch({
      type: 'order/aeAddressRules',
      payload: {
        data: {
          country_code: this.cacheAddress.country_code
        },
        callback: d => {
          if (d.code == 2000) {
            // setLocalStorage(
            //   `${this.cacheAddress.country_code}-rules`,
            //   d.data,
            //   24 * 3600 * 1000
            // );
            let data = d.data;
            if(this.cacheAddress.country_code == 'DE'){
              let deAddress1ValidatorConfigs = [
                {
                    "minLength": 0,
                    "errorCode": "addressRequired@buyeraddress",
                    "name": "required",
                    "maxLength": 2147483647,
                    "errorMessage": "Please enter an address"
                },
                {
                    "minLength": 0,
                    "uiPattern": "^([A-Za-z -~ -ÿĀ-ſ]+)",
                    "errorCode": "englishOrGermanOnly@buyeraddress",
                    "name": "regexp",
                    "maxLength": 2147483647,
                    "errorMessage": "Please enter English or German"
                },
                {
                    "minLength": 1,
                    "errorCode": "addressLength@buyeraddress",
                    "name": "length",
                    "maxLength": 256,
                    "errorMessage": "Please enter between 1 - 256 characters"
                }
              ]
              let deAddress2ValidatorConfigs = [
                {
                    "minLength": 0,
                    "errorCode": "addressRequired@buyeraddress",
                    "name": "required",
                    "maxLength": 2147483647,
                    "errorMessage": "Please enter an address"
                },
                {
                    "minLength": 0,
                    "uiPattern": "^[\u0000-ÄäÖöÜüẞß\\s0-9/\\-+.'’'‘,-\\\\(\\\\);:Other\\d\\D]*$",
                    "errorCode": "englishOrGermanOnly@buyeraddress",
                    "name": "regexp",
                    "maxLength": 2147483647,
                    "errorMessage": "Please enter English or German"
                },
                {
                    "minLength": 1,
                    "errorCode": "address2Length@buyeraddress",
                    "name": "length",
                    "maxLength": 256,
                    "errorMessage": "Please enter less 256 characters"
                }
              ]
              data = data.map(item => {
                if(item.fieldName == 'address'){
                  return {
                    ...item,
                    validatorConfigs: deAddress1ValidatorConfigs
                  }
                }
                if(item.fieldName == 'address2'){
                  return {
                    ...item,
                    validatorConfigs:deAddress2ValidatorConfigs
                  }
                }
                return item
              })
            }
            // this.aeRules = data;
          }
        }
      }
    });
  };

  // 获取是否改对了
  getIsAllRight = () => {
    let flag = true;
    Object.values(this.nameMap).forEach(item => {
      if (this[item[1]] == 'error') {
        flag = false;
      }
    });
    return flag;
  };

  // 获取省列表和城市列表
  getSelectData = () => {
    const { country_code, province } = this.cacheAddress;

    // if (this.provinceMap[country_code]) {
    //   return;
    // }

    this.props.dispatch({
      type: 'order/province',
      payload: {
        data: {
          country_code
        },
        callback: d => {
          if (d.code === 2000) {
            this.hasFetchCityList = true;
            this.provinceMap[country_code] = d.data || [];
            this.provinceList = d.data || [];
            this.setCityList(province, country_code);
          }
        }
      }
    });
  };

  checkCity = () => {
   return new Promise((resolve, reject) => {
      const { country_code, province } = this.cacheAddress;
      if (this.hasFetchCityList) {
        resolve()
      } else {
        this.props.dispatch({
          type: 'order/province',
          payload: {
            data: {
              country_code
            },
            callback: d => {
              if (d.code === 2000) {
                 this.hasFetchCityList = true;
                resolve()
              } else {
                reject()
              }
            }
          }
        });   
      }

    });
  }
  setCityList = (province, country_code) => {
    const provinceList = this.provinceMap[country_code];
    
    if (provinceList) {
      provinceList.forEach(item => {
        if (item.Name == province?.trim()) {
          this.cityList = item.Citys || [];
        }
      });
    }
    
  };

  rule_Flag = true

  // 改变除了国家&省以外的
  changeAll = (type, v) => {
    const { ext: { address = {}, multiple_platform_error = {} } } = this.props.listData;
    const err = multiple_platform_error.agency || {};
    this.cacheAddress[type] = v;

    if(type == 'contact_person'){
      address.contact_person = v;
    }

    if(type == 'address'){
      address.address = v
    }

    if(type == 'address2'){
      address.address2 = v
    }
    

    if(type == 'rut_no'){
      this.rule_Flag = false;
      this.rutError = 'success';
      this.rutErrorTxt = '';
    }
    if (type == 'phone_number') {
      this.cacheAddress.mobile_no = v;
    } else if (type == 'foreigner_passport_no') {
      if (this.cacheAddress.foreigner_passport_no.length > 0) {
        this.setState({
          passportInputDisabled1: true
        });
        address.foreigner_passport_no = v
        this.AddressError = ''
        this.AddressErrorTxt = ''
        this.Address2Error = ''
        this.Address2ErrorTxt = ''
      } else {
        this.setState({
          passportInputDisabled1: false
        });
      }
    } else if (type == 'passport_no') {
      this.cacheAddress.passport_no = v.replace(/\s/g, '');
      if (this.cacheAddress.passport_no.length > 0) {
        this.setState({
          passportInputDisabled2: true
        });
        address.passport_no = v
      } else {
        this.setState({
          passportInputDisabled2: false
        });
      }
    }
    if(type == 'city'){
      if(['JP','KR'].includes(this.cacheAddress.country_code)){
        this.cacheAddress[type] = v.replace(/\(.+\)/g,'').trim();
      }else{
        this.cacheAddress[type] = v
      }
    }
    

    this.checkHanguo(address)
    this.updataExt();
  };

  // 改变国家
  changeCountry = (v, s) => {
    this.cacheAddress.country_code = v;
    this.cacheAddress.country = s.props.children;
    this.provinceList = [];
    this.cityList = [];

    const { ext: { address = {}, multiple_platform_error = {} } } = this.props.listData;
    const err = multiple_platform_error.agency || {};
    address.country_code = v
    this.checkHanguo(address)


    this.getAERules();
    this.updataExt();
    this.getSelectData()
  };


  // 改变省份
  changeProvince = v => {
    let vs = null;
    if(['JP','KR'].includes(this.cacheAddress.country_code)){
      vs = v.replace(/\(.+\)/g,'').trim()
    }else{
      vs = v;
    }
    this.cacheAddress.province = vs;
    this.updataExt();
    this.setCityList(vs, this.cacheAddress.country_code);
  };

  // 更新往外传
  updataExt = () => {
    const { updataExt, listData: { id } } = this.props;
    // const isAllRight = this.getIsAllRight();

    updataExt({
      type: 'address',
      address: this.cacheAddress,
      oid: id,
      // isAllRight,
      prevCountryCode: this.prevCountryCode
    });
  };

  // 用ae规则验证地址
  testAeRules = (key, mobileRemovePhoneArea = false) => {
    // key校验哪个字段
    if (!this.aeRules) {
      return true;
    }

    const keys = this.nameMap[key]; // 例如 keys = ['zip', 'PostCodeError', 'PostCodeErrorTxt']

    let v = this.cacheAddress[keys[0]]; // 校验字段的值
    if (key === 'mobileNo' && v) {
      if(v?.[0] && v?.[0]) {
        if(v?.[0] === '+') {
          v = v.replace(new RegExp(`^\\+${this.props?.worldTelephoneAreaCodeMap[this.cacheAddress?.country_code]}`), '');
        }
      }
      // v = v.replace(/^\+/g, '');
      // if(mobileRemovePhoneArea) {
      //   // console.log(new RegExp(`^${this.props?.worldTelephoneAreaCodeMap[this.cacheAddress?.country_code]}`), 'regexp');
        
      //   v = v.replace(new RegExp(`^${this.props?.worldTelephoneAreaCodeMap[this.cacheAddress?.country_code]}`), '');
      //   // console.log(v, 'replaced area code');
      // }
      // console.log(this.aeRules, 'ae rules');
      
    }
    let flag = true;
    let errInfo = null;

    this.aeRules.some(item => {
      if (item.fieldName === key) {
        item.validatorConfigs.some(err => {
          if (err.name === 'required' && v.length === 0) {
            flag = false;
            errInfo = err;
            return true;
          }
          if (err.name === 'regexp') {
            const regexp = new RegExp(err.uiPattern);
            if (!regexp.test(v)) {
              flag = false;
              errInfo = err;
              return true;
            }
          }
          if (key === 'zip' && err.name === 'length' && err.maxLength) {
            this.PostCodeMaxLength = err.maxLength;
          }
          if (
            err.name === 'length' &&
            (v.length < err.minLength || v.length > err.maxLength)
          ) {
            flag = false;
            errInfo = err;
            return true;
          }
          return false;
        });
        return true;
      }
      return false;
    });

    if (errInfo) {
      this[keys[1]] = 'error';
      this[keys[2]] = errInfo.errorMessage;
    } else {
      if(mobileRemovePhoneArea) {
        this[keys[1]] = '';
        this[keys[2]] = '';
      }
    }
    return flag;
  };


  checkKoreaChar = str => {
    for(var i=0; i<str.length; i++) {
      if(((str.charCodeAt(i) > 0x3130 && str.charCodeAt(i) < 0x318F) || (str.charCodeAt(i) >= 0xAC00 && str.charCodeAt(i) <= 0xD7A3))) {
          return true;
        }
    }
    return false;
  }


  checkAddress3 = str => {
    if(/^[㄰-㆏\uac00-\ud7ff\s]+$/.test(str) || /[A-Za-z]+$/.test(str)){
      return true
    }
    return false;
  }


  checkProvinceList = (value) => {
    let bbb = this.provinceList.find(item => item.Name == value)
    return bbb
  }

  checkHanguo = cacheAddress => {
    if(cacheAddress.province == '' && cacheAddress.country_code == 'KR'){
      this.ProvinceError = 'error';
      this.ProvinceErrorTxt = intl.get(
      'order.edit_address.select_province'
      );
    }
    this.checkProvinceList(cacheAddress.province)
    if(cacheAddress.country_code == 'KR' && !this.checkKoreaChar(cacheAddress.province)){
      if(this.checkProvinceList(cacheAddress.province)){
        this.ProvinceError = '';
        this.ProvinceErrorTxt = '';
      }else{
      this.ProvinceError = 'error';
      this.ProvinceErrorTxt = intl.get(
      'order.edit_address.select_province'
      );
      }
      
    }else{
      this.ProvinceError = '';
      this.ProvinceErrorTxt = '';
    }

    if(cacheAddress.country_code == 'KR' && cacheAddress.passport_no !== '' && /^(p|P)\d+/.test(cacheAddress.passport_no)){
      if(!/^[㄰-㆏\uac00-\ud7ff\s]+$/.test(cacheAddress.contact_person)){
        this.ContactNameError = 'error';
        this.ContactNameErrorTxt = (
          <span>
            Please fill in the contact name in Korean.
          </span>
        );
      }else{
        this.ContactNameError = '';
        this.ContactNameErrorTxt = ''
      }

      if(cacheAddress.country_code == 'KR'){
        if(this.checkKoreaChar(cacheAddress.address)){
          this.AddressError = '';
          this.AddressErrorTxt = '';
        }else{
          this.AddressError = 'error';
          this.AddressErrorTxt = 'Please fill in the address in Korean';
        }


        if(cacheAddress.address2.length == 0 || this.checkKoreaChar(cacheAddress.address2)){
          this.Address2Error = '';
          this.Address2ErrorTxt = '';
        }else{
          this.Address2Error = 'error';
          this.Address2ErrorTxt = 'Please fill in the address in Korean';
        }

      }else{
        this.AddressError = '';
          this.AddressErrorTxt = '';
          this.Address2Error = '';
          this.Address2ErrorTxt = '';
      }


    }

    if(cacheAddress.country_code == 'KR' && cacheAddress.foreigner_passport_no !== ''){
      //不是韩国公民
      //既可以是韩文  又可以是英文  ------ 简直离谱
    

      if(/^[㄰-㆏\uac00-\ud7ff\s]+$/.test(cacheAddress.contact_person)){
        this.ContactNameError = '';
        this.ContactNameErrorTxt = ''
        return
      }

      if(/[A-Za-z]+/.test(cacheAddress.contact_person)){
        this.ContactNameError = '';
        this.ContactNameErrorTxt = ''
        return
      }else{
        this.ContactNameError = 'error';
        this.ContactNameErrorTxt = intl.get('order.edit_address.english_only');
      }
    }

  }

  checkKr = cacheAddress => {
        //如果是韩国公民 && 不是韩文
        if(cacheAddress.country_code == 'KR' && cacheAddress.passport_no !== '' && /^(p|P)\d+/.test(cacheAddress.passport_no)){
          if(!/^[㄰-㆏\uac00-\ud7ff\s]+$/.test(cacheAddress.contact_person)){
            this.ContactNameError = 'error';
            this.ContactNameErrorTxt = (
              <span>
                Please fill in the contact name in Korean.
              </span>
            );
          }else{
            this.ContactNameError = '';
            this.ContactNameErrorTxt = ''
          }
        }
        
        //非韩国公民
        if(cacheAddress.country_code == 'KR' && cacheAddress.foreigner_passport_no !== ''){
          //不是韩国公民
          //既可以是韩文  又可以是英文  ------ 简直离谱
          if(!/^[㄰-㆏\uac00-\ud7ff\s]+$/.test(cacheAddress.contact_person)){
            this.ContactNameError = 'error';
            this.ContactNameErrorTxt = (
              <span>
                Please enter Korean only333.
              </span>
            );
          }else{
            this.ContactNameError = '';
            this.ContactNameErrorTxt = ''
          }

          if(!/[A-Za-z]+/.test(cacheAddress.contact_person)){
            this.ContactNameError = 'error';
            this.ContactNameErrorTxt = (
              <span>
                Please enter Korean only333.
              </span>
            );
          }else{
            this.ContactNameError = '';
            this.ContactNameErrorTxt = ''
          }

        }

  }

  testFRPhoneNumber = v => {
    const arr = [ '+336', '+337', '+3306', '+3307', '3306', '3307', '336', '337', '7', '6', '06', '07' ];
    const i = arr.find( s => v.startsWith( s ) );

    let flag = false;

    if( i === undefined ){
      flag = false;
    }else if( i.length === 5 && v.length === 13 ){
      flag = true;
    }else if( i.length === 4 && v.length === 12 && i.startsWith('+') ){
      flag = true;
    }else if( i.length === 4 && v.length === 12 && i.startsWith('33') ){
      flag = true;
    }else if( i.length === 3 && v.length === 11 ){
      flag = true;
    }else if( v.length === 10 ){
      flag = true;
    }else if( i.length === 1 && v.length === 9 ){
      flag = true;
    }else{
      flag = false;
    }

    if( !Number( v ) ){
      flag = false;
    }

    return flag;

  }


  nameMap = {
    zip: ['zip', 'PostCodeError', 'PostCodeErrorTxt'],
    contactPerson: [
      'contact_person',
      'ContactNameError',
      'ContactNameErrorTxt'
    ],
    address: ['address', 'AddressError', 'AddressErrorTxt'],
    address2: ['address2', 'Address2Error', 'Address2ErrorTxt'],
    country: ['country', 'CountryError', 'CountryErrorTxt'],
    province: ['province', 'ProvinceError', 'ProvinceErrorTxt'],
    city: ['city', 'CityError', 'CityErrorTxt'],
    mobileNo: ['phone_number', 'TelError', 'TelErrorTxt'],
    cpf: ['cpf', 'CpfError', 'CpfErrorTxt'],
    phoneNumber: ['phone_number', 'TelError', 'TelErrorTxt']
  };

  prevCountryCode = '';
  aeRules = null; // AE验证规则
  originAddress = null;
  countryLists = [];
  provinceList = [];
  cityList = [];
  provinceMap = {}; // 国家对应的省map

  canExitStatus = [2, 6, 7]; // 能修改的状态

  ContactNameError = 'success';
  ContactNameErrorTxt = '';
  TelError = 'success';
  TelErrorTxt = '';
  CountryError = 'success';
  CountryErrorTxt = '';
  AddressError = 'success';
  AddressErrorTxt = '';
  Address2Error = 'success';
  Address2ErrorTxt = '';
  ProvinceError = 'success';
  ProvinceErrorTxt = '';
  CityError = 'success';
  CityErrorTxt = '';
  PostCodeError = 'success';
  PostCodeErrorTxt = '';
  CompanyError = 'success';
  CompanyErrorTxt = '';
  CpfError = 'success';
  CpfErrorTxt = '';
  rutError = 'success';
  rutErrorTxt = '';
  CcError = 'success';
  CcErrorTxt = '';
  AlienCcError = '';
  AlienCcErrorTxt = '';
  rutErrTip = '';
  PostCodeMaxLength = '';

  hasFetchCityList = false; // 有没有获取过city或province列表
  SaveCacheAddress = 1;

  cacheAddress = {};

  render() {
    const { selectLoading, form, listData, status } = this.props;

    const { getFieldDecorator } = form;

    const { ext: { address = {}, multiple_platform_error = {} } } = listData;

    const err = multiple_platform_error?.agency || {};

    if(address.country_code == "KR" && status == 7){
      this.checkHanguo(address)
    }else{
      this.AddressError = ''
      this.AddressErrorTxt = ''
      this.Address2Error = ''
      this.Address2ErrorTxt = ''
      this.ContactNameError = '';
      this.ContactNameErrorTxt = ''
    }

    let { msg = '' } = err;
    let defaultTelErr = false;

    // code是11就是不知道什么原因 所以都检查一遍
    // 6 也是
    if (err.code === 11 || err.code === 6) {
      msg = '[ 1, 2, 3, 4, 5, 6, 7, 8 ]'; // msg 里的信息对应具体哪个字段错误 例如 8是邮编错误
    }

    const inputDisabled = !this.canExitStatus.includes(status); // 是否禁用input 只有awaiting order || canceled || failed order 才能修改
    const { cacheAddress } = this;

    if (cacheAddress && cacheAddress.country_code === 'CL') {
      if (!cacheAddress.rut_no) {
        this.rutError = 'error';
        this.rutErrorTxt = intl.get('order.edit_address.rut_required');
      } else {
        this.rutError = 'success';
        this.rutErrorTxt = '';
      }
    }

    if (status == 2) {
      if (cacheAddress && cacheAddress.country_code === 'KR') {
        if (!cacheAddress.passport_no) {
          this.CcError = 'error';
          this.CcErrorTxt = intl.getHTML(
            'order.edit_address.clearance_info_required'
          );
        } else {
          this.CcError = 'success';
          this.CcErrorTxt = '';
        }
        if (this.state.passportInputDisabled1 || cacheAddress.foreigner_passport_no) {
          this.CcError = 'success';
          this.CcErrorTxt = '';
        }
      }

      if (cacheAddress && cacheAddress.country_code === 'KR') {
        if (!cacheAddress.foreigner_passport_no) {
          this.AlienCcError = 'error';
          this.AlienCcErrorTxt = intl.getHTML(
            'order.edit_address.alien_clearance_info_required'
          );
        } else {
          this.AlienCcError = 'success';
          this.AlienCcErrorTxt = '';
        }
        if (this.state.passportInputDisabled2 || cacheAddress.passport_no) {
          this.AlienCcError = 'success';
          this.AlienCcErrorTxt = '';
        }
      }
    }

    // 只有失败单才检查错误
    if (status == 7) {
      const pattern = /^\d{7,8}-[0-9k|K]$/;
      const no = cacheAddress.rut_no;
      if(this.rule_Flag){
        if(!pattern.test(no)){
          this.rutError = 'error';
          this.rutErrorTxt = intl.get('order.cl_rut_errtip');
        }
      }


      // contact_name 必填 2-128 只限英文 errInfo.contact_name
      if( msg.includes( '6' ) || cacheAddress.full_name !== this.originAddress.full_name ) {
        if(true){
          // this.checkHanguo(cacheAddress);
          if (
              cacheAddress.contact_person?.trim().length < 2 ||
              cacheAddress.contact_person?.trim().length > 64
          ) {
              this.ContactNameError = 'error';
              this.ContactNameErrorTxt = intl.get(
                'order.edit_address.enter_between',
                { start: 2, end: 64 }
              );
            }
          }
        else {
          if (this.testAeRules('contactPerson')) {
            // AE规则验证通过 再验证自有规则
            if (cacheAddress.contact_person.length === 0) {
              this.ContactNameError = 'error';
              this.ContactNameErrorTxt = intl.get('order.edit_address.enter_name');
            } else if (
              cacheAddress.contact_person.length < 2 ||
              cacheAddress.contact_person.length > 128
            ) {
              this.ContactNameError = 'error';
              this.ContactNameErrorTxt = intl.get(
                'order.edit_address.enter_between',
                { start: 2, end: 128 }
              );
            } else if (
              !/^(?![\x00-\x7F]*(\x3C))[\x00-\x7F]*$/.test(
                cacheAddress.contact_person
              )
            ) {
              // if(cacheAddress.country_code !== 'JP'){
              //   this.ContactNameError = 'error';
              //   this.ContactNameErrorTxt = intl.get(
              //     'order.edit_address.english_only'
              //   );
              // }else{
              //   this.ContactNameError = '';
              //   this.ContactNameErrorTxt = '';
              // }
              
            } else if(cacheAddress.country_code === 'JP' && !/\S +\S/.test(cacheAddress.contact_person)) {
              this.ContactNameError = 'error';
              this.ContactNameErrorTxt = (
                <span>
                  Please fill in Japanese if you can and enter space between first
                  and family name.
                  {intl.getHTML('common.click_blog', {
                    url:
                      'https://help.dsers.com/orders-to-japan-specifications-for-shopify/'
                  })}
                </span>
              );
            } else if(cacheAddress.country_code === 'FR' && !/\S +\S/.test(cacheAddress.contact_person)){
              this.ContactNameError = 'error';
              this.ContactNameErrorTxt = (
                <span>
                  {intl.getHTML('common.click_blog2', {
                    url:
                      'https://help.dsers.com/orders-to-france-specifications-for-shopify/'
                  })}
                </span>
              );
            }else if(cacheAddress.country_code === 'KR'){
              
              this.checkKr(cacheAddress)
            }else {
              this.ContactNameError = 'success';
              this.ContactNameErrorTxt = '';
            }
          } else {
            // 发往日本的单子 联系人名字特殊提示
            if (
              cacheAddress.country === 'Japan' &&
              /fill[\s\S]*Japanese[\s\S]*enter[\s\S]*space/.test(
                this.ContactNameErrorTxt
              )
            ) {
              this.ContactNameErrorTxt = (
                <span>
                  Please fill in Japanese if you can and enter space between first
                  and family name.
                  {intl.getHTML('common.click_blog', {
                    url:
                      'https://help.dsers.com/orders-to-japan-specifications-for-shopify/'
                  })}
                </span>
              );
            }
            
            //发往加拿大的单子 如果Contact Name所填入的姓和名中间没有空格。AE则会拦截报错。
            if(cacheAddress.country === 'Canada' && /Please enter your full name as it appears on your ID/g.test(this.ContactNameErrorTxt)){
              this.ContactNameErrorTxt = (
                <span>
                  {intl.getHTML('order.conada_error')}
                </span>
              );
            }
            
            this.checkHanguo(address)
          }
          this.AddressError = '';
          this.AddressErrorTxt = '';
          this.Address2Error = '';
          this.Address2ErrorTxt = '';
        }
      }

      // company
      // if( this.originAddress.company != cacheAddress.company ){
      if (
        cacheAddress.company.length + cacheAddress.contact_person.length >
        128
      ) {
        this.CompanyError = 'error';
        this.CompanyErrorTxt = intl.get('order.edit_address.max128');
      } else if (
        (cacheAddress.company.length < 2 && cacheAddress.company.length !== 0) ||
        cacheAddress.company.length > 128
      ) {
        this.CompanyError = 'error';
        this.CompanyErrorTxt = intl.get('order.edit_address.enter_between', {
          start: 2,
          end: 128
        });
      } else if (
        !/^(?![\x00-\x7F]*(\x3C))[\x00-\x7F]*$/.test(cacheAddress.company)
      ) {
        this.CompanyError = 'error';
        this.CompanyErrorTxt = intl.get('order.edit_address.english_only');
      } else {
        this.CompanyError = 'success';
        this.CompanyErrorTxt = '';
      }
      // }

      // 电话tel 5-16 只限数字/连字符' - '/或正斜杠'/'
      if ( msg.includes( '7' ) || cacheAddress.phone_number !== this.originAddress.phone_number ) {

        if(cacheAddress.country === 'Korea'){
          const phoneNumber = cacheAddress.phone_number.replace(/[^\d]/, '');
          if(phoneNumber.length !== 0){

            if(/^\s*(1|01)\s*-*(?:\s*[0-9]\s*-*){9}\s*$/.test(phoneNumber)){
              this.TelError = '';
                this.TelErrorTxt = ''
            }else{
              this.TelError = 'error';
                this.TelErrorTxt = intl.get('order.edit_address.korea_error', {
                  start: 10,
                  end: 11
                }); 
            }

        
          }
        }else{
          if( cacheAddress.country === 'France' ){
            this.TelError = 'error';
            if( this.orderSetting.default_tel ){
              defaultTelErr = true;
              this.TelErrorTxt = intl.get('order.edit_address.france_phone_setting_tip');
            }else{
              this.TelErrorTxt = intl.get('order.edit_address.france_phone_setting_tip');
            }
  
            if( this.testFRPhoneNumber( cacheAddress.phone_number ) ){
              this.TelError = 'success';
              this.TelErrorTxt = '';
            }else if( !defaultTelErr ){
              this.TelError = 'error';
              this.TelErrorTxt = intl.get('order.edit_address.france_phone_setting_tip');
            }
          }
  
          if (!['Korea','France','Italy'].includes(cacheAddress.country) && (this.testAeRules('mobileNo'))) {
            const phoneNumber = cacheAddress.phone_number.replace(/[^\d]/, '');
            if (phoneNumber.length !== 0) {
              if (phoneNumber.length < 5 || phoneNumber.length > 16) {
                this.TelError = 'error';
              } else if (!/^[\d -/]*$/.test(cacheAddress.phone_number)) {
                this.TelError = 'error';
                this.TelErrorTxt = intl.get(
                  'order.edit_address.only_num_hyphens_forward'
                );
              } else {
                this.TelError = 'success';
                this.TelErrorTxt = '';
              }
            }
          }
  
      
          if(cacheAddress.country == 'Italy'){
            const phoneNumber = cacheAddress.phone_number.replace(/[^\d]/, '');
            if (phoneNumber.length !== 0) {
              if (phoneNumber.length < 9 || phoneNumber.length > 10) {
                this.TelError = 'error';
                this.TelErrorTxt = intl.get('order.edit_address.italy_error', {
                  start: 9,
                  end: 10
                });
              }else{
                this.TelError = '';
                this.TelErrorTxt = ''
              } 
            }
          }
        }

      }
      // if (cacheAddress.country_code === 'BR') {
      //   let num = cacheAddress.phone_number.replace(/[^\d]/g, '');
      //   if (num.indexOf('+55') === 0) {
      //     num = num.replace('+55', '');
      //   }
      //   if (num.length < 10 || num.length > 11) {
      //     this.TelError = 'error';
      //     this.TelErrorTxt = 'Please enter 10 or 11 digits';
      //   } else {
      //     this.TelError = 'success';
      //     this.TelErrorTxt = '';
      //   }
      // }

      // 空白手机号不报错
      // if (cacheAddress.phone_number === '') {
      //   this.TelError = 'success';
      //   this.TelErrorTxt = '';
      // }

      // cpf
      if (this.testAeRules('cpf')) {
        // 巴西订单失败，在其他的错误提示的基础上，增加一项模糊提示， CPF可能错误，请去AE确认
        if (cacheAddress.cpf.length !== 0) {
          if (cacheAddress.country === 'Brazil') {
            this.CpfError = 'error';
            this.CpfErrorTxt = intl.get('order.edit_address.cpf_tip');
          } else {
            this.CpfError = 'success';
            this.CpfErrorTxt = '';
          }
        }
      }

      // 清关信息
      if (cacheAddress && cacheAddress.country_code === 'KR') {
        if (!cacheAddress.passport_no) {
          this.CcError = 'error';
          this.CcErrorTxt = intl.getHTML(
            'order.edit_address.clearance_info_required'
          );
        } else if (!checkPassPortNo(cacheAddress.passport_no)) {
          this.CcError = 'error';
          this.CcErrorTxt = intl.getHTML('order.edit_address.clearance_info_tip');
        } else {
          this.CcError = 'success';
          this.CcErrorTxt = '';
        }
        if (this.state.passportInputDisabled1 || cacheAddress.foreigner_passport_no) {
          this.CcError = 'success';
          this.CcErrorTxt = '';
        }
      }

      if (cacheAddress && cacheAddress.country_code === 'KR') {
        if (!cacheAddress.foreigner_passport_no) {
          this.AlienCcError = 'error';
          this.AlienCcErrorTxt = intl.getHTML(
            'order.edit_address.alien_clearance_info_required'
          );
        } else if (!checkAlienPassPortNo(cacheAddress.foreigner_passport_no)) {
          this.AlienCcError = 'error';
          this.AlienCcErrorTxt = intl.getHTML('order.edit_address.alien_clearance_info_tip');
        } else {
          this.AlienCcError = 'success';
          this.AlienCcErrorTxt = '';
        }
        if (this.state.passportInputDisabled2 || cacheAddress.passport_no) {
          this.AlienCcError = 'success';
          this.AlienCcErrorTxt = '';
        }
      }

      // 国家 必填 errInfo.country
      if (msg.includes('3')) {
        if (this.testAeRules('country')) {
          if (cacheAddress.country.length === 0) {
            this.CountryError = 'error';
            this.CountryErrorTxt = intl.get('order.edit_address.enter_country');
          } else {
            this.CountryError = 'success';
            this.CountryErrorTxt = '';
          }
        }
      }

      // 地址1 必填 2-256 只限英文 errInfo.address
      if ( (msg.includes( '1' ) || cacheAddress.address !== this.originAddress.address) && !cacheAddress?.passport_no?.includes('P')) {
        if(cacheAddress.country_code != 'KR'){
        if (this.testAeRules('address')) {
          if (cacheAddress.address.length === 0) {
            this.AddressError = 'error';
            this.AddressErrorTxt = intl.get('order.edit_address.enter_address');
          } else if (cacheAddress.address.length > 256) {
            this.AddressError = 'error';
            this.AddressErrorTxt = intl.get('order.edit_address.enter_between', {
              start: 2,
              end: 256
            });
          // 不再只限制英文
          // } else if (!/^[\x00-\x7F]*$/.test(cacheAddress.address)) {
          //   this.AddressError = 'error';
          //   this.AddressErrorTxt = intl.get('order.edit_address.english_only');
          } else if (cacheAddress.address.replace(/\s+/g,"")?.length < 5) {
            this.AddressError = 'error';
            this.AddressErrorTxt = intl.get('order.edit_address.enter_address_length');
          }
          else {
            this.AddressError = 'success';
            this.AddressErrorTxt = '';
          }
        }
        }
      }

      // 地址2 2-256 只限英文 errInfo.address_2
      if ( (msg.includes( '2' ) || cacheAddress.address2 !== this.originAddress.address2) && !cacheAddress?.passport_no?.includes('P')) {
        if(cacheAddress.country_code != 'KR'){
        if (this.testAeRules('address2')) {
          if (cacheAddress.address.length !== 0) {
            if (cacheAddress.address2.length > 256) {
              this.Address2Error = 'error';
              this.Address2ErrorTxt = intl.get(
                'order.edit_address.enter_between',
                { start: 2, end: 256 }
              );
            // 不再只限制英文
            // } else if (!/^[\x00-\x7F]*$/.test(cacheAddress.address2)) {
            //   this.Address2Error = 'error';
            //   this.Address2ErrorTxt = intl.get('order.edit_address.english_only');
            } else {
              this.Address2Error = 'success';
              this.Address2ErrorTxt = '';
            }
          }
        }
        }
      }

      if ((cacheAddress.address2.length + cacheAddress.address.length) > 256) {
        this.AddressError = 'error';
        this.AddressErrorTxt = intl.get('order.edit_address.enter_between', {
          start: 1,
          end: 256
        });
      }

      if (
        msg.includes('4') ||
        cacheAddress.province !== this.originAddress.province
      ) {
        if (this.testAeRules('province')) {
          if (this.provinceList.length === 0) {
            if (this.hasFetchCityList) {
              // if (cacheAddress.province.length == 0) {
              //   this.ProvinceError = "error";
              //   this.ProvinceErrorTxt = "Please select a State/Province/Region";
              // } else
              if (
                cacheAddress.province.length < 2 ||
                cacheAddress.province.length > 64
              ) {
                this.ProvinceError = 'error';
                this.ProvinceErrorTxt = intl.get(
                  'order.edit_address.enter_between',
                  { start: 2, end: 64 }
                );
              } else {
                this.ProvinceError = 'success';
                this.ProvinceErrorTxt = '';
              }
              // else if (!/^[\x00-\x7F]*$/.test(cacheAddress.province)) {
              //   this.ProvinceError = "error";
              //   this.ProvinceErrorTxt = "Please enter English only";
              // }
            } 
          } else {
            let flag = false;
            this.provinceList.forEach(item => {
              if (item.Name === cacheAddress.province) {
                flag = true;
              }
            });

            if (flag) {
              this.ProvinceError = 'success';
              this.ProvinceErrorTxt = '';
            } 
          }
        }
      }
      if (msg.includes('5') || cacheAddress.city !== this.originAddress.city) {
       if (this.testAeRules('city')) {
         if (this.cityList.length === 0) {
           setTimeout(() => { //加一个延迟 防止过多的请求
            this.checkCity().then(() => {
              if (cacheAddress.city.length === 0) {
                this.CityError = 'error';
                this.CityErrorTxt = intl.get('order.edit_address.enter_city');
              } else if (
                cacheAddress.city.length < 2 ||
                cacheAddress.city.length > 64
              ) {
                this.CityError = 'error';
                this.CityErrorTxt = intl.get(
                  'order.edit_address.enter_between',
                  { range: '2 - 64' }
                );
              } else {
                this.CityError = 'success';
                this.CityErrorTxt = '';
              }
              // else if ( !/^[\x00-\x7F]*$/.test(cacheAddress.city) ) {
              //   this.CityError = "error";
              //   this.CityErrorTxt = "Please enter English only";
              // }
            }).catch(() => {
              this.CityError = 'error';
              this.CityErrorTxt = intl.get('order.edit_address.city_not_match');
            })
          },800)

          } else {
            let flag = false;
            this.cityList.forEach(item => {
              if (item.Name == cacheAddress.city) {
                flag = true;
              }
            });

            if (flag) {
              this.CityError = 'success';
              this.CityErrorTxt = '';
            } else {
              // 判断是否需要报错
              if(cacheAddress.country == 'Mexico'){
                let MexicoList =  [];
                this.provinceList.map(item => item.Name == cacheAddress.province && MexicoList.push(...item.Citys))
                let flag2 = false;
                MexicoList.map(item=> {
                  if(item.Name == cacheAddress.city){
                    flag2 = true
                  }
                })
                if(!flag2){
                  this.CityError = 'error';
                  this.CityErrorTxt = intl.get('order.edit_address.city_not_match');
                }else{
                  this.CityError = 'success';
                  this.CityErrorTxt = '';
                }
              }
              
              let showErr = this.cityList.find(item => item.Dialect == address.city) || {}
              if(showErr.hasOwnProperty('Dialect')){
                this.CityError = 'success';
                this.CityErrorTxt = '';
              }
            }
          }
        }
      }

      if ( msg.includes( '8' ) || cacheAddress.zip !== this.originAddress.zip ) {
        if (this.testAeRules('zip')) {
          if (
            cacheAddress.country === 'Austria' ||
            cacheAddress.country === 'Belgium' ||
            cacheAddress.country === 'Denmark' ||
            cacheAddress.country === 'Hungary' ||
            cacheAddress.country === 'Luxembourg' ||
            cacheAddress.country === 'Norway' ||
            cacheAddress.country === 'Slovenia'
          ) {
            // 特殊国家邮编 4位
            this.PostCodeMaxLength = '4';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (!/^([0-9]{4})$/.test(cacheAddress.zip)) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_1234',
                { length: 4, ps: 1234 }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (
            cacheAddress.country === 'Korea' ||
            cacheAddress.country === 'Spain' ||
            cacheAddress.country === 'Ukraine' ||
            cacheAddress.country === 'United States' ||
            cacheAddress.country === 'Czech Republic' ||
            cacheAddress.country === 'France' ||
            cacheAddress.country === 'Germany' ||
            cacheAddress.country === 'Italy' ||
            cacheAddress.country === 'Mexico' ||
            cacheAddress.country === 'Poland' ||
            cacheAddress.country === 'Slovakia (Slovak Republic)'
          ) {
            // 特殊国家邮编 5位
            this.PostCodeMaxLength = '5';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (!/^([0-9]{5})$/.test(cacheAddress.zip)) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_1234',
                { length: 5, ps: 12345 }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (
            cacheAddress.country === 'Russian Federation' ||
            cacheAddress.country === 'Singapore' ||
            cacheAddress.country === 'Russia'
          ) {
            // 特殊国家邮编 6位
            this.PostCodeMaxLength = '6';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (!/^([0-9]{6})$/.test(cacheAddress.zip)) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_123456',
                { ps: 123456 }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (
            cacheAddress.country === 'Chile' ||
            cacheAddress.country === 'Israel'
          ) {
            // 特殊国家邮编 7位
            this.PostCodeMaxLength = '7';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (!/^([0-9]{7})$/.test(cacheAddress.zip)) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_1234',
                { length: 7, ps: 1234567 }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (cacheAddress.country === 'Belarus') {
            // 特殊国家邮编 6位 2开头 纯数字
            this.PostCodeMaxLength = '6';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (!/^(2[0-9]{5})$/.test(cacheAddress.zip)) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_123456',
                { ps: 234567 }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (cacheAddress.country === 'Brazil') {
            // 特殊国家邮编 5位数字：12345 8位数字：12345000 9位数字+‘-’且必须在倒数第四位12345-000  无空格
            this.PostCodeMaxLength = '9';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (
              !/^((0[1-9][0-9]{3})|([1-9][0-9]{4})|(0[1-9][0-9]{3}(-)?[0-9]{3})|([1-9][0-9]{4}(-)?[0-9]{3}))$/.test(
                cacheAddress.zip
              )
            ) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_special',
                { ps: '13165-000' }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (cacheAddress.country === 'Canada') {
            // 特殊国家邮编 6位 英文数字间隔，第一位英文A1B2C3 3+3位，中间空格A1B 2C3 英文必须大写
            this.PostCodeMaxLength = '7';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (
              !/^([A-Z][0-9][A-Z][0-9][A-Z][0-9]|[A-Z][0-9][A-Z][\s][0-9][A-Z][0-9])$/.test(
                cacheAddress.zip
              )
            ) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_1234',
                { length: 6, ps: 'A1B2C3' }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (cacheAddress.country === 'Netherlands') {
            // 特殊国家邮编 6位  4数字+2字母 7位 4数字+空格+2字母 前四个不能有英文
            this.PostCodeMaxLength = '7';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (
              !/^(([0-9]{4}[\s][A-Za-z]{2})|([0-9]{4}[A-Za-z]{2}))$/.test(
                cacheAddress.zip
              )
            ) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_special',
                { ps: '1234AB' }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (cacheAddress.country === 'Portugal') {
            // 特殊国家邮编 7位数字 4数字+-+3数字 无英文
            this.PostCodeMaxLength = '8';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (
              !/^(([0-9]{7})|([0-9]{4}-[0-9]{3}))$/.test(cacheAddress.zip)
            ) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_confirm'
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (cacheAddress.country === 'Saudi Arabia') {
            // 特殊国家邮编 5位数字+-+四位数字 无英文
            this.PostCodeMaxLength = '10';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (
              !/^(([0-9]{5}-[0-9]{4})|([0-9]{5})|([0-9]{7}))$/.test(
                cacheAddress.zip
              )
            ) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_special',
                { ps: '12345-1234' }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (cacheAddress.country === 'United Kingdom') {
            // 特殊国家邮编 4+3:1234 111 3+3:123 111 2+3:12 111
            this.PostCodeMaxLength = '8';
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (
              !/^([0-9A-Za-z]{2,4}[\s][0-9A-Za-z]{3})$/.test(cacheAddress.zip)
            ) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_special',
                { ps: 'WC1B 3DG' }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else if (cacheAddress.country === 'Australia') {
            this.PostCodeMaxLength = '4';
            // 邮编 必填 1-16 只限拉丁字母/数字/空格/连字符 errInfo.zip
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (!/^([0-9]{4})$/.test(cacheAddress.zip)) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_zip_1234',
                { length: 4, ps: '1234' }
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          } else {
            this.PostCodeMaxLength = '16';
            // 邮编 必填 1-16 只限拉丁字母/数字/空格/连字符 errInfo.zip
            if (cacheAddress.zip.length === 0) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get('order.edit_address.enter_zip');
            } else if (cacheAddress.zip.length > 16) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.enter_between',
                { start: 1, end: 16 }
              );
            } else if (!/^[A-Z\d-\s]*$/.test(cacheAddress.zip)) {
              this.PostCodeError = 'error';
              this.PostCodeErrorTxt = intl.get(
                'order.edit_address.only_num_spaces_hyphens'
              );
            } else {
              this.PostCodeError = 'success';
              this.PostCodeErrorTxt = '';
            }
          }
        }
      }
    }
    // }

    const proviceTool =
      this.provinceList.length === 0 &&
      !this.provinceList[this.cacheAddress.country_code] ? (
        <TextField
          label={intl.get('order.edit_address.province')}
          variant="outlined"
          disabled={inputDisabled}
          maxLength={64}
          onFocus={this.getSelectData}
          onChange={e => {
            this.changeProvince(e.target.value);
            this.props.changeSave()
          }}
        />
      ) : (
        <Select
          disabled={inputDisabled}
          loading={selectLoading}
          showSearch
          onChange={e => {
            this.changeProvince(e);
            this.props.changeSave()
          }}
          dropdownMatchSelectWidth={false}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        >
          {this.provinceList.map(item => {
            return (
              <Option value={`${item.Name}${item?.Dialect ? `(${item?.Dialect})` : ''} `} key={item.Name}>
                {/* 城市列表 */}
                {item.Name}{ item?.Dialect ? `(${item?.Dialect})` : '' }
              </Option>
            );
          })}
        </Select>
      );

    const cityTool =
      this.cityList.length === 0 &&
      !this.provinceList[this.cacheAddress.country_code] ? (
        <TextField
          label={intl.get('order.edit_address.city')}
          variant="outlined"
          disabled={inputDisabled}
          maxLength={64}
          onChange={e => {
            this.changeAll('city', e.target.value);
            this.props.changeSave()
          }}
          onFocus={this.getSelectData}
        />
      ) : (
        <Select
          disabled={inputDisabled}
          loading={selectLoading}
          showSearch
          onChange={v => {
            this.changeAll('city', v);
            this.props.changeSave()
          }}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        >
          {this.cityList.map(item => {
            return (
              <Option value={`${item.Name}${item?.Dialect ? `(${item?.Dialect})` : ''}`} key={item.Name}>
                {item.Name} { item?.Dialect ? `(${item?.Dialect})` : '' }
              </Option>
            );
          })}
        </Select>
      );
    
    // 产品要求去掉所有手机号校验规则 2023/08/09
    this.TelError = '';
    this.TelErrorTxt = '';

    // 产品又要把省份城市校验去掉（2023/08/09口头说明）
    this.CityError = '';
    this.CityErrorTxt = '';
    this.ProvinceError = '';
    this.ProvinceErrorTxt = '';

    return (
      <>
      <Spin spinning={this.state.newRulesLoading}>
        <div className={orderProductsStyles.tableForm}>
          {listData.showCustomerTip ? (
            <p className={orderProductsStyles.productTip}>
              <img src={orderErrorTipIco} />{' '}
              {intl.get('order.edit_address.unknow_wrong')}
            </p>
          ) : null}
          <Row>
            <Form id="editFromLayout" layout="horizontal">
              <Col >
                <Form.Item
                  {...formItemLayout}
                  validateStatus={this.ContactNameError}
                  help={this.ContactNameErrorTxt}
                >
                  {getFieldDecorator(`contact_person_${listData.id}`, {
                    initialValue: address.contact_person || ''
                  })(
                    <TextField
                      label={intl.get('order.edit_address.contact_name')}
                      variant="outlined"
                      disabled={inputDisabled}
                      maxLength={128}
                      placeholder=""
                      onChange={e => {
                        this.changeAll('contact_person', e.target.value);
                        this.props.changeSave()
                      }}
                      />
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  validateStatus={this.TelError}
                  help={this.TelErrorTxt}
                >
                  {getFieldDecorator(`phone_number_${listData.id}`, {
                    initialValue: address.phone_number
                  })(
                    <TextField
                      label={intl.get('order.edit_address.tel')}
                      variant="outlined"
                      disabled={inputDisabled}
                      maxLength={16}
                      onChange={e => {
                        this.changeAll('phone_number', e.target.value);
                        this.props.changeSave()
                      }}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                >
                  {getFieldDecorator(`email_${listData.id}`, {
                    initialValue: address.email
                  })(
                    <TextField
                      label={intl.get('order.edit_address.email')}
                      variant="outlined"
                      disabled={inputDisabled}
                      onChange={e => {
                        this.changeAll('email', e.target.value);
                        this.props.changeSave()
                      }}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  validateStatus={this.CompanyError}
                  help={this.CompanyErrorTxt}
                >
                  {getFieldDecorator(`company_${listData.id}`, {
                    initialValue: address.company
                  })(
                    <TextField
                      label={intl.get('order.edit_address.company')}
                      variant="outlined"
                      disabled={inputDisabled}
                      maxLength={128}
                      onChange={e => {
                        this.changeAll('company', e.target.value);
                        this.props.changeSave()
                      }}
                    />
                  )}
                </Form.Item>

                {cacheAddress.country_code === 'KR' ? (
                  <Form.Item
                    {...formItemLayout}
                    validateStatus={this.CcError}
                    help={this.CcErrorTxt}
                  >
                    {getFieldDecorator(`passport_no_${listData.id}`, {
                      initialValue: address.passport_no
                    })(
                      <TextField
                        label={intl.get('order.edit_address.clearance_info')}
                        variant="outlined"
                        disabled={
                          this.state.passportInputDisabled1 ||
                          address.foreigner_passport_no ||
                          inputDisabled
                        }
                        onChange={e => {
                          this.changeAll('passport_no', e.target.value);
                        }}
                      />
                    )}
                  </Form.Item>
                ) : null}
                {/* 可能有问题 */}
                {/*

                  label={
                      // <span className={orderProductsStyles.text}>{intl.getHTML('order.edit_address.alien_clearance_info')}</span>
                      <span className={orderProductsStyles.text}>{intl.getHTML('order.edit_address.alien_clearance_info')}</span>
                    }

                */}
                {cacheAddress.country_code === 'KR' ? (
                  <Form.Item
                    {...formItemLayout}

                    className={`${orderProductsStyles.alien} ${localStorage.getItem('lang') == 'fr-FR' ? orderProductsStyles.fr:''}`}
                    validateStatus={this.AlienCcError}
                    help={this.AlienCcErrorTxt}
                  >
                    {getFieldDecorator(`foreigner_passport_no_${listData.id}`, {
                      initialValue: address.foreigner_passport_no
                    })(
                      <TextField
                        label={intl.get('order.edit_address.alien_clearance_info')}
                        variant="outlined"
                        disabled={
                          this.state.passportInputDisabled2 ||
                          address.passport_no ||
                          inputDisabled
                        }
                        onChange={e => {
                          this.changeAll('foreigner_passport_no', e.target.value);
                        }}
                      />
                    )}
                  </Form.Item>
                ) : null}

                {cacheAddress.country_code === 'CL' ? (
                  <Form.Item
                    {...formItemLayout}
                    validateStatus={this.rutError}
                    help={this.rutErrorTxt}
                    extra={this.rutErrTip}
                  >
                    {getFieldDecorator(`rut_no_${listData.id}`, {
                      initialValue: address.rut_no
                    })(
                      <TextField
                        label="RUT"
                        variant="outlined"
                        disabled={inputDisabled}
                        onChange={e => {
                          this.changeAll('rut_no', e.target.value);
                        }}
                      />
                    )}
                  </Form.Item>
                ) : null}

                {cacheAddress.country_code === 'BR' ? (
                  <Form.Item
                    {...formItemLayout}
                    validateStatus={this.CpfError}
                    help={this.CpfErrorTxt}
                  >
                    {getFieldDecorator(`cpf_${listData.id}`, {
                      initialValue: address.cpf
                    })(
                      <TextField
                        label={intl.get('order.edit_address.cpf')}
                        variant="outlined"
                        disabled={inputDisabled}
                        maxLength={11}
                        onChange={e => {
                          this.changeAll('cpf', e.target.value);
                        }}
                      />
                    )}
                  </Form.Item>
                ) : null}
              </Col>
              <Col style={{position:'relative'}}>
                {address.country_code ?
                  <div className={orderProductsStyles.contryCQ}>{intl.get('order.edit_address.country')}</div>: null
                }
                
                <Form.Item
                  {...formItemLayout}
                  validateStatus={this.CountryError}
                  help={this.CountryErrorTxt}
                >
                  {getFieldDecorator(`country_code_${listData.id}`, {
                    initialValue: address.country_code
                  })(
                    <Select
                      disabled={inputDisabled}
                      showSearch
                      onChange={(v,e) => {
                        this.changeCountry(v, e);
                        this.props.changeSave()
                      }}
                      optionFilterProp="children"
                      className={!address.country_code ? 'stopSelect' : null}
                      onClick={this.clickCountry}
                      placeholder={intl.get('order.edit_address.country')}
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      dropdownStyle={{
                        width:500
                      }}
                    >
                      {this.countryLists.map(item => {
                        return (
                          <Option value={item.Code} key={item.Code}>
                            {item.Name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  validateStatus={this.AddressError}
                  help={this.AddressErrorTxt}
                >
                  {getFieldDecorator(`address_${listData.id}`, {
                    initialValue: address.address
                  })(
                    <TextField
                      label={intl.get('order.edit_address.address')}
                      variant="outlined"
                      disabled={inputDisabled}
                      maxLength={256}
                      onChange={e => {
                        this.changeAll('address', e.target.value);
                        this.props.changeSave()
                      }}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  validateStatus={this.Address2Error}
                  help={this.Address2ErrorTxt}
                >
                  {getFieldDecorator(`address2_${listData.id}`, {
                    initialValue: address.address2
                  })(
                    <TextField
                      label=" "
                      variant="outlined"
                      disabled={inputDisabled}
                      maxLength={256}
                      onChange={e => {
                        this.changeAll('address2', e.target.value);
                        this.props.changeSave()
                      }}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}

                  validateStatus={this.ProvinceError}
                  help={this.ProvinceErrorTxt}
                >
                  {getFieldDecorator(`province_${listData.id}`, {
                    initialValue: address.province
                  })(proviceTool)}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  validateStatus={this.CityError}
                  help={this.CityErrorTxt}
                >
                  {getFieldDecorator(`city_${listData.id}`, {
                    initialValue: address.city
                  })(cityTool)}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  validateStatus={this.PostCodeError}
                  help={this.PostCodeErrorTxt}
                >
                  {getFieldDecorator(`zip_${listData.id}`, {
                    initialValue: address.zip || ''
                  })(
                    <TextField
                      label={intl.get('order.edit_address.post_code')}
                      variant="outlined"
                      disabled={inputDisabled}
                      maxLength={this.PostCodeMaxLength ? this.PostCodeMaxLength * 1 : undefined}
                      onChange={e => {
                        this.changeAll('zip', e.target.value);
                        this.props.changeSave()
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Form>
          </Row>
        </div>
        </Spin>
      </>
    );
  }
}
