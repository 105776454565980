import React from 'react';
import { connect } from 'dva';
import { Popover, Icon, Radio, Alert, message } from 'antd';

import intl from 'react-intl-universal';
import className from 'classnames';
import round from 'lodash/round';

import Ellipsis from 'components/Ellipsis';
import SelectShipp from './SelectShipp.js';
import { SearchInput } from 'features'

import { getAESkuMap, getAESkuWithoutDefineName, getAliExpressUrl, replaceUrlToHttps } from '../../../utils/utils.js';
import { currencySymbolMap } from '../../../common/sysconfig';

import defaultImg from '../../../assets/default-image.jpg';
import { OrderAction } from 'actions';
import orderStylesBack from 'features/order/ActionEdit/ActionEdit.less';
import orderStyles from '../Order.less';

import '../Order.css';

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

@connect(({ order, global, loading }) => ({
  order,
  storeCurrency: global.storeCurrency,
  loading: loading.models.order,
  okLoading: loading.effects['order/express']
}))
export default class Bind extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bindImg: '',
      showSelectFreight: false,
      selectFreight: undefined,
      price: 0,
      selectSku: [],
      publicBtnDispath: false,
      showBindOption:true,
      bindUrl:'',
      select_Holder:intl.get('order.bind_product.ae_url_tip')
    };
  }

  componentDidMount() {
    const {
      listData: {
        detail: { line_items }
      },
      index
    } = this.props;
    window.EVENT.on(OrderAction.UPDATA_SHIPPING, async res => {
          this.getFreight()
    })
    console.log('我的sku',this.props)

    this.updataObj.title = line_items[index].title || '';
  }

  bindUrl = '';

  bindData = null;

  bindOptions = [];

  freights = [];

  selectSkuName = null;

  selectFreightObj = {};

  platform = 'AliExpress'

  updataObj = {
    cost: '0',
    freight_cost: 0,
    logistics_company: '',
    platform_product_id: '',
    platform_sku: '',
    sale_id: '',
    sku_img: '',
    title: '',
    variant_title: '',
    freights: null,
    is_edit: true
  };

  aeop_ae_product_sku = []

  skuMap = {};
  // 粘贴换绑商品url
  changeBindUrl = e => {
    this.bindUrl = e.target.value;
    this.setState({
      bindUrl:e.target.value
    })
  };

  // 获取换绑商品详情
  getBindProduct = () => {
    if (!this.state.bindUrl) {
      message.error('Please input valid AliExpress product URL.')
      return;
    }

    this.props.dispatch({
      type: 'order/bind',
      payload: {
        data: {
          url: this.state.bindUrl
        },
        callback: d => {
          if (d.code == 2000) {
            this.setState({
              publicBtnDispath: false
            });
            const pImg = GetProductDefaultImage(d.data);

            this.selectSkuName = [];
            this.bindData = d.data;
            this.bindOptions = GetProductSkuOptionFromDetail(d.data);

            console.log('this bindOptions',this.bindOptions)

            this.updataObj.sale_id = d.data.owner_member_id;
            this.updataObj.sku_img = pImg;
            this.updataObj.platform_product_id = `${d.data.product_id}`;
            
            //暂时写死 后期会加参数
            this.updataObj.supply_type = d.data.supplier_type || 2;
            this.platformOrigin = 'Aliexpress'

            this.skuMap = getAESkuMap(
              d.data?.aeop_ae_product_s_k_us?.aeop_ae_product_sku
            );

            if (this.bindOptions.length === 0) {
              this.updataObj.platform_sku = '<none>';
            }

            this.updataBind();
            this.setNoneSku(d.data, this.bindOptions);
            this.setState({
              bindImg: pImg || defaultImg,
              selectSku: []
            });
          } else if (d.code === 4290) {
            this.setState({
              publicBtnDispath: true
            });
          }
        }
      }
    });
  };

  //获取天猫换绑商品详情
  getTmallBindProduct = () => {
    if (!this.state.bindUrl) {
      message.error('The URL you entered is not a Tmall URL, please check and re-enter')
      return;
    }

    let id = '';
    var reg = /.*\/app\/tmallproduct\/(\d+)?/;

    if(reg.test(this.state.bindUrl)){
      id = RegExp.$1
    }

    if(!id){
      message.error('The URL you entered is not a Tmall URL, please check and re-enter')
      return
    }
    
    this.props.dispatch({
      type: 'order/tmallbind',
      payload: {
        data: {
          product_id: id
        },
        callback: d => {
          if (d.code == 2000) {
            this.setState({
              publicBtnDispath: false
            });
            const pImg = GetProductDefaultImage(d.data);

            this.selectSkuName = [];
            this.bindData = d.data;
            this.bindOptions = GetProductSkuOptionFromDetail(d.data);

            console.log('this bindOptions',this.bindOptions)

            this.updataObj.sale_id = d.data.owner_member_id;
            this.updataObj.sku_img = pImg;
            this.updataObj.platform_product_id = `${d.data.product_id_str}`;
            
            //暂时写死 后期会加参数
            this.updataObj.supply_type = d.data.supplier_type || 4;
            this.platformOrigin = 'Tmall'
            this.aeop_ae_product_sku = d.data?.aeop_ae_product_s_k_us?.aeop_ae_product_sku

            this.skuMap = getAESkuMap(
              d.data?.aeop_ae_product_s_k_us?.aeop_ae_product_sku
            );

            if (this.bindOptions.length === 0) {
              this.updataObj.platform_sku = '<none>';
            }

            this.updataBind();
            this.setNoneSku(d.data, this.bindOptions);
            this.setState({
              bindImg: pImg || defaultImg,
              selectSku: []
            });
          } else if (d.code === 4290) {
            this.setState({
              publicBtnDispath: true
            });
          }
        }
      }
    });
  };

  getDetailList = () => {
    if(this.platform == 'AliExpress'){
      this.getBindProduct()
    }else{
      this.getTmallBindProduct()
    }
  }

  // 商品没有sku
  setNoneSku = (prod, options) => {
    if (options.length) {
      return;
    }

    const { item_offer_site_sale_price, product_price } = prod;
    const price = Number(item_offer_site_sale_price || product_price) || 0;
    this.updataObj.cost = `${price}`;
    // this.updataObj
   this.getFreight();
    this.setState({
      price
    });
  };

  // 更改sku
  changeSku = (e, index) => {
    const { selectSku } = this.state;
    const arr = [...selectSku];

    

    arr[index] = e.target.value;
    this.selectSkuName[index] = e.target.data_v;
    if (e.target.data_img) {
      this.setState({
        bindImg: e.target.data_img
      });
      this.updataObj.sku_img = e.target.data_img;
    }

    this.setState(
      {
        selectSku: arr
      },
      () => {
        this.updataBind();
        this.getFreight();
      }
    );
  };

  // 获取商品物流
  getFreight = () => {
    const { selectSku } = this.state;
    if (
      selectSku.length !== this.bindOptions.length ||
      selectSku.includes(undefined)
    ) {
      return;
    }
    
    if (this.bindOptions.length !== 0) {
      const joinedSku = selectSku.join(';');
      const skuId =
        this.skuMap[getAESkuWithoutDefineName(joinedSku)]?.id || joinedSku;
      const price = GetSkuPrice(this.bindData, skuId);
      const skuId_add = 
      this.skuMap[getAESkuWithoutDefineName(joinedSku)]?.sku_id || ""; 
      this.updataObj.cost = `${price}`;
      this.updataObj.platform_sku = skuId;
      if (skuId_add) {
        this.updataObj.platform_sku_id = skuId_add;
      }
      this.updataObj.variant_title = this.selectSkuName.join(' / ');
      this.updataBind();

      this.setState({
        price
      });
    }

    console.log('selectSku',selectSku)

    const {
      listData: {
        ext: { address = {} }
      },
      child
    } = this.props;

    let skuId;
    
    if (this.bindOptions.length !== 0) {
        const joinedSku = selectSku.join(';');
        skuId = this.skuMap[getAESkuWithoutDefineName(joinedSku)]?.id || joinedSku;
    }else{
      skuId = '<none>' 
    }

    let params = {
      prod_id: this.bindData.product_id,
      sku: skuId,
      country_code: address.country_code || '',
      quantity: child.qty,
      province: address.province || '',
      city: address.city || '',
      supply_type:2,
      supplier_type:2
    }

    if(this.platform == 'Tmall'){
      if(this.aeop_ae_product_sku.length != 0){
          let skuObj = this.aeop_ae_product_sku.find(skuItem => skuItem.id === skuId)
          Object.assign(params, {sku_id:skuObj.sku_id, supplier_type:4,prod_id:this.bindData.product_id_str})
      }
    }

    this.props.dispatch({
      type: 'order/freight',
      payload: {
        data: params,
        cancelMessage: true,
        callback: d => {
          if (d.code === 2000) {
            this.freights = d.data.freight;
            this.updataObj.freights = d.data.freight;
          } else if (d.code === 4000 && d.msg.includes('CountryCode')) {
            message.error(intl.get('order.order_product.ae_order_error_tip.freight_error'));
          }
        }
      }
    });
  };

  getBindToolError = () => {
    return null;
  };

  toggleFreightList = () => {
    const { selectSku } = this.state;
    if (selectSku.length !== this.bindOptions.length) {
       this.setState({
        showBindOption:false,
        showSelectFreight: !this.state.showSelectFreight
      })
    }else{
      this.setState({
        showBindOption:true,
        showSelectFreight: !this.state.showSelectFreight
      })
    }
  };

  onOk = v => {
    this.selectFreightObj = getFreightCompayName(this.freights, v);
    this.toggleFreightList();
    this.setState({
      selectFreight: v
    });

    const {
      freightAmount = this.selectFreightObj.standardFreightAmount
    } = this.selectFreightObj;

    this.updataObj.logistics_company = this.selectFreightObj.serviceName;
    this.updataObj.freight_cost = `${freightAmount.value || 0}`; // free shipping value 是没有 给后台穿啥
    this.updataBind();
  };

  updataBind = () => {
    const {
      listData: { id },
      child,
      index,
      sonIndex
    } = this.props;

    this.props.updataExt({
      type: 'bind',
      oid: id,
      lineItemId: child.id,
      bind: this.updataObj,
      sonIndex,
      index,
      isEditSave:true
    });
  };

  render() {
    const {
      child = {},
      okLoading,
      storeCurrency,
      listData: { storeId, ext: { address = {} } = {} }
    } = this.props;
    
    const {
      bindImg,
      showSelectFreight,
      selectFreight,
      price,
      selectSku,
      publicBtnDispath
    } = this.state;
    
    const {
      freightAmount = this.selectFreightObj.standardFreightAmount,
      company
    } = this.selectFreightObj;

    let currencyCode;
    let currencySymbol;
    let isNotUSD;
    let currencyRates;
    let isShowTmallOption = !!window.TMALL_USER;

    //判断是不是显示edit tmall
    // if(child.supplierMapType == 5){
    //   isShowTmallOption = true;
    // }else if(child.supplierMapType == 1){
    //   //判断是不是主副供应商
    //   // if(child.sp.length == 1){
    //   //   isShowTmallOption = true;
    //   // }
    //   // if(child.sp.length >= 2){
    //   //   if(child.sp[0].is_default && !child.sp[1].is_default){
    //   //     isShowTmallOption = false;
    //   //   }
    //   // }
    //   isShowTmallOption = true;
    // }else{
    //   isShowTmallOption = false;
    // }

    if (storeCurrency[storeId]) {
      currencyCode = storeCurrency[storeId].currency_code || 'USD';
      currencySymbol = currencySymbolMap[currencyCode];
      isNotUSD = currencyCode !== 'USD';
      currencyRates = storeCurrency[storeId].rates_value;
    }

    const countryCode = address.country_code;

    return (
      <div className={orderStyles.bindProduct}>
        {this.getBindToolError(child)}
        {publicBtnDispath ? (
          <Alert
            message={intl.get('order.bind_product.overrun_warning')}
            type="warning"
            showIcon
          />
        ) : null}

        {child.isDserProduct === undefined ? (
          <div className={orderStyles.bindAliUrl}>
            {
              window.TMALL_USER && isShowTmallOption? <>
                 <SearchInput
                  onSubmit={this.getDetailList}  
                  defaultValue={child.bindUrl}
                  value={this.state.bindUrl}
                  allowClear
                  onChange={(e) => { this.setState({ bindUrl: e?.target?.value || '' }) }}
                  changeSearchKey={this.changeBindUrl}
                  selfSearchIcon={intl.get('public.ok')}
                  productValue ={this.state.bindUrl}
                  onlySearch={true}
                  placeholder={this.state.select_Holder}
                  hiddenSelect={false}
                  getPlatform={type => { 
                    this.platform = type; 
                    this.setState({
                      select_Holder:type == 'Tmall' ? intl.get('order.bind_product.tmall_url_tip') : intl.get('order.bind_product.ae_url_tip')
                    })
                  }}
                  isShowTmallOption={isShowTmallOption}
                />
              </> : <>
                <div>{intl.get('order.bind_product.ae_url')}:</div>
                  <input defaultValue={child.bindUrl} onChange={this.changeBindUrl} />
                  <div className={orderStyles.divBindAliUrlBtn}>
                    <button
                      className={orderStyles.customBtn}
                      onClick={this.getBindProduct}
                    >
                      {intl.get('public.ok')}
                    </button>
                  </div>
              </> 
            }
          </div>
        ) : null}
        {this.bindData ? (
          <>
            <div className={orderStyles.bindProductInfo}>
              <Popover
                placement="leftTop"
                trigger="hover"
                content={
                  <img
                    src={replaceUrlToHttps(bindImg)}
                    style={{
                      width: '260px',
                      height: '260px',
                      objectFit: 'contain'
                    }}
                  />
                }
                overlayClassName={orderStyles.popover}
              >
                <a className={orderStyles.bindProductImg}>
                  <img src={replaceUrlToHttps(bindImg)} />
                </a>
              </Popover>

              <div className={orderStyles.productTitle}>
                <Ellipsis lines={2}>
                  <p className={orderStyles.productTitles}>
                    {
                        this.bindData.supplier_type  == 2 ? <a
                        href={getAliExpressUrl(this.bindData.product_id)}
                        target="_blank"
                      >
                        {this.bindData.subject}
                      </a> : <a
                        href={`/app/tmallproduct/${this.bindData.product_id_str}?appId=${this.bindData.app_id}`}
                        target="_blank"
                      >
                        {this.bindData.subject}
                      </a>
                    }
                    
                  </p>
                </Ellipsis>
                <p style={{ marginTop: 32, fontSize: 12 }}>
                  {this.platformOrigin == 'Tmall' ? 'From: Tmall' : intl.get('order.bind_product.from_aliexpress')}
                  
                </p>
              </div>
              <div className={orderStyles.productCost}>
                <div className={orderStyles.productShippingMethod}>
                  <div className={orderStyles.productShippingTool}>
                    <div
                      className="ali_div"
                      onClick={this.toggleFreightList}
                      type="button"
                    >
                      <span
                        className={className({
                          [orderStyles.shippingMethodTip]: !selectFreight
                        })}
                      >
                        {company ||
                          intl.get('order.order_product.shipping_method')}
                      </span>
                      <img
                        src={require('../../../assets/newImportList/icon_expand_more-24px.png')}
                      />
                    </div>

                    {showSelectFreight ? (
                      <SelectShipp
                        freights={this.freights}
                        freightDefault={selectFreight}
                        onCancel={this.toggleFreightList}
                        onOk={this.onOk}
                        updataObj={this.updataObj}
                        loading={okLoading}
                        countryCode={countryCode}
                        bindData={this.state.showBindOption}
                        platformOrigin={this.platformOrigin || ''}
                      />
                    ) : null}
                  </div>


                </div>
                {/* ali框 */}
                <div className="cost">
                  <p>
                    {intl.get('order.bind_product.cost')}:&nbsp;
                    <span>
                      <b>$ {price.toFixed(2)}</b>&nbsp;x&nbsp;
                      <b>{child.qty}</b>
                    </span>
                  </p>
                  {isNotUSD ? (
                    <p>
                      {`${currencySymbol}${round(price * currencyRates, 2)} x ${
                        child.qty
                      }`}
                    </p>
                  ) : null}


                    {selectFreight ? (
                    <div className="emsPriceSele_box">
                      <p className="emsPriceSele">
                        Shipping: {freightAmount.value
                          ? ` $${freightAmount.value}` 
                          : 'free'}
                      </p>
                      <p className="two">
                        {isNotUSD && freightAmount.value ? (
                          <span>
                            {` ${currencySymbol}${round(
                              freightAmount.value * currencyRates,
                              2
                            )}`}
                          </span>
                        ) : null}
                      </p>
                      <p className="emsTimeEnd">
                        Shipping time: {this.selectFreightObj.time}{' '}
                        {intl.get('order.order_product.shipping_time')}
                      </p>
                    </div>
                  ) : null}


                </div>
              </div>
            </div>

            <div className={orderStyles.bindsku}>
              <p className={orderStyles.bindsku_p}>
                {intl.getHTML('order.bind_product.select_varint', {
                  num: child.variant ? child.variant : '--'
                })}
              </p>
              {this.bindOptions.map((item, i) => {
                return (
                  <div className={orderStyles.skuline} key={item.prop_name}>
                    <div className={orderStyles.optionName}>
                      {item.prop_name}:{' '}
                    </div>
                    <RadioGroup
                      onChange={e => {
                        this.changeSku(e, i);
                      }}
                      value={selectSku[i] || undefined}
                    >
                      {item.values.map(v => {
                        return (
                          <Radio
                            key={GetSkuPropertyID(v)}
                            value={GetSkuPropertyID(v)}
                            data_img={v.sku_image}
                            data_v={
                              v.property_value_definition_name ||
                              v.sku_property_value
                            }
                          >
                            {v.property_value_definition_name ||
                              v.sku_property_value}
                          </Radio>
                        );
                      })}
                    </RadioGroup>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

// 获取选中的物流信息
function getFreightCompayName(freights, name) {
  let obj = {};

  freights.forEach(item => {
    if (item.serviceName == name) {
      obj = item;
    }
  });
  return obj;
}

// 获取sku价格
function GetSkuPrice(prod, sku) {
  const itemOfferSiteSalePrice = prod.item_offer_site_sale_price;
  let aeopAeProductSku = [];
  let n = 0;

  if (
    prod.aeop_ae_product_s_k_us &&
    prod.aeop_ae_product_s_k_us.aeop_ae_product_sku
  ) {
    aeopAeProductSku = prod.aeop_ae_product_s_k_us.aeop_ae_product_sku;
  }

  aeopAeProductSku.forEach(item => {
    if (item.id === sku) {
      n =
        Number(
          item.offer_sale_price || itemOfferSiteSalePrice || item.sku_price
        ) || 0;
    }
  });
  return n;
}

// 获取optons
function GetProductSkuOptionFromDetail(prod) {
  const map = new Map();
  const opts = [];
  if (!prod.aeop_ae_product_s_k_us) {
    return opts;
  }
  const {
    aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] }
  } = prod;
  aeop_ae_product_sku.forEach(e => {
    if (e.id == '<none>') {
      return;
    }
    const { aeop_s_k_u_propertys: { aeop_sku_property = [] } = {} } = e;
    aeop_sku_property.forEach((v, i) => {
      const id = GetSkuPropertyID(v,e);
      if (!map.has(id)) {
        // Init
        if (opts.length <= i) {
          opts.push({
            prop_id: v.sku_property_id,
            prop_name: v.sku_property_name,
            values: []
          });
        }
        v.index = i;
        opts[i].values.push(v);
        map.set(id, true);
      }
    });
  });
  return opts;
}

// GetSkuPropertyID 获取SKU属性的ID
function GetSkuPropertyID(prop,item) {
  
  let res = `${prop.sku_property_id}:${prop.property_value_id_long}`;
  if (prop.property_value_definition_name) {
    res = `${res}#${prop.property_value_definition_name}`;
  }
  return res;
}

// GetProductDefaultImage 获取商品默认图片
function GetProductDefaultImage(prod) {
  if (prod.image_u_r_ls && prod.image_u_r_ls.length > 0) {
    return prod.image_u_r_ls.split(';')[0];
  }
  return '';
}
