import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Icon, message } from 'antd';
// import { saveAs } from 'file-saver';
import intl from 'react-intl-universal';
import moment from 'moment';

import { sleep } from 'routes/AgencyOrder/util';
import DSersAlert from '../DSersAlert';
// import { getTaskInfo, getTasks, setTasks } from './utils';
import styles from './AgencyGroup.less';
import {
  AGENCY_EVENT_ROLLING_PAYMENT_STATUS,
  AGENCY_LOCAL_STORE_PAYMENT_ROLLING_TASK
} from '../constants';
import { Get, Post } from 'utils/request';

import {
  getOrderDataByOrderId,
  getPaymentDetailByOrderShowData
} from './utils';
import AgencyPaymentDetailModal from './AgencyPaymentDetailModal';
import { MakePayMentComponents } from 'features';

const alertTypeMap = {
  1: 'info',
  2: 'success',
  3: 'error'
};

const RollingAgencyPayment = ({ userInfo, types, getOrderList }) => {
  const [allTasks, setAllTasks] = useState([]);
  const currentTasksRef = useRef([]);
  // 是否正在轮询
  const tasksRef = useRef(false);

  const unmountRef = useRef(false);

  const dirtyCheckRef = useRef(false);

  const [checkDetailLoading, setCheckDetailLoading] = useState(false);

  const [showPaymentDetail, setShowPaymentDetail] = useState(false);

  const [paymentDetailData, setPaymentDetailData] = useState({
    structData: [],
    initPaymentAmount: '0.00'
  });

  const handleClosePaymentDetail = () => {
    setShowPaymentDetail(false);
  };

  const getOrderPaymentDetail = async paymentId => {
    if (checkDetailLoading) return;
    const paymentTaskData = allTasks.find(i => i.id === paymentId);
    if (!paymentTaskData) return;
    setCheckDetailLoading(true);
    try {
      const dsersOrderIds = [];
      const agencyOrderIds = [];
      paymentTaskData?.orders?.forEach(i => {
        if (!dsersOrderIds.includes(i?.id)) {
          dsersOrderIds.push(i?.id);
        }
        i?.supply_orders?.forEach(supplyOrder => {
          if (!agencyOrderIds.includes(supplyOrder.id)) {
            agencyOrderIds.push(supplyOrder.id);
          }
        });
      });
      const fakeTabStatus = 3;

      let orderShowData = await getOrderDataByOrderId({
        orderIdArray: dsersOrderIds,
        status: fakeTabStatus,
        userInfo,
        types
      });
      // console.log(orderShowData)
      if (!orderShowData?.length) {
        orderShowData = await getOrderDataByOrderId({
          orderIdArray: dsersOrderIds,
          status: 5,
          userInfo,
          types
        });
        if (!orderShowData?.length) {
          throw { msg: 'get Order ShowData Error', orderShowData };
        }
      }

      let agencyId = '';
      // console.log(orderShowData, dsersOrderIds, agencyOrderIds);
      for (const orderOne of orderShowData) {
        if (dsersOrderIds.includes(orderOne.id)) {
          for (const child of orderOne?.childrens || []) {
            console.log(child, agencyOrderIds);
            const findedSp = child?.sp?.find(
              i => agencyOrderIds.includes(i.platform_order_id) && i.sale_id
            );
            if (findedSp) {
              agencyId = findedSp.sale_id;
            }
            if (agencyId) break;
          }
        }
        if (agencyId) break;
      }

      if (!agencyId) {
        throw { msg: 'get agency id error' };
      }
      console.log(agencyId, 'agency id');
      const paymentDetails = getPaymentDetailByOrderShowData({
        data: orderShowData,
        agencyOrderIdArray: agencyOrderIds,
        filterAgencyId: agencyId
      });
      if (!paymentDetails) {
        throw { msg: 'get Payment details error', paymentDetails };
      }

      setPaymentDetailData({
        structData: paymentDetails,
        initPaymentAmount: (paymentTaskData.amount / 100 || 0).toFixed(2)
      });
      setShowPaymentDetail(true);
    } catch (error) {
      console.log(error);
      // console.log(error, 'Fetch payment detail error');
      message.error(
        intl.get('order.agency_orders.payment.get_payment_detail_failed')
      );
    }
    setCheckDetailLoading(false);
  };

  const rollingTask = async needReloadListOnAppend => {
    let appended = false;
    try {
      // ?status={pending,success,failed}&last_id=1&limit=50&followed=true

      const rollingRes = await Get('/supply/order/payments', {
        data: {
          // last_id: `${cachePaymentId}`,
          limit: 50,
          follow: true,
          platform: 5
        }
      });

      // console.log(rollingRes);
      if (rollingRes?.code === 2000 && rollingRes?.data?.payments) {
        for (const paymentInfo of rollingRes?.data?.payments) {
          const cachedPayment = currentTasksRef.current?.find(
            i => i.id === paymentInfo.id
          );
          paymentInfo.time = moment(new Date(paymentInfo?.created_at)).format(
            'lll'
          );
          if (![1, 2, 3].includes(paymentInfo.status)) {
            paymentInfo.status = 1;
          }
          if ([2, 3].includes(paymentInfo.status)) {
            try {
              Post(`/supply/order/payments/${paymentInfo.id}/unfollow`, {
                cancelMessage: true
              });
            } catch (error) {}
          }
          if (cachedPayment) {
            if (cachedPayment.status < paymentInfo.status) {
              Object.assign(cachedPayment, paymentInfo);
              appended = true;
            }
          } else {
            currentTasksRef.current.push(paymentInfo);
            appended = true;
            // getOrderList();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    if (appended && needReloadListOnAppend) {
      getOrderList();
    }
    currentTasksRef.current = [...currentTasksRef.current];
    setAllTasks([...currentTasksRef.current]);
  };
  console.log(allTasks);
  const checkTask = async () => {
    // 还有任务在执行 不再重复执行
    if (tasksRef.current) {
      // 设置已经在轮询的任务为脏任务 以免造成漏查信息
      dirtyCheckRef.current = true;
      // 立即查询一次
      rollingTask(false);
      return;
    }

    // 正在轮询
    tasksRef.current = true;
    // 首次轮询
    let firstRolling = true;

    // 当缓存的任务中还有status不为3或2的 或者首次执行任务 或者命中脏值 就继续轮询
    while (
      (currentTasksRef.current.find(i => ![2, 3].includes(i.status)) ||
        firstRolling ||
        dirtyCheckRef.current) &&
      !unmountRef.current
    ) {
      const appendNeedReloadList = !firstRolling && !dirtyCheckRef.current;
      // 首次轮询状态清除
      firstRolling = false;
      dirtyCheckRef.current = false;

      await rollingTask(appendNeedReloadList);

      await sleep(5 * 1000);
    }
    tasksRef.current = false;
  };

  useEffect(() => {
    checkTask();
    // getOrderPaymentDetail();
    window.EVENT.on(AGENCY_EVENT_ROLLING_PAYMENT_STATUS, checkTask);
    return () => {
      window.EVENT.off(AGENCY_EVENT_ROLLING_PAYMENT_STATUS, checkTask);
      unmountRef.current = true;
    };
  }, []);

  const showList = useMemo(() => {
    return !!allTasks?.find(i => !i.isRemoved);
  }, [allTasks]);

  const handleClosePaymentRollingBar = async paymentId => {
    const paymentData = currentTasksRef.current?.find(i => i.id === paymentId);
    if (paymentData) {
      paymentData.isRemoved = true;
    }
    currentTasksRef.current = [...currentTasksRef.current];
    setAllTasks([...currentTasksRef.current]);
    try {
      Post(`/supply/order/payments/${paymentId}/unfollow`, {
        cancelMessage: true
      });
    } catch (error) {}
  };

  return (
    <div>
      <AgencyPaymentDetailModal
        data={paymentDetailData}
        visible={showPaymentDetail}
        onClose={handleClosePaymentDetail}
      />
      {showList ? (
        <div style={{ marginTop: 16 }}>
          {allTasks.map(i => {
            if (i.isRemoved) return null;
            return (
              <MakePayMentComponents
                key={i?.id}
                status={i?.status}
                paymentLink={
                  i.payment_method === 'stripe'
                    ? `${location.origin}/app/make-payment/stripe?transactionId=${i.id}&from=agency_order`
                    : i?.redirect_url
                }
                checkDetailHandle={e => {
                  getOrderPaymentDetail(i?.id);
                }}
                handleClose={() => handleClosePaymentRollingBar(i?.id)}
                time={i.time}
                className={styles.agencyRollingAlert}
                error={i.err_reason}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(RollingAgencyPayment);
