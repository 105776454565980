export const FROM_SUPPLY_PLATFORM = {
  1: 'order.order_product.from_other',
  2: 'order.order_product.from_aliexpress',
  4: 'order.order_product.from_tmall',
  5: 'order.agency_orders.from_agency'
};

export const SUPPLY_ORDER_NO_TITLE = {
    1: 'order.order_product.ali_order_no',
    2: 'order.order_product.ali_order_no',
    4: 'order.order_product.tmall_order_no',
    5: 'order.search_type.agency_order_no'
}