import React, { Fragment } from 'react';
import { connect } from 'dva';
import { Modal, Icon, Checkbox, Popover, Progress, Button, Input,message, Form } from 'antd';
import cloneDeep from 'lodash/cloneDeep';

import Ellipsis from 'components/Ellipsis';

import orderStyles from './Order.less';
import orderProductsStyles from './OrderProducts.less';
import './Order.css';
import './modalConfirm.css';

import defaultImg from '../../assets/default-image.jpg';
import bogo_flag from '../../assets/order/bogo_flag.png';
import bundle_flag from '../../assets/order/bundle_flag.png';

import intl from 'react-intl-universal';
import { getLanguage, replaceUrlToHttps } from '../../utils/utils';
import { confirmLanguage } from '../../common/sysconfig';
import { filterRollbackExtLineItems, filterUpdateExtLineItems, supplyPlatformEquals } from './util';

const imageCss = { width: '260px', height: '260px', objectFit: 'contain' };

@connect(({ order, loading }) => ({
  order,
  loading: loading.models.order,
  okLoading: loading.effects['order/updataExt']
}))
export default class OrderRollBack extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checkedAll: true,
      checkedSome: false,
      fulfilledList: [],
      progressStatus: 'success',
      showText:false
    };
  }

  componentWillMount() {
    let { data, status } = this.props;

    data = cloneDeep(data);

    data = this.setSpDefaultChecked(data, status);
    this.setState({
      fulfilledList: data
    });
  }

  allCheckedIds = []; // 所有可fulfilled的子订单id
  checkedLength = -1; // 选中的个数
  showProgress = false; // 是否展示进度条
  progressNum = 1; // 进度
  currentLanguage = getLanguage();

  // 设置sp上的默认状态
  setSpDefaultChecked = (data, tabStatus) => {
    const { supplyPlatform } = this.props;
    data.forEach((item, ii) => {
      item.childrens.forEach((child, ci) => {
        if (child.is_black_list === 2) {
          return;
        }
        // if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
        //   return;
        // }
        const flag =
          item.ext.fulfillments && item.ext.fulfillments.includes(child.id);
        if (
          (child.sp.length == 0 || child.supplierMapType == 4 || flag) &&
          item.ext.lineitems[ci].status >= 2048
        ) {
          child.checked = true;
          const index = `${ii}-${ci}`;
          if (!this.allCheckedIds.includes(index)) {
            this.allCheckedIds.push(index);
          }
        }

        if (flag) {
          // 手动fulfilled只能整个字订单rollback
          return;
        }

        child.sp.forEach((sp, si) => {
          if (child.supplierMapType == 1) {
            if (si != this.getWhichSpCanRollBack(child)) {
              return;
            }
          }
          if (sp.status == tabStatus) {
            sp.checked = true;
            const index = `${ii}-${ci}-${si}`;
            if (!this.allCheckedIds.includes(index)) {
              this.allCheckedIds.push(index);
            }
          }
        });
      });
    });
    this.checkedLength = this.allCheckedIds.length;
    return data;
  };

  // basMap 哪个sp可以roll back
  getWhichSpCanRollBack = child => {
    const { sp = [] } = child;
    const { status } = this.props;
    let index = 0;
    let flag = false;

    if (sp.length === 2) {
      sp.forEach((oneSp, si) => {
        if ([4, 3, 7].includes(oneSp.status)) {
          index = si;
          flag = true;
        }
        if (oneSp.is_default && oneSp.status == status && !flag) {
          index = si;
          flag = true;
        }
        if (oneSp.status == status && !oneSp.is_default && !flag) {
          index = si;
        }
        if (!oneSp.is_default && oneSp.platform_order_id) {
          index = si;
          flag = true;
        }
      });
    }

    return index;
  };

  // 设置rollback时子子订单是否应该展示
  getSpOrderShowAsFulfilled = (sp, tabStatus, child, ext) => {
    let flag = false; // 返回ture展示 false不展示

    if (sp.status == tabStatus) {
      flag = true;
    }

    if (ext.fulfillments && ext.fulfillments.includes(child.id)) {
      flag = false;
    }

    return flag;
  };

  // 子订单该不该展示
  getChildShowRollBack = (child, tabStatus, ext, childIndex) => {
    const { lineitems } = ext;

    let flag = false;

    if (
      (child.sp.length == 0 || child.supplierMapType == 4) &&
      lineitems[childIndex].status >= 2048
    ) {
      flag = true;
    }

    if (
      ext.fulfillments &&
      ext.fulfillments.includes(child.id) &&
      lineitems[childIndex].status >= 2048
    ) {
      // 手动 fulfillments 只能 rollback 整个子订单
      flag = true;
    }

    return flag;
  };

  // basMap 哪个应该应该roll back
  getWhichOrderCanRollBack = child => {
    let i = null;
    let flag = true;

    child.sp &&
      child.sp.forEach((item, ci) => {
        if (item.status == 5 && item.is_default) {
          i = ci;
          flag = false;
        }
        if (item.status == 5 && !item.is_default && flag) {
          i = ci;
        }
      });

    return i;
  };

  // 获取 spanrow 数量
  getRowSpanLen = (order, tabStatus) => {
    const { ext: { fulfillments = [], lineitems } } = order;
    let n = 0;
    const { supplyPlatform } = this.props;
    order.childrens.forEach((child, i) => {
      if (child.is_black_list === 2) {
        return;
      }
      // if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
      //   return;
      // }
      // 手动fulfilled的必须整个子订单roll back
      if (lineitems[i].status >= 2048 && fulfillments.includes(child.id)) {
        n += 1;
        return;
      }

      // 没有sp的情况
      if (
        (child.sp.length == 0 || child.supplierMapType == 4) &&
        lineitems[i].status >= 2048
      ) {
        n += 1;
      }

      if (!fulfillments.includes(child.id)) {
        child.sp.forEach((sp, si) => {
          if (child.supplierMapType == 1) {
            const i = this.getWhichOrderCanRollBack(child);
            if (si == i) {
              n += 1;
            }
          } else if (sp.status == tabStatus) {
              n += 1;
            }
        });
      }
    });

    return n || 1;
  };

  // 获取rollback产品列表
  readerFulfilledList = (list, tabStatus) => {
    const arr = [];
    const { supplyPlatform } = this.props;
    list.forEach((order, index) => {
      const rowspanLen = this.getRowSpanLen(order, tabStatus);
      let rowspanIndex = 0;

      order.childrens.forEach((child, ii) => {
        if (child.is_black_list === 2) {
          return;
        }
        // if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
        //   return;
        // }
        // 是不是只展示子订单不展示子子订单
        if (this.getChildShowRollBack(child, tabStatus, order.ext, ii)) {
          rowspanIndex += 1;
          arr.push(
            <tr key={`${index}${ii}`}>
              {rowspanIndex == 1 ? (
                <td rowSpan={rowspanLen}>{order.orderNumber}</td>
              ) : null}
              <td>
                <Checkbox
                  className={orderStyles.confirmCheckbox}
                  checked={child.checked}
                  disabled // 产品规定不能选
                  onChange={e => this.setFulfilledChecked(e, index, ii)}
                />
              </td>
              <td>
                <p className={orderProductsStyles.fulfillProduct}>
                  <Popover
                    placement="leftTop"
                    trigger="click"
                    content={
                      <img src={replaceUrlToHttps(child.image) || defaultImg} style={imageCss} />
                    }
                    overlayClassName={orderStyles.popover}
                  >
                    <img src={replaceUrlToHttps(child.image) || defaultImg} />
                  </Popover>
                  <Ellipsis lines={2}>
                    <span>{child.title}</span>
                  </Ellipsis>
                </p>
              </td>
              <td>{child.platform_type !== 5 ? '' : child.sp && child.sp[0] && child.sp[0].platform_order_id}</td>
              <td>
                {this.readerMarkAsFulilledFeedback(
                  child.markAsFulfilledFeedback
                )}
              </td>
            </tr>
          );
        }

        child.sp.forEach((sp, si) => {
          if (child.supplierMapType == 1) {
            const i = this.getWhichSpCanRollBack(child);
            if (i != si || sp.status != tabStatus) {
              return;
            }
            if (
              order.ext.fulfillments &&
              order.ext.fulfillments.includes(child.id)
            ) {
              return;
            }
          }
          if (
            !this.getSpOrderShowAsFulfilled(sp, tabStatus, child, order.ext) &&
            child.supplierMapType != 1
          ) {
            return;
          }
          rowspanIndex += 1;
          arr.push(
            <tr key={`${index}${ii}${si}${sp.platform_sku}`}>
              {rowspanIndex == 1 ? (
                <td rowSpan={rowspanLen}>{order.orderNumber}</td>
              ) : null}
              <td>
                <div className={orderProductsStyles.mapTypeWrap}>
                  {child.supplierMapType == 3 && sp.is_base != true ? (
                    <img
                      className={orderProductsStyles.mapTypeFlag}
                      src={bogo_flag}
                      title="BOGO"
                    />
                  ) : null}
                  {child.supplierMapType == 4 ? (
                    <img
                      className={orderProductsStyles.mapTypeFlag}
                      src={bundle_flag}
                      title="Bundle"
                    />
                  ) : null}
                  <Checkbox
                    className={orderStyles.confirmCheckbox}
                    checked={sp.checked}
                    disabled // 产品规定不能选
                    onChange={e => this.setFulfilledChecked(e, index, ii, si)}
                  />
                </div>
              </td>
              <td>
                <div className={orderProductsStyles.fulfillProduct}>
                  <Popover
                    placement="leftTop"
                    trigger="click"
                    content={
                      <img src={replaceUrlToHttps(sp.sku_img) || defaultImg} style={imageCss} />
                    }
                    overlayClassName={orderStyles.popover}
                  >
                    <img src={replaceUrlToHttps(sp.sku_img) || defaultImg} />
                  </Popover>
                  <Ellipsis lines={2}>
                    <span>{sp.title}</span>
                  </Ellipsis>
                </div>
              </td>
              <td>{sp.platform_order_id || ''}</td>
              <td>
                {this.readerMarkAsFulilledFeedback(sp.markAsFulfilledFeedback)}
              </td>
            </tr>
          );
        });
      });
    });
    return arr;
  };

  readerMarkAsFulilledFeedback = status => {
    if (status == '' || status == undefined) {
      return null;
    }
    if (status == 'loading') {
      return <Icon type="loading" style={{ color: '#aaa', fontSize: 16 }} />;
    }
    if (status == 'check-circle') {
      return (
        <Icon type="check-circle" style={{ color: '#54D022', fontSize: 16 }} />
      );
    }
    if (status == 'close-circle') {
      return (
        <Icon type="close-circle" style={{ color: '#FF5200', fontSize: 16 }} />
      );
    }
  };

  // 设置单个选中状态
  setFulfilledChecked = (e, index, ii, si) => {
    // index大订单索引  ii子订单索引
    const { fulfilledList } = this.state;
    const checked = e.target.checked;

    if (si == undefined) {
      fulfilledList[index].childrens[ii].checked = checked;
    } else {
      fulfilledList[index].childrens[ii].sp[si].checked = checked;
    }

    if (checked) {
      this.checkedLength = this.checkedLength + 1;
    } else {
      this.checkedLength = this.checkedLength - 1;
    }

    this.setState({
      fulfilledList: [...fulfilledList],
      checkedAll:
        this.checkedLength == this.allCheckedIds.length,
      checkedSome:
        !!(this.checkedLength != this.allCheckedIds.length &&
        this.checkedLength != 0)
    });
  };

  // 设置全选
  setFulfilledListCheckedAll = e => {
    const checked = e.target.checked;
    const { fulfilledList } = this.state;
    const { supplyPlatform } = this.props;
    fulfilledList.forEach(item => {
      item.childrens.forEach((child, ci) => {
        if (child.is_black_list === 2) {
          return;
        }

        // if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
        //   return;
        // }

        const flag =
          item.ext.fulfillments && item.ext.fulfillments.includes(child.id);
        if (
          (child.sp.length == 0 || child.supplierMapType == 4 || flag) &&
          item.ext.lineitems[ci].status >= 2048
        ) {
          child.checked = checked;
        }
        child.sp.forEach(sp => {
          if (child.supplierMapType == 1 && !sp.is_default) {
            return;
          }
          sp.checked = checked;
        });
      });
    });

    this.checkedLength = checked ? this.allCheckedIds.length : 0;

    this.setState({
      checkedAll: checked,
      checkedSome: false,
      fulfilledList: [...fulfilledList]
    });
  };

  // 设置loading状态
  setListLoading = () => {
    const { fulfilledList } = this.state;
    const { supplyPlatform } = this.props;
    fulfilledList.forEach(item => {
      item.childrens.forEach((child, i) => {
        if (child.is_black_list === 2) {
          return;
        }

        // if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
        //   return;
        // }

        if (
          (child.sp.length == 0 || child.supplierMapType == 4) &&
          child.checked
        ) {
          child.markAsFulfilledFeedback = 'loading';
        }

        if (
          item.ext.fulfillments &&
          item.ext.fulfillments.includes(child.id) &&
          item.ext.lineitems[i].status >= 2048
        ) {
          child.markAsFulfilledFeedback = 'loading'; // 手动fulfilled只能整个lineitem回滚
        }

        child.sp.forEach(sp => {
          if (sp.checked) {
            sp.markAsFulfilledFeedback = 'loading';
          }
        });
      });
    });

    this.setState({
      fulfilledList: [...fulfilledList]
    });
  };

  // 设置结果状态
  setFeedback = (i, code) => {
    const { fulfilledList } = this.state;
    const currentOrder = fulfilledList[i];
    let result = true;
    const { supplyPlatform } = this.props;
    if (code != 2000 && code != 2010) {
      result = false;
      this.progressStatus = 'exception';
    }

    currentOrder.childrens.forEach(child => {
      if (child.is_black_list === 2) {
        return;
      }
      // if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
      //   return;
      // }
      if (child.markAsFulfilledFeedback) {
        child.markAsFulfilledFeedback = result ? 'check-circle' : 'close-circle';
      }

      child.sp.forEach(sp => {
        if (sp.checked) {
          sp.markAsFulfilledFeedback = result ? 'check-circle' : 'close-circle';
        }
      });
    });

    this.setState({
      fulfilledList: [...fulfilledList],
      progressStatus: this.progressStatus
    });
  };

  progressStatus = 'success';
  submitFulfilledList = null;
  hasSetListLoading = false;
  fulfilledOverSucc = false;
  hasSave = false;
  statusMap = {
    '1': 1,
    '2': 2,
    '3': 4,
    '4': 8,
    '5': 2048,
    '6': 16,
    '7': 32
  };

  // 求 ext 中 lineitems的状态
  getLineItemsStatus = (child, lineitem, i, detail) => {
    // lineitem是ext中的第i个
    const { status } = this.props;

    const { refunds, fulfillments, line_items } = detail;

    const shopifyLineItem = line_items[i];

    const arr = [];
    // 未在shopify退款的商品数
    let unrefundQuantity = shopifyLineItem.quantity;

    if (refunds) {
      refunds.forEach(re => {
        re.refund_line_items?.forEach(item => {
          if (item.line_item_id != shopifyLineItem.id) {
            return;
          }
          unrefundQuantity -= item.quantity || 0;
          if (!arr.includes(6)) {
            arr.push(6);
          }
        });
      });
    }

    // if( fulfillments ){
    //   fulfillments.forEach( fu => {
    //     fu.line_items.forEach( item => {
    //       if( item.id == shopifyLineItem.id && !arr.includes( 5 ) ){
    //         arr.push( 5 )
    //       }
    //     })
    //   })
    // }
    // 如果该商品已全部在shopify退款 rollback就不再保留sp状态
    // console.log(unrefundQuantity);
    if (unrefundQuantity > 0) {
      if (child.supplierMapType == 1) {
        if (lineitem.sp) {
          arr.push(lineitem.sp[this.getWhichSpCanRollBack(lineitem)].status);
        }
      } else if (lineitem.sp) {
        lineitem.sp.forEach(item => {
          arr.push(item.status);
        });
      }
    }

    let v = 0;
    arr.forEach(n => {
      v |= this.statusMap[n];
    });

    return v;
  };

  // basic mapping 有一个sp选中另一个也要rollback
  getBasMapHasRollback = child => {
    let flag = false;

    if (child.supplierMapType != 1) {
      return flag;
    }

    child.sp.forEach(sp => {
      if (sp.status == 5 && sp.checked) {
        flag = true;
      }
    });
    return flag;
  };
  rollBackConfirmWord = ''; // Roll back 商品需要用户输入confirm
  RollBackFlag = false; // 禁止重复点击按钮

  rollBackConfirmWords = e => {
    this.rollBackConfirmWord = e.target.value;

    if(this.rollBackConfirmWord.toLocaleLowerCase().trim() == confirmLanguage[this.currentLanguage]){
      let domx = document.querySelector('.modalConfirmDom_Input_Tip')
      domx.style.opacity = 0
    }else{
      let domx = document.querySelector('.modalConfirmDom_Input_Tip')
      domx.style.opacity = 1
    }
  };

  modals = {}

  getModalConfirmTitle = () => {
    return <div className='modalConfirmDom_Box'>
      <div className='modalConfirmDom_title'>
        <span className='modalConfirmDom_title_one'>{intl.get('order.roll_desc')}</span>
        <span className='modalConfirmDom_title_icon' onClick={this.closeModalConfirm}><Icon type="close" /></span>
      </div>
      <div className='modalConfirmDom_Content'>{intl.get('order.roll_back_confirm_title')}</div>
    </div>
  }

  closeModalConfirm = () => {
    this.modals.destroy()
  }

  messerr = ''

  // 提交
  markMultipleFulfilledTrue = () => {
    const { fulfilledList } = this.state;
    this.hasSave = true;
    this.showProgress = true;
    this.submitFulfilledList = cloneDeep(fulfilledList);

    this.modals = Modal.confirm({
      title: this.getModalConfirmTitle(),
      content: (
        <div className='modalConfirmDom_Input_Box'>
          <Input placeholder={intl.get('order.roll_back_confirm_tip')} onChange={this.rollBackConfirmWords} />
          <span className='modalConfirmDom_Input_Tip' style={{opacity:0}}>{intl.get('order.roll_back_confirm_tip')}</span>
        </div>
      ),
      icon:null,
      className:"roBackConfigModal modalConfirmDom",
      keyboard: false,
      okText: intl.get('public.cancel'),
      okType: 'primary',
      cancelText: intl.get('order.confirm_modal_up.fulfill_confirm'),
      onCancel: () => {
        if (
          this.rollBackConfirmWord.toLocaleLowerCase().trim() !==
          confirmLanguage[this.currentLanguage]
        ) {
          return Promise.reject('Please input CONFIRM to Roll back orders.');
        }
        this.RollBackFlag = true;
        this.submitSave(0);
      }
    });
  };

  // 提交保存
  submitSave = i => {
    const data = this.submitFulfilledList[i];
    let flag = false;
    const { supplyPlatform } = this.props;
    const fulfillment_ids = this.getFulfillmentIds(data);
    const { ext: { lineitems } } = data;
    const shouldDeleteSpLineItems = {};
    data.childrens.forEach((child, ci) => {
      if (child.is_black_list === 2) {
        return;
      }
      // if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
      //   return;
      // }

      const isManualFulfilled =
        data.ext.fulfillments && data.ext.fulfillments.includes(child.id); // 是不是手动fulfilled
      const childIsChecked = child.checked;
      let hasRollBack = false;
      if (
        (child.sp.length == 0 || child.supplierMapType == 4) &&
        lineitems[ci].status >= 2048 &&
        child.checked
      ) {
        child.status = 2;
        delete child.checked;
        flag = true;
        lineitems[ci].status = 2;
        hasRollBack = true;
        // 记录需要删除sp的lineItem
        shouldDeleteSpLineItems[lineitems[ci].lineitem_id] = true;
      }

      const mustRollback = this.getBasMapHasRollback(child);
      child.sp.forEach(sp => {
        if (
          (sp.status == 5 && sp.checked) ||
          (isManualFulfilled && childIsChecked && sp.status == 5) ||
          mustRollback
        ) {
          sp.status = 2;
          sp.platform_order_id = '';
          sp.logistics_infos = null;
          flag = true;
          hasRollBack = true;
          shouldDeleteSpLineItems[lineitems[ci].lineitem_id] = true;
        }
        delete sp.checked;
      });
      // 8192状态不再流转 rollback 依然为8192 在fulfill下展示
      if (hasRollBack && lineitems[ci].status !== 8192) {
        lineitems[ci].status = this.getLineItemsStatus(
          child,
          lineitems[ci],
          ci,
          data.detail
        );
      }
    });

    if (!flag) {
      this.setPercent(i);
      if (this.submitFulfilledList.length > i + 1) {
        this.submitSave(i + 1);
      }
      if (this.submitFulfilledList.length == i + 1) {
        this.fulfilledOverSucc = true;
      }
      return;
    }

    if (!this.hasSetListLoading) {
      this.setListLoading();
      this.hasSetListLoading = true;
    }

    if (data.ext.fulfillments) {
      data.ext.fulfillments = data.ext.fulfillments.filter(
        item =>
          !!lineitems.find(
            li => li.lineitem_id === item && (li.status & 2048) === 2048
          )
      );

      if (data.ext.fulfillments.length === 0) {
        data.ext.fulfillment_status = 0;
      } else if (data.ext.fulfillments.length === data.ext.lineitems.length) {
        data.ext.fulfillment_status = 2;
      } else {
        data.ext.fulfillment_status = 1;
      }
    }

    // 2021.1.15 后端要求 rollback 时不携带 sp
    if (data?.ext?.lineitems) {
      data.ext.lineitems.forEach(item => {
        // 不进行rollback的lineItem 需要保留sp
        if (!shouldDeleteSpLineItems[item.lineitem_id]) {
          return;
        }

        if(item.childCurrentMappingSupplyType !== item.platform_type || (item.platform_type >= 5 && item.childCurrentMappingSupplyType >= 5) ) {
          // console.log('应该改类型了')
          item.platform_type = item.childCurrentMappingSupplyType || 1;
          if(item.childCurrentMappingSupplyType && item.childCurrentMappingSupplyType >= 5) {
            item.agency_id = item.childCurrentAgencyId;
          }
        }

        if (item.sp) {
          delete item.sp; // eslint-disable-line
          // 2022/10/21新增字段 为了兼容agency与tmall/ae的筛选冲突
          item.is_manual_fulfill = false;
          item.is_manual_cancel = false;
        }
      });
    }
    window.UPDATA_EXT = true
    window.UPDATA_EXT_TIME = +new Date()

    const oldSubmitExt = data?.ext?.multiple_platform_error?.agency?.code == 24 ? {
      ...data?.ext,
      err:{}
    } : data?.ext;

    const { originalOldOrderMap  } = this.props;
    const originalExt = originalOldOrderMap[data.id];
    const submitExt = filterRollbackExtLineItems(supplyPlatform, oldSubmitExt, originalExt, shouldDeleteSpLineItems);

    let rollBackdata = {
      order_id: data.id,
      ext: submitExt,
      fulfillment_ids,
      store_id: data.storeId,
      type: 'rollback'
    }

    
    this.props.dispatch({
      type: 'order/updataExt',
      payload: {
        data: rollBackdata,
        callback: d => {
          this.setPercent(i);
          if (this.submitFulfilledList.length > i + 1) {
            this.submitSave(i + 1);
          }
          if (this.submitFulfilledList.length == i + 1) {
            if(d.code == 2010){
              message.success(intl.get('order.roll_back_awaiting_shopify_hook_tip'))
            }
            this.fulfilledOverSucc = true;
            this.RollBackFlag = false;
          }
          this.setFeedback(i, d.code);
        }
      }
    });
  };

  // 设置进度
  setPercent = i => {
    i += 1;
    this.progressNum = Math.ceil(i / this.submitFulfilledList.length * 100);
  };

  // 获取被shopify fulfilld的子订单 并且要回滚的子订单 的 fulfillments id 不是line_item.id
  getFulfillmentIds = data => {
    const { childrens, detail: { fulfillments = [] } } = data;
    const { supplyPlatform } = this.props;
    if (fulfillments.length == 0) {
      return [];
    }

    const arr = [];

    childrens.forEach(child => {
      let flag = false;
      if (child.is_black_list === 2) {
        return;
      }
      // if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
      //   return;
      // }
      if (child.checked) {
        flag = true;
      }

      child.sp.forEach(sp => {
        if (sp.checked) {
          flag = true;
        }
      });

      if (flag) {
        const fulfillmentsIds = this.fulfillmentsHasLineItemId(
          fulfillments,
          child.id
        );
        if (fulfillmentsIds.length) {
          arr.push(...fulfillmentsIds);
        }
      }
    });

    return Array.from(new Set(arr));
  };

  fulfillmentsHasLineItemId = (fulfillments, id) => {
    let fulfillments_ids = [];
    fulfillments.forEach(item => {
      if (item.status == 'cancelled') {
        return;
      }
      item.line_items.forEach(line => {
        if (line.id == id) {
          fulfillments_ids.push(item.id);
        }
      });
    });
    return fulfillments_ids;
  };

  title = () => {
    return <>
      <div className='rollback_title'>{intl.get('order.roll_desc')}</div>
      <div className='rollback_desc'>{intl.get('order.agency_orders.agency_rollback_title')}</div>
    </>
  }

  render() {
    const { status, hideRollBackOrders, okLoading } = this.props;

    const {
      checkedAll,
      checkedSome,
      fulfilledList,
      progressStatus
    } = this.state;

    console.log(fulfilledList, 777);

    return (
      <Modal
        visible
        width={1000}
        title={this.title()}
        footer={false}
        maskClosable={false}
        wrapClassName={orderProductsStyles.abcd}
        onCancel={() => hideRollBackOrders(this.hasSave)}
      >
        <div className={orderProductsStyles.abcd_tableBox}>
          <table>
            <thead>
              <tr>
                <th>{intl.get('order.global_place_order')}</th>
                <th>
                  <Checkbox
                    className={orderStyles.confirmCheckbox}
                    checked={checkedAll}
                    onChange={this.setFulfilledListCheckedAll}
                    indeterminate={checkedSome}
                    disabled // 产品规定不能选
                  />
                </th>
                <th style={{ width: 200 }}>
                  {intl.get('order.confirm.table_title.product')}
                </th>
                <th>
                  {intl.get(
                    'order.search_type.agency_order_no'
                  )}
                </th>
                <th>{intl.get('order.confirm.table_title.feedback')}</th>
              </tr>
            </thead>
            <tbody>{this.readerFulfilledList(fulfilledList, status)}</tbody>
            <tfoot>
              <tr>
                <td>
                  {this.checkedLength}&nbsp;
                  {intl.get('order.fulfill_confirm.item', {
                    num: this.checkedLength
                  })}
                </td>
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tfoot>
          </table>
        </div>
        {this.showProgress ? (
          <Progress percent={this.progressNum} status={progressStatus} />
        ) : null}
        <div className={orderProductsStyles.abcd_btnBox}>
          {this.fulfilledOverSucc ? (
            <button
              data="dx"
              style={{ width: 116,cursor:'pointer' }}
              onClick={() => hideRollBackOrders(this.hasSave)}
            >
              {intl.get('public.ok')}
            </button>
          ) : (
            <Button
            data="dx"
              disabled={!this.checkedLength || okLoading || this.RollBackFlag}
              onClick={this.markMultipleFulfilledTrue}
            >
              {intl.get('order.roll_back_btn')}
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}
