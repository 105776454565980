import React from 'react';
import { connect } from 'dva';
import { Modal, Icon, Checkbox, Popover, Progress, Button } from 'antd';

import cloneDeep from 'lodash/cloneDeep';
import intl from 'react-intl-universal';

import Ellipsis from 'components/Ellipsis';

import orderStyles from './Order.less';
import orderProductsStyles from './OrderProducts.less';
import './modalConfirm.css';
import { replaceUrlToHttps, CommonDataCollect } from 'utils/utils';
import { filterUpdateExtLineItems, supplyPlatformEquals } from './util';

const imageCss = { width: '260px', height: '260px', objectFit: 'contain' };

@connect(({ order, loading }) => ({
  order,
  loading: loading.models.order,
  okLoading: loading.effects['order/updataExt']
}))
export default class OrderFulfilled extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checkedAll: true,
      checkedSome: false,
      fulfilledList: [],
      progressStatus: 'success',
      checkSp: false,
      isSpCancel: true
    };
  }

  componentWillMount() {
    let { data, fulfilledChildId, status } = this.props;

    data = cloneDeep(data);

    if (fulfilledChildId) {
      data = this.setDataChecked(data, fulfilledChildId);
    }

    this.fulfilledChildId = fulfilledChildId;
    this.setState({
      fulfilledList: data
    });

    //判断是不是shopify cancel
    let spCancel;

    var pushStack = []

    data.forEach(orderItem => {
      orderItem.childrens.forEach(childrensItem => {
        if (!this.getChildOrderShowAsFulfilled(childrensItem, status)) {
          return;
        }
        pushStack.push(childrensItem)
      })
    })

    if(status == 6){
      pushStack.forEach(childrensItem => {
        if(childrensItem.canceledBy == 'shopify'){
          childrensItem.AECanceled.forEach(AEITEM => {
            if(AEITEM){
              spCancel = true;
            }else{
              spCancel = false;
            }
          })
        }else{
          spCancel = true;
        }
      })


      this.setState({
        isSpCancel: spCancel
      })
    }

  }

  allCheckedIds = []; // 所有可fulfilled的子订单id
  checkedLength = -1; // 选中的个数
  showProgress = false; // 是否展示进度条
  progressNum = 1; // 进度
  fulfilledChildId = ''; // 单个子订单fulfilled的id


  setDataChecked = (data, id) => {
    const { supplyPlatform, agencyAuthStatus, status } = this.props;
    data.forEach(item => {
      item.childrens.forEach(child => {
        if (child.is_black_list === 2) {
          return;
        }
        if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }
        if(agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status && status === 3) {
          return;
        }
        if (child.id != id) {
          child.markAsFulfilledChecked = false;
        } else {
          child.markAsFulfilledChecked = true;
        }
      });
    });
    return data;
  };

  // 设置fulfilled状态时子订单是否应该展示
  getChildOrderShowAsFulfilled = (child, tabStatus) => {
    let flag = false; // 返回ture展示 false不展示

    if (
      (child.supplierMapType == 1 || child.supplierMapType == 2) &&
      child.sp.length == 0 &&
      child.status == tabStatus
    ) {
      flag = true;
      return flag;
    }

    if (child.supplierMapType == 1) {
      const spIndex = this.getWhichSpCanFulfilled(child);
      if (child.sp[spIndex].status == tabStatus) {
        flag = true;
        return flag;
      }
    }
    // console.log(child.supplierMapType, child.useSp.status, child.useSp)
    if (
      child.supplierMapType === 6 &&
      (child?.sp?.[0]?.status === tabStatus ||
        (child?.sp?.[0]?.status === 5 && child.status === 8 && tabStatus === 9))
    ) {
      return true;
    }

    if ([2, 5, 6].includes(child.supplierMapType) && child.useSp.status == tabStatus) {
      flag = true;
      return flag;
    }

    if (child.supplierMapType == 3) {
      if (child.sp && child.sp.length == 0 && child.status == tabStatus) {
        flag = true;
        return flag;
      }
      child.sp.forEach(sp => {
        if (sp.status == tabStatus) {
          flag = true;
        }
      });
    }

    if (child.supplierMapType == 4) {
      if (child.sp && child.sp.length == 0 && child.status == tabStatus) {
        flag = true;
        return flag;
      }
      child.sp.forEach(sp => {
        if (sp.status == tabStatus) {
          flag = true;
        }
      });
    }



    return flag;
  };


  // 获取把订单设为完成单弹窗里的产品列表
  readerFulfilledList = (list, tabStatus) => {
    const { supplyPlatform, agencyAuthStatus } = this.props;
    console.log(list, tabStatus)
    const arr = [];
    list.forEach((order, index) => {
      order.childrens.forEach((child, ii) => {
        if (child.is_black_list === 2) {
          return;
        }
        // 筛选agency 订单
        if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }
        if(tabStatus === 3 && agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status) {
          return;
        }
        // console.log(this.getChildOrderShowAsFulfilled(child, tabStatus))
        if (!this.getChildOrderShowAsFulfilled(child, tabStatus)) {
          return;
        }
        if (!this.allCheckedIds.includes(child.id)) {
          this.allCheckedIds.push(child.id);
        }

        arr.push(
          <tr key={child.id}>
            <td>
              <Checkbox
                className={orderStyles.confirmCheckbox}
                checked={child.markAsFulfilledChecked}
                onChange={e => {
                  this.setFulfilledChecked(e, index, ii);
                }}
              />
            </td>
            <td>{order.orderNumber}</td>
            <td>
              <div className={orderProductsStyles.fulfillProduct}>
                <Popover
                  placement="leftTop"
                  trigger="click"
                  content={
                    <img
                      src={replaceUrlToHttps(child.image)}
                      style={imageCss}
                    />
                  }
                  overlayClassName={orderStyles.popover}
                >
                  <img src={replaceUrlToHttps(child.image)} />
                </Popover>
                <Ellipsis lines={2}>
                  <span>{child.title}</span>
                </Ellipsis>
              </div>
            </td>
            <td>{this.getOrderNumber(child)}</td>
            <td>
              {this.readerMarkAsFulilledFeedback(child.markAsFulfilledFeedback)}
            </td>
          </tr>
        );
      });
    });

    if (this.checkedLength == -1) {
      if (this.fulfilledChildId) {
        this.checkedLength = 1;
      } else {
        this.checkedLength = this.allCheckedIds.length;
      }
    }
    return arr;
  };

  getOrderNumber = child => {
    let v = '';
    if (child.supplierMapType == 1) {
      v = child.sp[this.getWhichSpCanFulfilled(child)].platform_order_id;
    } else {
      if(child.platform_type === 5) {
        v = child?.sp?.[0]?.platform_order_id || ''
      }else{
        v = (child.useSp && child.useSp.platform_order_id) || '';
      }
    }
    return v;
  };

  readerMarkAsFulilledFeedback = status => {
    if (status == '' || status == undefined) {
      return null;
    }
    if (status == 'loading') {
      return <Icon type="loading" style={{ color: '#aaa', fontSize: 16 }} />;
    }
    if (status == 'check-circle') {
      return (
        <Icon type="check-circle" style={{ color: '#54D022', fontSize: 16 }} />
      );
    }
    if (status == 'close-circle') {
      return (
        <Icon type="close-circle" style={{ color: '#FF5200', fontSize: 16 }} />
      );
    }
  };

  // 设置单个选中状态
  setFulfilledChecked = (e, index, ii) => {
    // index大订单索引  ii子订单索引
    const { fulfilledList } = this.state;
    const { supplyPlatform, agencyAuthStatus, status } = this.props;
    let n = 0;

    fulfilledList[index].childrens[ii].markAsFulfilledChecked =
      e.target.checked;
    fulfilledList.forEach(item => {
      item.childrens.forEach(child => {
        if (child.is_black_list === 2) {
          return;
        }
        if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }
        if(status === 3 && agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status) {
          return;
        }
        if (
          this.allCheckedIds.includes(child.id) &&
          child.markAsFulfilledChecked
        ) {
          n++;
        }
      });
    });

    this.checkedLength = n;

    this.setState({
      fulfilledList: [...fulfilledList],
      checkedAll: n == this.allCheckedIds.length,
      checkedSome: !!(n != this.allCheckedIds.length && n != 0)
    });
  };

  // 设置全选
  setFulfilledListCheckedAll = e => {
    const checked = e.target.checked;
    const { fulfilledList } = this.state;
    const { supplyPlatform, agencyAuthStatus, status } = this.props;
    fulfilledList.forEach(item => {
      item.childrens.forEach(child => {
        if (child.is_black_list === 2) {
          return;
        }
        if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }
        if(agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status && status === 3) {
          return;
        }
        child.markAsFulfilledChecked = checked;
      });
    });

    this.checkedLength = checked ? this.allCheckedIds.length : 0;

    this.setState({
      checkedAll: checked,
      checkedSome: false,
      fulfilledList: [...fulfilledList]
    });
  };

  // 设置loading状态
  setListLoading = () => {
    const { fulfilledList } = this.state;
    const { supplyPlatform, agencyAuthStatus, status } = this.props;
    fulfilledList.forEach(item => {
      item.childrens.forEach(child => {
        if (child.is_black_list === 2) {
          return;
        }
        if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }
        if(status === 3 && agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status) {
          return;
        }
        if (
          this.allCheckedIds.includes(child.id) &&
          child.markAsFulfilledChecked
        ) {
          child.markAsFulfilledFeedback = 'loading';
        }
      });
    });

    this.setState({
      fulfilledList: [...fulfilledList]
    });
  };

  // 设置结果状态
  setFeedback = (i, code) => {
    const { fulfilledList } = this.state;
    let result = true;
    const { supplyPlatform, agencyAuthStatus, status } = this.props;
    if (code != 2000 && code != 2010) {
      result = false;
      this.progressStatus = 'exception';
    }

    fulfilledList[i].childrens.forEach(child => {
      if (child.is_black_list === 2) {
        return;
      }
      if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
        return;
      }
      if(status === 3 && agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status) {
        return;
      }
      if (
        this.allCheckedIds.includes(child.id) &&
        child.markAsFulfilledChecked
      ) {
        child.markAsFulfilledFeedback = result
          ? 'check-circle'
          : 'close-circle';
      }
    });

    this.setState({
      fulfilledList: [...fulfilledList],
      progressStatus: this.progressStatus
    });
  };

  progressStatus = 'success';
  submitFulfilledList = null;
  hasSetListLoading = false;
  fulfilledOverSucc = false;
  hasSave = false;
  markFulfilledFlag = false; // 发送数据过程中禁止再次点击按钮

  modals = {}

  getModalConfirmTitle = () => {
    return <div className='modalConfirmDom_title'>
      <span className='modalConfirmDom_title_one'>{intl.get('order.confirm_modal_up.fulfill_title')}</span>
      <span style={{cursor: 'pointer'}} className='modalConfirmDom_title_icon' onClick={this.closeModalConfirm}><Icon type="close" /></span>
    </div>
  }

  closeModalConfirm = () => {
    this.modals.destroy()
  }


  // 提交
  markMultipleFulfilledTrue = () => {

    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'mark_as_fulfilled',
    })

    this.modals = Modal.confirm({
      className:'modalConfirmDom',
      icon:'null',
      title: this.getModalConfirmTitle(),
      content: intl.getHTML('order.agency_orders.agency_fulfill_tip'),
      okText: intl.get('order.confirm_modal_up.fulfill_confirm'),
      cancelText: intl.get('public.cancel'),
      onOk: () => {
        this.markFulfilledFlag = true;
        this.subFulfilled();
      }
    });
  };

  subFulfilled = () => {
    const { fulfilledList } = this.state;
    const i = 0;

    this.hasSave = true;
    this.showProgress = true;
    this.submitFulfilledList = cloneDeep(fulfilledList);
    this.submitSave(i);
  };

  // basMap 哪个sp可以fulfilled
  getWhichSpCanFulfilled = child => {
    const { sp = [] } = child;

    const { status } = this.props;

    let n = 0;
    let flag = false;

    if (sp.length == 2) {
      sp.forEach((item, i) => {
        if ([4, 3, 7].includes(item.status)) {
          n = i;
          flag = true;
        }
        if (item.status == status && item.is_default && !flag) {
          n = i;
          flag = true;
        }
        if (item.status == status && !item.is_default && !flag) {
          n = i;
        }
        if (!item.is_default && item.platform_order_id) {
          flag = true;
          n = i;
        }
      });
    }

    return n;
  };

  // 提交保存
  submitSave = i => {
    const { status, supplyPlatform, agencyAuthStatus } = this.props;
    const data = this.submitFulfilledList[i];
    const arr = data.ext.fulfillments || [];
    let flag = false;

    data.childrens.forEach((child, ci) => {
      if (child.is_black_list === 2) {
        return;
      }

      if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
        return;
      }

      if(status === 3 && agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status) {
        return;
      }

      if (
        this.allCheckedIds.includes(child.id) &&
        child.markAsFulfilledChecked
      ) {
        flag = true;
        if (data.ext.lineitems[ci].sp) {
          data.ext.lineitems[ci].sp.forEach((sp, si) => {
            if (child.supplierMapType == 1) {
              const basSpI = this.getWhichSpCanFulfilled(child);
              if (basSpI == si && sp.status == status) {
                sp.status = 5;
              }
            } else {
              sp.status = 5;
            }
          });
        }

        data.ext.lineitems[ci].status = 2048;
        
        // 2022/10/21新增字段 为了兼容agency与tmall/ae的筛选冲突
        data.ext.lineitems[ci].is_manual_fulfill = true;

        if (!arr.includes(data.ext.lineitems[ci].lineitem_id)) {
          arr.push(data.ext.lineitems[ci].lineitem_id);
        }
      }
    });

    if (!flag) {
      this.setPercent(i);
      if (this.submitFulfilledList.length > i + 1) {
        this.submitSave(i + 1);
      }
      if (this.submitFulfilledList.length == i + 1) {
        this.fulfilledOverSucc = true;
        this.markFulfilledFlag = false;
      }
      return;
    }

    if (arr.length === 0) {
      data.ext.fulfillment_status = 0;
    } else if (arr.length === data.ext.lineitems.length) {
      data.ext.fulfillment_status = 2;
    } else {
      data.ext.fulfillment_status = 1;
    }
    data.ext.fulfillments = arr;
    const { originalOldOrderMap  } = this.props;
    const originalExt = originalOldOrderMap[data.id];
    const submitExt = filterUpdateExtLineItems(supplyPlatform, data.ext, originalExt);

    if (!this.hasSetListLoading) {
      this.setListLoading();
      this.hasSetListLoading = true;
    }
    window.UPDATA_EXT = true
    window.UPDATA_EXT_TIME = +new Date()
    this.props.dispatch({
      type: 'order/updataExt',
      payload: {
        data: {
          order_id: data.id,
          ext: submitExt,
          shopify_fulfill: this.state.checkSp,
          store_id: data.storeId,
          type: 'fulfilled'
        },
        callback: d => {
          this.setPercent(i);
          if (this.submitFulfilledList.length > i + 1) {
            this.submitSave(i + 1);
          }
          if (this.submitFulfilledList.length == i + 1) {
            this.fulfilledOverSucc = true;
            this.markFulfilledFlag = false;
          }
          this.setFeedback(i, d.code);
        }
      }
    });
  };

  // 设置进度
  setPercent = i => {
    i += 1;
    this.progressNum = Math.ceil((i / this.submitFulfilledList.length) * 100);
  };

  onChangeSp = e => {
    this.setState({
      checkSp: e.target.checked
    });
  };

  render() {
    const { status, hideFulfilledModal, okLoading } = this.props;
    const {
      checkedAll,
      checkedSome,
      fulfilledList,
      progressStatus,
      isSpCancel
    } = this.state;

    return (
      <Modal
        visible
        width={1000}
        title={intl.get('order.fulfill_confirm.title')}
        footer={false}
        maskClosable={false}
        wrapClassName={orderProductsStyles.abcd}
        onCancel={() => hideFulfilledModal(this.hasSave)}
        destroyOnClose={true}
      >
        <div className={orderProductsStyles.abcd_tableBox}>
          <table>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    className={orderStyles.confirmCheckbox}
                    checked={checkedAll}
                    onChange={this.setFulfilledListCheckedAll}
                    indeterminate={checkedSome}
                  />
                </th>
                <th>{intl.get('order.fulfill_confirm.table_title.order')}</th>
                <th style={{ width: 200, textAlign: 'left', paddingLeft: 13 }}>
                  {intl.get('order.fulfill_confirm.table_title.product')}
                </th>
                <th>
                  {intl.get(
                    'order.search_type.agency_order_no'
                  )}
                </th>
                <th>
                  {intl.get('order.fulfill_confirm.table_title.feedback')}
                </th>
              </tr>
            </thead>
            <tbody>{this.readerFulfilledList(fulfilledList, status)}</tbody>
          </table>
          <div className={orderProductsStyles.footerBox}>
            {isSpCancel ? (
              <div className={orderProductsStyles.first_checkbox}>
                <Checkbox
                  checked={this.state.checkSp}
                  onChange={this.onChangeSp}
                />
                <span style={{ marginLeft: 10 }}>
                  {intl.get('order.fulfill_confirm.checkTitle')}
                </span>
              </div>
            ) : null}
            <div className={orderProductsStyles.ordrNum}>
              {this.checkedLength}&nbsp;
              {intl.get('order.fulfill_confirm.item', {
                num: this.checkedLength
              })}
            </div>
          </div>
        </div>
        {this.showProgress ? (
          <Progress percent={this.progressNum} status={progressStatus} />
        ) : null}
        <div className={orderProductsStyles.abcd_btnBox}>
          {this.fulfilledOverSucc ? (
            <button
              style={{ width: 116 }}
              onClick={() => hideFulfilledModal(this.hasSave)}
            >
              {intl.get('public.ok')}
            </button>
          ) : (
            <Button
              data="dx"
              disabled={
                !this.checkedLength || okLoading || this.markFulfilledFlag
              }
              onClick={this.markMultipleFulfilledTrue}
            >
              {intl.get('order.fulfill_confirm.mark_as_fulfilled')}
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}
