import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Icon } from 'antd';
import intl from 'react-intl-universal';

import { sleep } from 'routes/AgencyOrder/util';
import DSersAlert from '../DSersAlert';
import { getTasks, setTasks } from './utils';
import styles from './RollingDownloadResult.less';
import { AGENCY_EVENT_CHECK_UPLOAD_ORDER_TASK } from '../constants';
import { Get } from 'utils/request';

const configs = {
  uploadUrl: {
    3: '/ord/supply/action/payment/import',
    4: '/ord/supply/action/tracking/import'
  }
};

const getTaskInfo = async ({ taskId, type, status }) => {
  try {
    const res = await Get(configs.uploadUrl[type], { data: { id: taskId } });
    if (res.code === 2000) {
      return res.data;
    }
  } catch (error) {
    return null;
  }
};

const alertTypeMap = {
  1: 'info',
  2: 'success',
  3: 'error'
};

const RollingUploadResult = ({getOrderList}) => {
  const [allTasks, setAllTasks] = useState([]);

  const tasksRef = useRef(allTasks);

  const unmountRef = useRef(false);

  const checkTask = async () => {
    // 还有任务在执行 不再重复执行
    if (tasksRef.current?.find(i => ![2, 3].includes(i.status))) {
      return;
    }
    // 获取本地缓存的任务
    let cacheTasks = getTasks();
    // 同步到组件数据
    tasksRef.current = cacheTasks;

    setAllTasks(cacheTasks);

    // 过滤需要跑任务的数据
    cacheTasks = cacheTasks.filter(i => ![2, 3].includes(i.status));

    // setTasks(cacheTasks);
    while (cacheTasks.length && !unmountRef.current) {
      const taskRes = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const task of cacheTasks) {
        // eslint-disable-next-line no-await-in-loop
        const taskInfo = await getTaskInfo({
          taskId: task.id,
          type: task.type
        });
        if (taskInfo?.id) {
          taskRes.push(taskInfo);
        }
      }
      const processingTasks = tasksRef.current;

      processingTasks.forEach(i => {
        const resObj = taskRes.find(res => res?.id === i.id);
        if (resObj) {
          Object.assign(i, resObj);
        }
      });

      const currentCachedTasks = getTasks();

      currentCachedTasks.forEach(i => {
        const exsits = processingTasks.find(
          processingTask => processingTask.id === i.id
        );
        if (!exsits) {
          processingTasks.push(i);
        }
      });

      tasksRef.current = processingTasks;
      setAllTasks([...processingTasks]);
      setTasks(processingTasks);

      cacheTasks = processingTasks.filter(i => ![2, 3].includes(i.status));
      if (cacheTasks.length === 0) {
        setTasks([]);
        break;
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(5 * 1000);
    }

    // for()
  };
  useEffect(() => {
    checkTask();
    window.EVENT.on(AGENCY_EVENT_CHECK_UPLOAD_ORDER_TASK, checkTask);
    return () => {
      window.EVENT.off(AGENCY_EVENT_CHECK_UPLOAD_ORDER_TASK, checkTask);
      unmountRef.current = true;
    };
  }, []);

  const taskStatus = useMemo(() => {
    // return 1
    if (!allTasks.length) return 0;
    if (allTasks.find(i => ![2, 3].includes(i.status))) {
      return 1;
    }
    if (allTasks.find(i => i.status === 3)) return 3;
    return 2;
  }, [allTasks]);

  useEffect(() => {
    if(taskStatus > 1) {
      getOrderList && getOrderList();
    }
  }, [taskStatus]);


  const handleClickErrorLink = () => {
    // console.log('download error items');
  };

  const alertMessage = useMemo(() => {
    if (taskStatus === 1) {
      return (
        <div className={styles.rollingMessage}>
          <Icon style={{ fontSize: 24, marginRight: 16 }} type="sync" spin />
          {intl.get('order.agency_orders.uploading')}
        </div>
      );
    }
    if (taskStatus === 2) {
      return (
        <div className={styles.rollingMessage}>
          {intl.get('order.agency_orders.upload_success')}
        </div>
      );
    }
    if (taskStatus === 3) {
      return (
        <div className={styles.rollingMessage}>
          <div>{intl.get('order.agency_orders.upload_failed')}</div>
          <a
            role="button"
            tabIndex="-1"
            style={{ marginLeft: 16 }}
            target="_blank"
            download={allTasks[0]?.file_name}
            href={allTasks[0]?.file_url}
            // onClick={handleDownload}
          >
            {intl.get('order.agency_orders.upload_failed_reason')}
          </a>
          {/* <a role="button" tabIndex="-1" onClick={handleClickErrorLink}>
            {intl.get('order.agency_orders.upload_failed_reason')}
          </a> */}
        </div>
      );
    }
  }, [taskStatus]);

  const handleClose = () => {
    // closable自带过度效果 延迟执行等过度完毕
    setTimeout(() => {
      setAllTasks([]);
    }, 300);
  };

  console.log(taskStatus, '------------ task status');
  return (
    <DSersAlert
      closable={taskStatus !== 1}
      onClose={handleClose}
      showIcon={taskStatus !== 1}
      visible={taskStatus !== 0}
      type={alertTypeMap[taskStatus]}
      description={alertMessage}
      className={styles.globalAlert}
    />
  );
};

export default React.memo(RollingUploadResult);
