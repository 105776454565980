import React, { useState, useMemo } from 'react';
import { Modal, Input, Button } from 'antd';
import intl from 'react-intl-universal';
import { connect } from 'dva';

import styles from './MarkAsCommon.less';
import { getLanguage } from 'utils/utils';
import { supplyPlatformEquals } from './util';

const langConfirmValueMap = {
  'en-US': 'CONFIRM',
  'fr-FR': 'Confirmer',
  'pt-PT': 'Confirmar',
  'de-DE': 'Bestätigen',
  'it-IT': 'CONFERMARE',
  'es-ES': 'CONFIRMAR'
};

const MarkAsPaidConfirmContent = ({
  data,
  childId,
  listData,
  dispatch,
  loading,
  confirmLoading,
  setDisableClose,
  disableClose,
  onClose,
  agencyAuthStatus,
  status
}) => {
  const [inputValue, setInputValue] = useState('');
  const confirmDisabled = useMemo(() => {
    if (!inputValue) return true;
    const lang = getLanguage().replace('_', '-');
    return (
      langConfirmValueMap[lang].toLowerCase() !== inputValue?.toLowerCase()?.trim()
    );
  }, [inputValue]);

  const showConfirmError = useMemo(() => {
    return confirmDisabled && inputValue;
  }, [confirmDisabled, inputValue]);

  const handleConfirm = async () => {
    if (disableClose) {
      return;
    }
    setDisableClose(true);

    try {
      const actionOrders = data;
      const markAsPaidSupplyOrderIds = [];
      const orderNumberMap = {};
      // console.log(markAsPaidSupplyOrderIds, actionOrders, childId);
      actionOrders.forEach(orderOne => {
        orderOne.childrens.forEach(child => {
          if (child.is_black_list === 2) {
            return;
          }

          if(childId && childId !== child.id) {
            return;
          }

          if(agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status && status === 3) {
            return;
          }

          if (supplyPlatformEquals(child.platform_type, 5)) {
            const spItem = child.sp[0];
            if (spItem?.platform_order_id && spItem?.status === 3) {
              if (!orderNumberMap[spItem?.platform_order_id]) {
                orderNumberMap[spItem?.platform_order_id] = true;
                markAsPaidSupplyOrderIds.push({
                  supplyOrderId: spItem?.platform_order_id,
                  dsersStoreId: child.storeId
                });
              }
            }
          }
        });
      });
      console.log(markAsPaidSupplyOrderIds, actionOrders, childId);
      if (!markAsPaidSupplyOrderIds.length) throw {msg: 'no order to mark as paid'};

      for (const {supplyOrderId, dsersStoreId} of markAsPaidSupplyOrderIds) {
        const res = await new Promise((resolve, reject) => {
          dispatch({
            type: 'order/markAgencyOrderAsPaidAction',
            payload: {
              data: { order_id: supplyOrderId, store_id: dsersStoreId },
              callback: resolve,
              exceptionFn: reject,
            }
          });
        });
      }
    } catch (error) {
      console.log(error);
    }

    setDisableClose(false);
    onClose(true);
  };
  return (
    <div>
      <div className={styles.contentLine}>
        {intl.get('order.agency_orders.mark_as_paid_desc')}
      </div>
      <div className={styles.contentLine}>
        <Input
          placeholder={intl.get('order.agency_orders.please_input_confirm')}
          onChange={e => setInputValue(e.target.value)}
        />
        {showConfirmError? <div style={{color: 'red', fontSize: 12}}>{intl.get('order.agency_orders.please_input_confirm')}</div> : null}
      </div>
      <div className={styles.operation}>
        {/* <Button data="dx" onClick={onClose}>
          {intl.get('order.order_product.shipping_dialog.cancel')}
        </Button> */}
        <Button
          data="dx"
          disabled={confirmDisabled}
          onClick={handleConfirm}
          type="primary"
          loading={disableClose || loading || confirmLoading}
        >
          {intl.get('order.agency_orders.upload_confirm')}
        </Button>
      </div>
    </div>
  );
};

const MarkAsPaidConfirm = ({ visible, onClose, ...restProps }) => {
  const [disableClose, setDisableClose] = useState(false);
  const handleClose = (canClose = false) => {
    const shouldClose = canClose === true || !disableClose;
    if (shouldClose) {
      onClose(true);
    }
  };
  return (
    <Modal
      destroyOnClose
      footer={null}
      title={intl.get('order.agency_orders.mark_as_paid_title')}
      visible={visible}
      width={580}
      onCancel={handleClose}
    >
      <MarkAsPaidConfirmContent
        disableClose={disableClose}
        setDisableClose={setDisableClose}
        {...restProps}
        onClose={handleClose}
      />
    </Modal>
  );
};

export default connect(({ loading }) => ({
  loading: loading.models.order,
  confirmLoading: loading.effects['order/markAgencyOrderAsPaidAction']
}))(MarkAsPaidConfirm);
