import React from 'react';
import { connect } from 'dva';
import { Icon, Tooltip,Button } from 'antd';

import intl from 'react-intl-universal';
import round from 'lodash/round';
import reactGaEvent from '../../utils/GAEvent';

import { OrderAction,GuildeAction } from 'actions'
import { currencySymbolMap } from '../../common/sysconfig';
import { getRates } from '../../services/user'
import orderStyles from './Order.less';
import { supplyPlatformEquals } from './util';
import { IconButton } from './component/IconButton';

@connect(({ global,login,user }) => ({
  storeCurrency: global.storeCurrency,
  currencyState: global.currencyState,
  customizeCurrency: login.customizeCurrency,
  newUserInfo:user.newUserInfo,
}))
export default class OrderFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyRatesY: '',
      currencyRatesX: ''
    };
  }

  componentWillUnmount() {
    clearTimeout(this.hoverTimer);
  }

  // 获取订单是不是纯other单
  getIsAllOther = listData => {
    const { childrens = [] } = listData;

    let flag = true;

    childrens.forEach(child => {
      if (child.is_black_list === 2) {
        return;
      }
      if (child.platformType != 1) {
        flag = false;
      }
    });

    return flag;
  };

  currencyRatesY;

  componentDidMount() {
    const {
      listData,
      status,
      storeCurrency,
      listData: { storeId, fulfillStatus },
      currencyState
    } = this.props;

    // console.log('currencyState', currencyState)
  }

  // 获取订单商品成本
  getProductCost = (listData, status) => {
    let n = 0;

    listData.childrens.forEach(child => {
      if (child.is_black_list === 2 || !child.sp) {
        return;
      }
      if (child.supplierMapType == 1) {
        if (child.sp.length == 1) {
          if (child.sp[0].status == status) {
            n += child.sp[0].cost * child.sp[0].quantity;
          }
          if (status == 9 && child.sp[0].logistics_infos) {
            n += child.sp[0].cost * child.sp[0].quantity;
          }
        }
        if (child.sp.length == 2) {
          if (child.useSp.status == status) {
            n += child.useSp.cost * child.useSp.quantity;
          } else {
            child.sp.forEach(sp => {
              if (sp.status == status) {
                n += sp.cost * sp.quantity;
              }
              if (status == 9 && sp.logistics_infos) {
                n += sp.cost * sp.quantity;
              }
            });
          }
        }
      }

      if (child.supplierMapType == 2 && child.useSp.status == status) {
        n += child.useSp.cost * child.useSp.quantity;
      }

      if (child.supplierMapType > 2) {
        child.sp.forEach(sp => {
          if (sp.status == status) {
            n += sp.cost * sp.quantity;
          }
        });
      }

      if (status == 9 && child.supplierMapType > 1) {
        child.sp.forEach(sp => {
          if (sp.logistics_infos) {
            n += sp.cost * sp.quantity;
          }
        });
      }
    });

    return n;
  };

  // 获取订单物流成本
  getFreightCost = (listData, status) => {
    const {
      ext: { transactions = [] }
    } = listData;
    let n = 0;
    let aeOrderIds = new Set();

    listData.childrens.forEach(child => {
      if (child.is_black_list === 2 || !child.sp) {
        return;
      }
      if (child.supplierMapType == 1) {
        if (child.sp.length == 1) {
          if (child.sp[0].status == status) {
            if (child.sp[0].platform_order_id) {
              aeOrderIds.add(child.sp[0].platform_order_id);
            } else {
              n = n + child.sp[0].freight_cost * 1 || 0;
            }
          }
          if (status == 9 && child.sp[0].logistics_infos) {
            if (child.sp[0].platform_order_id) {
              aeOrderIds.add(child.sp[0].platform_order_id);
            } else {
              n = n + child.sp[0].freight_cost * 1 || 0;
            }
          }
        }
        if (child.sp.length == 2) {
          if (child.useSp.status == status) {
            if (child.useSp.platform_order_id) {
              aeOrderIds.add(child.useSp.platform_order_id);
            } else {
              n = n + child.useSp.freight_cost * 1 || 0;
            }
          } else {
            child.sp.forEach(sp => {
              if (sp.status == status) {
                if (sp.platform_order_id) {
                  aeOrderIds.add(sp.platform_order_id);
                } else {
                  n = n + sp.freight_cost * 1 || 0;
                }
              }
              if (status == 9 && sp.logistics_infos) {
                if (sp.platform_order_id) {
                  aeOrderIds.add(sp.platform_order_id);
                } else {
                  n = n + sp.freight_cost * 1 || 0;
                }
              }
            });
          }
        }
      }

      if (child.supplierMapType == 2 && child.useSp.status == status) {
        if (child.useSp.platform_order_id) {
          aeOrderIds.add(child.useSp.platform_order_id);
        } else {
          n = n + child.useSp.freight_cost * 1 || 0;
        }
      }

      if (child.supplierMapType > 2) {
        child.sp.forEach(sp => {
          if (sp.status == status) {
            if (sp.platform_order_id) {
              aeOrderIds.add(sp.platform_order_id);
            } else {
              n = n + sp.freight_cost * 1 || 0;
            }
          }
        });
      }

      if (status == 9 && child.supplierMapType > 1) {
        child.sp.forEach(sp => {
          if (sp.logistics_infos) {
            if (sp.platform_order_id) {
              aeOrderIds.add(sp.platform_order_id);
            } else {
              n = n + sp.freight_cost * 1 || 0;
            }
          }
        });
      }
    });

    aeOrderIds = [...aeOrderIds];

    transactions.reverse();

    aeOrderIds.forEach(item => {
      let cost = 0;
      transactions.some(record => {
        if (record.order_info && record.order_info[item]) {
          const {
            order_info: {
              [item]: {
                child_order_list: { aeop_child_order_info = [] } = {},
                order_amount: { amount } = {}
              } = {}
            } = {}
          } = record;

          aeop_child_order_info.forEach(info => {
            const {
              product_count = 1,
              product_price: { amount = 0 } = {}
            } = info;
            cost += product_count * amount;
            cost = cost.toFixed(2) * 1;
          });

          if (amount) {
            cost = (amount - cost).toFixed(2) * 1;
          }

          return true;
        }
        return false;
      });
      n += cost;
    });

    return n;
  };

  getAllConst = (listData, status) => {
    // 获取订单所有成本
    let n = 0;
    n =
      this.getProductCost(listData, status) +
      this.getFreightCost(listData, status);
    n = n < 0 ? 0 : n;
    return n;
  };

  getTotalCost = (listData, status) => {
    let totalCost = 0;
    let totalShippingCost = 0;
    let totalProductCost = 0;
    let countToTotalProductCost = 0;
    let countToTotalShippingCost = 0;
    const {
      ext: { transactions = [] },
      AgencyOrders,
      agencyShippingCost
    } = listData;
    const { supplyPlatform, agencyAuthStatus } = this.props;
    // let n = 0;
    // 需要使用transaction order 价格的order id 需要去重
    // 没有order id或者已取消订单的不再使用订单价格
    const successAeTransactions = {};
    const successAgencyOrders = {};

    const aeOrderExtPriceInfo = {};

    let costCurrency = 'USD';

    if([1, 2, 6, 7].includes(status)) {
      totalShippingCost += parseFloat(agencyShippingCost) || 0;
    }

    countToTotalShippingCost = totalShippingCost;

    AgencyOrders?.forEach(i => {
      if(i.Status === 5) {
        return;
      }
      successAgencyOrders[i.Id] = {
        ...i,
        manuallyUploadShippingCost: !!i?.Items?.find(item => {return item.IsImportPayment}),
      }
    })


    listData.childrens.forEach(child => {
      if (child.is_black_list === 2 || !child.sp) {
        return;
      }
      if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
        return;
      }

      if(agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status && status === 3) {
        return;
      }


      // bogo、bundle mapping
      if (child.supplierMapType > 2) {
        child.sp.forEach(sp => {
          if (sp.status == status) {
            
            const { freight_cost, cost, quantity } = sp;
            totalProductCost = totalProductCost + cost * quantity;
            const agencyOrderInfo = successAgencyOrders[sp.platform_order_id];

            if(!agencyOrderInfo) {
              countToTotalProductCost = countToTotalProductCost + cost * quantity;
              if(![1, 2, 6, 7].includes(sp.status)) {
                countToTotalShippingCost = countToTotalShippingCost + freight_cost * 1 || 0;
              }
            }

            if(agencyOrderInfo && !agencyOrderInfo.manuallyUploadShippingCost && !agencyOrderInfo.counted) {
              console.log(successAgencyOrders[sp.platform_order_id])
              totalShippingCost = totalShippingCost + (agencyOrderInfo.ShippingFee * 1 || 0);
              agencyOrderInfo.counted = true;
              totalCost = totalCost + (agencyOrderInfo.OrderFee * 1 || 0);
            } else if(agencyOrderInfo && !agencyOrderInfo.counted && agencyOrderInfo.manuallyUploadShippingCost){
              totalShippingCost = totalShippingCost + freight_cost * 1 || 0;
            }
            

            if(child.lineItemAgencyPaymentCurrency) {
              costCurrency = child.lineItemAgencyPaymentCurrency;
            }

          }
        });
      }

      // 处理高级mapping的awating fulfillment
      if (status === 9 && child.supplierMapType > 1) {
        child.sp.forEach(sp => {
          if (sp.logistics_infos) {
            const { freight_cost, cost, quantity } = sp;
            totalProductCost = totalProductCost + cost * quantity;

            const agencyOrderInfo = successAgencyOrders[sp.platform_order_id];

            if(!agencyOrderInfo) {
              countToTotalProductCost = countToTotalProductCost + cost * quantity;
              if(![1, 2, 6, 7].includes(sp.status)) {
                countToTotalShippingCost = countToTotalShippingCost + freight_cost * 1 || 0;
              }
              
            }

            if(agencyOrderInfo && !agencyOrderInfo.manuallyUploadShippingCost && !agencyOrderInfo.counted) {
              console.log(successAgencyOrders[sp.platform_order_id])
              totalShippingCost = totalShippingCost + (agencyOrderInfo.ShippingFee * 1 || 0);
              totalCost = totalCost + (agencyOrderInfo.OrderFee * 1 || 0);
              agencyOrderInfo.counted = true;
            } else if(agencyOrderInfo && !agencyOrderInfo.counted && agencyOrderInfo.manuallyUploadShippingCost){
              totalShippingCost = totalShippingCost + freight_cost * 1 || 0;
            }
              
            
          }
        });
      }
    });

    console.log('计算前的价格', totalProductCost);

    // 插件下单可展示税费及与AE一致的商品物流费用数值
    // 目前只有vat订单有这个值 - 来自插件订单cost
    // let showExtPrice = false;
    // let hintValue = 0;
    // let hasHintValue = false;
    // // 税费 - 来自插件订单cost
    // let tax = 0;
    // // 支付总额 - 来自插件订单cost
    // let extTotal = 0;
    // // 商品总价 - 来自插件订单cost
    // let extProdTotal = 0;
    // // 物流总价 - 来自插件订单cost
    // let extShippingTotal = 0;
    // // 其他优惠券等总额 - 来自插件订单cost
    // let extOtherTotal = 0;
    let otherTotal = 0;

    totalCost = totalCost + countToTotalProductCost + countToTotalShippingCost;

    otherTotal = totalCost - totalShippingCost - totalProductCost;

    // console.log('totalProductCost, totalShippingCost, totalCost', totalProductCost, totalShippingCost, totalCost)

    return [
      totalProductCost,
      totalShippingCost,
      totalCost,
      {
        // hasHint: 0,
        // hintValue: 0,
        // tax: 0,
        other: otherTotal,
        // showExtPrice
      },
      costCurrency
    ];
  };

  // 是否显示 order again
  getShowOrderAgain = (listdata, status) => {
    let flag = false;

    if (listdata.isShopifyDelete) {
      return flag;
    }

    listdata.childrens.forEach(item => {
      const { AECanceled = [] } = item;
      if (item.is_black_list === 2) {
        return;
      }

      if(item.platform_type !== 5) {
        return;
      }

      if(item.shopifyFulfillStatus) {
        return;
      }

      AECanceled.forEach(b => {
        if (b) {
          flag = true;
        }
      });
    });

    return flag;
  };

  // 下单
  savePlaceOrder = () => {
    // blerlo  未绑定AE用户 -点击Order （已经有逻辑不重复展示）
    // if (!window.IS_BIND_AE) {
    //   window.EVENT.emit(
    //     GuildeAction.OblerloGuide_Pop,
    //     `AE-${Date.now()}`,
    //     true
    //   );
    //   return;
    // }

    const { listData, placeOrder } = this.props;
    // if (!window.IS_BIND_AE) {
    //   window.EVENT.emit(OrderAction.IS_BIND_AE_MODAL, true);
    //   return;
    // }
    placeOrder(listData.id);
  };

  productCostTip = (
    <Tooltip
      placement="top"
      overlayClassName={orderStyles.productCostTip}
      title={
        <>
          <p className={orderStyles.dec}>
            {intl.get('order.order_foot.product_cost_tip.title')}
          </p>
          <p className={orderStyles.dec}>
            {intl.get('order.order_foot.product_cost_tip.p1')}
          </p>
          <p className={orderStyles.dec}>
            {intl.get('order.order_foot.product_cost_tip.p2')}
          </p>
          <p className={orderStyles.dec}>
            {intl.get('order.order_foot.product_cost_tip.p3')}
          </p>
          <p className={orderStyles.dec}>
            {intl.get('order.order_foot.product_cost_tip.p3_1')}
          </p>
          <p className={orderStyles.dec}>
            {intl.get('order.order_foot.product_cost_tip.p4')}
          </p>
          <p className={orderStyles.dec}>
            {intl.get('order.order_foot.product_cost_tip.p_end')}
          </p>
        </>
      }
    >
      <Icon
        type="question-circle"
        theme="outlined"
        className={orderStyles.productCostCircle}
        onMouseEnter={() => {
          this.sendGA();
        }}
      />
    </Tooltip>
  );

  hoverTimer = null;
  currencyRatesX = '';

  sendGA = () => {
    clearTimeout(this.hoverTimer);
    this.hoverTimer = setTimeout(() => {
      reactGaEvent({
        category: 'Order',
        action: 'Product cost '
      });
    }, 3000);
  };

  renderAgencyCost = ({
    productCostTotal,
    shippingTotal,
    totalCost,
    currencySymbol,
    currencyRates,
    isNotUSD,
    costCurrency
  }) => {
    const { supplyPlatform } = this.props;
    // console.log(supplyPlatform)
    // todo agency 不展示currency 后续可能会展示
    return (
      <>
        <span className={orderStyles.totalContent}>
          {intl.get('order.order_foot.cost')}:&nbsp;
          <b>{`${currencySymbolMap[costCurrency]}`}{productCostTotal ? productCostTotal.toFixed(2): ' -'}</b>
          {isNotUSD && false ? (
            <>
              <br />
              <span className={orderStyles.currencyTotalContent}>
                <>{`${currencySymbol}${productCostTotal? round(
                  productCostTotal * currencyRates,
                  2
                ): ' -'}`}</>
              </span>
            </>
          ) : null}
        </span>
        <span className={orderStyles.totalContent}>
          {intl.get('order.order_foot.shipping')}:&nbsp;
          <b>
            {`${currencySymbolMap[costCurrency]}`}{/* {shippingTotal.toFixed(2)} */}
            {shippingTotal ? shippingTotal.toFixed(2): ' -'}
          </b>
          {isNotUSD && false ? (
            <>
              <br />
              <span className={orderStyles.currencyTotalContent}>
                <>{`${currencySymbol}${shippingTotal? round(
                  shippingTotal * currencyRates,
                  2
                ): ' -'}`}</>
              </span>
            </>
          ) : null}
        </span>

        {/* Total cost:  */}
        <span className={orderStyles.totalContent}>
          {intl.get('order.order_foot.total')}:&nbsp;
          <b>{`${currencySymbolMap[costCurrency]}`}{totalCost ? totalCost.toFixed(2): ' -'}</b>
          {isNotUSD && false ? (
            <>
              <br />
              <span>
                <>{`${currencySymbol}${totalCost ? round(totalCost * currencyRates, 2): ' -'}`}</>
              </span>
            </>
          ) : null}
        </span>
      </>
    );
  };

  render() {
    const {
      listData,
      status,
      storeCurrency,
      listData: { storeId, fulfillStatus },
      currencyState,
      isClickOrder,
      isSavinglogic,
      supplyPlatform,
      customizeCurrency,
      newUserInfo
    } = this.props;

    let currencyCode;
    let currencySymbol;
    let isNotUSD;
    let currencyRates;
    let cancel;
    let flag;

    if (listData.ext?.transactions) {
      let length = listData.ext?.transactions.length;
      let transactionsSy = listData.ext?.transactions[length - 1];
      let len;
      let key;
      flag = true;
      if (transactionsSy?.order_info) {
        len = Object.keys(transactionsSy.order_info).length;
        key = Object.keys(transactionsSy.order_info)[len - 1];
        cancel = transactionsSy?.order_info[key];
      } else {
        flag = false;
      }
    }

    if (storeCurrency[storeId]) {
      currencyCode = storeCurrency[storeId]?.currency_code || 'USD';
      if ([3, 4, 9, 5, 6, 7].includes(status)) {
        if (
          typeof currencyState == 'object' &&
          currencyState.hasOwnProperty(currencyCode)
        ) {
          this.currencyRatesX =
            currencyState[currencyCode] &&
            currencyState[currencyCode]?.rates_value;
        }
      }

      currencySymbol = currencySymbolMap[currencyCode];
      isNotUSD = currencyCode !== 'USD';
    }
    if(customizeCurrency?.storeId == storeId && 
      customizeCurrency?.currency &&
      customizeCurrency?.id == newUserInfo.org_id){
       currencyRates = customizeCurrency.currency;
   }else{
       currencyRates = this.state.currencyRatesY || storeCurrency[storeId]?.rates_value;
   }
    const tabStatusArr3 = [2, 6, 7];

    // const productCostTotal = this.getProductCost(listData, status);
    // const shippingTotal = this.getFreightCost(listData, status);
    // const totalCost = this.getAllConst(listData, status);

    const [
      productCostTotal,
      shippingTotal,
      totalCost,
      { hasHint, hintValue, tax, other, showExtPrice } = {},
      costCurrency
    ] = this.getTotalCost(listData, status);

    const handleOnHoldBtn = (fulfillStatus, status) => {
      if (fulfillStatus == 4 && status == 9) {
        return (
          <Tooltip
            title={intl.get('order.order_onHold_snync_tip')}
            placement="topRight"
          >
            <button
              className={orderStyles.customBtnNew}
              type="primary"
              onClick={isSavinglogic}
            >
              {intl.get('order.order_onHold_info')}{' '}
              <span style={{ marginLeft: '3px' }}>
                <Icon
                  type="question-circle"
                  style={{ verticalAlign: 'middle', color: 'white' }}
                />
              </span>
            </button>
          </Tooltip>
        );
      }

      if (fulfillStatus == 5 && status == 9) {
        return (
          <button className={orderStyles.customBtnDisabled} type="primary">
            {intl.get('order.order_onHold_info_tip')}
          </button>
        );
      }
      return null;
    };
    return (
      <div className={orderStyles.orderCount}>
        {fulfillStatus == 1 ? (
          <p className={orderStyles.fullfillBtn}>Awaiting fulfill</p>
        ) : null}
        <p className={orderStyles.authBtn}>
          {listData.platfrom_financial_status == 'authorized' && status == 1 ? (
            <Tooltip
              title={intl.get('order.order_foot.pending_authorized_tip')}
            >
              <span className={orderStyles.orderMoreStatusIco}>Authorized</span>
            </Tooltip>
          ) : null}
          {listData.isShopifyDelete && [2, 5, 6].includes(status) ? (
            <span className={orderStyles.orderIsShopifyDelete}>
              {intl.get('order.order_foot.shopify_deleted')}
              <Tooltip title={intl.get('order.order_foot.is_shopify_delete')}>
                <Icon type="question-circle" />
              </Tooltip>
            </span>
          ) : null}
          {this.getIsAllOther(listData) || supplyPlatform === 5 ? (
            supplyPlatform !== 5 ? (
              <>
                <span className={orderStyles.totalContent}>
                  {intl.get('order.order_foot.cost')}: <b>$ --</b>&nbsp;
                  {this.productCostTip}
                </span>
                <span className={orderStyles.totalContent}>
                  {intl.get('order.order_foot.shipping')}: <b>$ --</b>
                </span>
                <span className={orderStyles.totalContent}>
                  {intl.get('order.order_foot.total')}: <b>$ --</b>
                </span>
              </>
            ) : (
              this.renderAgencyCost({
                productCostTotal,
                shippingTotal,
                totalCost,
                currencyRates,
                currencySymbol,
                isNotUSD,
                costCurrency
              })
            )
          ) : (
            <>
              <span className={orderStyles.totalContent}>
                {intl.get('order.order_foot.cost')}:&nbsp;
                <b>${productCostTotal.toFixed(2)}</b>
                {this.productCostTip}
                {isNotUSD ? (
                  <>
                    <br />
                    <span className={orderStyles.currencyTotalContent}>
                      <>{`${currencySymbol}${round(
                        productCostTotal * currencyRates,
                        2
                      )}`}</>
                    </span>
                  </>
                ) : null}
              </span>
              <span className={orderStyles.totalContent}>
                {intl.get('order.order_foot.shipping')}:&nbsp;
                <b>
                  ${/* {shippingTotal.toFixed(2)} */}
                  {shippingTotal.toFixed(2)}
                </b>
                <Tooltip
                  placement="top"
                  title={
                    <span>
                      {!showExtPrice
                        ? intl.getHTML('order.order_foot.shipping_tip')
                        : intl.get('order.order_foot.shipping_tip_ext')}
                    </span>
                  }
                >
                  <Icon
                    type="question-circle"
                    theme="outlined"
                    style={{
                      marginLeft: 2,
                      transform: 'scale(.9)'
                    }}
                  />
                </Tooltip>
                {isNotUSD ? (
                  <>
                    <br />
                    <span className={orderStyles.currencyTotalContent}>
                      <>{`${currencySymbol}${round(
                        shippingTotal * currencyRates,
                        2
                      )}`}</>
                    </span>
                  </>
                ) : null}
              </span>

              {/* Tax cost:  */}
              {status !== 1 ? (
                <span className={orderStyles.totalContent}>
                  {intl.get('order.order_foot.tax_title')}:&nbsp;
                  {showExtPrice ? <b>${tax.toFixed(2)}</b> : <b>-</b>}
                  <Tooltip
                    placement="top"
                    title={
                      <span>{intl.getHTML('order.order_foot.tax_tip')}</span>
                    }
                  >
                    <Icon
                      type="question-circle"
                      theme="outlined"
                      style={{
                        marginLeft: 2,
                        transform: 'scale(.9)'
                      }}
                    />
                  </Tooltip>
                  {isNotUSD ? (
                    <>
                      <br />
                      <span>
                        <>
                          {showExtPrice
                            ? `${currencySymbol}${round(
                                tax * currencyRates,
                                2
                              )}`
                            : '-'}
                        </>
                      </span>
                    </>
                  ) : null}
                </span>
              ) : null}

              {/* Other cost:  */}
              {status !== 1 ? (
                <span className={orderStyles.totalContent}>
                  {intl.get('order.order_foot.other_title')}:&nbsp;
                  {showExtPrice ? <b>${other.toFixed(2)}</b> : <b>-</b>}
                  <Tooltip
                    placement="top"
                    title={
                      <span>{intl.get('order.order_foot.other_tip_ext')}</span>
                    }
                  >
                    <Icon
                      type="question-circle"
                      theme="outlined"
                      style={{
                        marginLeft: 2,
                        transform: 'scale(.9)'
                      }}
                    />
                  </Tooltip>
                  {isNotUSD ? (
                    <>
                      <br />
                      <span>
                        <>
                          {showExtPrice
                            ? `${currencySymbol}${round(
                                other * currencyRates,
                                2
                              )}`
                            : '-'}
                        </>
                      </span>
                    </>
                  ) : null}
                </span>
              ) : null}

              {/* Total cost:  */}
              <span className={orderStyles.totalContent}>
                {intl.get('order.order_foot.total')}:&nbsp;
                <b>${totalCost.toFixed(2)}</b>
                {hasHint ? (
                  <Tooltip
                    placement="top"
                    title={<span>VAT included(${hintValue?.toFixed(2)})</span>}
                  >
                    <Icon
                      type="question-circle"
                      theme="outlined"
                      style={{
                        marginLeft: 2,
                        transform: 'scale(.9)'
                      }}
                    />
                  </Tooltip>
                ) : null}
                {isNotUSD ? (
                  <>
                    <br />
                    <span>
                      <>{`${currencySymbol}${round(
                        totalCost * currencyRates,
                        2
                      )}`}</>
                    </span>
                  </>
                ) : null}
              </span>
            </>
          )}
        </p>
        <div>
          {status == 6 ? (
            this.getShowOrderAgain(listData) ? (
              <button
                className={orderStyles.customBtn}
                onClick={e => {
                  this.savePlaceOrder(listData.id, 1, e);
                }}
              >
                {intl.get('order.order_foot.order_again')}
              </button>
            ) : null
          ) : tabStatusArr3.includes(status) ? (
            listData.canOrderDisabled ||
            listData?.bStatus == 1 ||
            listData?.bStatus == 2 ||
            !isClickOrder ? (
              <>
                <Tooltip
                  title={
                    <span>{intl.getHTML('order.order_foot.order_tip')}</span>
                  }
                  placement="left"
                >
                  <button
                    disabled={
                      listData.canOrderDisabled ||
                      listData?.bStatus == 1 ||
                      listData?.bStatus == 2 ||
                      !isClickOrder
                    }
                    data-label="order-item-save2"
                  >
                    {intl.get('order.order_foot.order')}
                  </button>
                </Tooltip>
              </>
            ) : (
              <button
                className={orderStyles.customBtn}
                onClick={this.savePlaceOrder}
                data-label="order-item-save"
              >
                {intl.get('order.order_foot.order')}
              </button>
            )
          ) : (
            handleOnHoldBtn(fulfillStatus, status)
          )}
          {status === 3 ? (
            <IconButton data="dx" onClick={() => this.props.makePayment(listData.id)}>
              {intl.get('order.make_payment.btn')}
            </IconButton>
          ) : null}
        </div>
      </div>
    );
  }
}
