import React from 'react';
import { Modal } from 'antd';
import MakePaymentAgencyBroup from './MakePaymentAgencyBroup';

const AgencyPaymentDetailModal = ({ visible, onClose, data }) => {
    const handleClose = () => {
      onClose && onClose();
    };
    return (
      <Modal
        destroyOnClose
        footer={null}
        title={'Payment Detail'}
        visible={visible}
        width={1000}
        onCancel={handleClose}
      >
        <MakePaymentAgencyBroup
          data={data?.structData || []}
          initPaymentAmount={data?.initPaymentAmount}
          onlyShowOrderDetail
        />
      </Modal>
    );
  };

export default React.memo(AgencyPaymentDetailModal);