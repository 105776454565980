import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback
} from 'react';
import { Modal, Input, Button } from 'antd';
import intl from 'react-intl-universal';
import { connect } from 'dva';

import { supplyPlatformEquals } from 'routes/AgencyOrder/util';
import MakePaymentAgencyBroup from './MakePaymentAgencyBroup';
import { getAgencyPaymentGroupsFromSelectedOrders } from './utils';
import RefreshBounced from 'components/RefreshBounced';
import InPaymentList from './InPaymentAgencyInfo';

const MakePaymentModal = ({
  data,
  dispatch,
  loading,
  confirmLoading,
  setDisableClose,
  disableClose,
  onClose,
  handleSaveStatusChange,
  agencyAuthStatus
}) => {
  const [agencyGroups, setAgencyGroups] = useState([]);
  const agencyGroupsRef = useRef([]);

  const [inPaymentList, setInPaymentList] = useState([]);
  const inPaymentListRef = useRef([]);

  const [refreshAgencyModal, setRefreshAgencyModal] = useState(false);

  useEffect(() => {
    const initedGroups = getAgencyPaymentGroupsFromSelectedOrders(data, agencyAuthStatus);
    console.log(initedGroups, 'agency 分组数据', data);
    setAgencyGroups(initedGroups);
    agencyGroupsRef.current = initedGroups;
  }, [data]);

  const showRefreshModal = useCallback(() => {
    setRefreshAgencyModal(true);
  }, []);

  const handleRefresh = useCallback(() => {
    dispatch({ type: 'global/getAgencyListFromMerchant' });
    setRefreshAgencyModal(false);
  }, []);

  const onPaymentStart = useCallback((inPaymentUIParams) => {
    const { agencyInfo, orders, amount, currency } = inPaymentUIParams;
    const oldAgencyGroups = agencyGroupsRef.current;
    const oldAgencyGroupIndex = oldAgencyGroups.findIndex(i => `${i.agency_id}` === `${agencyInfo.id}`);
    
    if(oldAgencyGroupIndex > -1) {
      const newAgencyGroups = [...oldAgencyGroups];
      const oldGroup = oldAgencyGroups[oldAgencyGroupIndex];
      const newGroupData = oldGroup.data.filter(i => !orders.includes(i.id));
      if(!newGroupData.length) {
        newAgencyGroups.splice(oldAgencyGroupIndex, 1);
      } else {
        newAgencyGroups[oldAgencyGroupIndex] = { ...newAgencyGroups[oldAgencyGroupIndex], data: newGroupData };
      }

      handleSaveStatusChange();

      if(!newAgencyGroups.length) {
        onClose(true);
      }

      // 设置支付表格数据
      setAgencyGroups(newAgencyGroups);
      agencyGroupsRef.current = newAgencyGroups;

      // 设置正在支付的信息
      const newInPaymentList = [...inPaymentListRef.current];
      newInPaymentList.push(inPaymentUIParams);
      setInPaymentList(newInPaymentList);
      inPaymentListRef.current = newInPaymentList;
    }
  }, []);

  return (
    <div>
      <RefreshBounced
        visible={refreshAgencyModal}
        handleRefresh={handleRefresh}
        buttonEvent={handleRefresh}
      />
      {inPaymentList.map((i, index) => {
        return <InPaymentList data={i} key={`${i.agencyInfo.id}_${index}`} />
      })}
      {agencyGroups?.map(i => {
        return (
          <MakePaymentAgencyBroup
            key={i.agency_id}
            data={i}
            showRefreshModal={showRefreshModal}
            onPaymentStart={onPaymentStart}
          />
        );
      })}
    </div>
  );
};

const MakePaymentConfirm = ({ visible, onClose, ...restProps }) => {
  const [disableClose, setDisableClose] = useState(false);
  const hasSaveRef = useRef(false);
  const handleClose = (canClose = false) => {
    const shouldClose = canClose === true || !disableClose;
    if (shouldClose) {
      onClose(hasSaveRef.current);
      hasSaveRef.current = false;
    }
  };
  return (
    <Modal
      destroyOnClose
      footer={null}
      title={intl.get('order.agency_orders.payment.payment_modal_title')}
      visible={visible}
      width={1000}
      onCancel={handleClose}
    >
      <MakePaymentModal
        disableClose={disableClose}
        setDisableClose={setDisableClose}
        {...restProps}
        onClose={handleClose}
        handleSaveStatusChange={() => {
          hasSaveRef.current = true;
        }}
      />
    </Modal>
  );
};

export default connect(({ loading }) => ({
  loading: loading.models.order,
  confirmLoading: loading.effects['order/markAgencyOrderAsPaidAction']
}))(MakePaymentConfirm);
