import React from 'react';
import classNames from 'classnames';
import { Icon } from 'antd';
import intl from 'react-intl-universal';

import currencySymbolMap from 'common/currencySymbolMap';
import styles from './AgencyGroup.less';
import DSersAlert from '../DSersAlert';

const InPaymentList = React.memo(({ data }) => {
  const { agencyInfo } = data;
  const paymentCurrency = data.currency;
  const paymentAmount = data.amount;

  const alertDesc = (
    <div className={styles.rollingMessage}>
      <Icon style={{ fontSize: 24, marginRight: 16 }} type="sync" spin />
      <div>
        {intl.get('order.agency_orders.payment.agency_inpayment_tip')}
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.agencyInfo)}>
      <div className={styles.agencyInfoRow}>
        <div className={styles.infoItem}>
          {intl.get('order.agency_orders.payment.agency_info_name')}:{' '}
          <span className={styles.boldText}>{agencyInfo?.agency_name}</span>
        </div>
        <div className={styles.infoItem}>
          {intl.get('order.agency_orders.payment.agency_info_amount_v2')}:{' '}
          <span
            className={classNames(styles.amountText, {
              [styles.error]: !parseFloat(paymentAmount)
            })}
          >
            {currencySymbolMap[paymentCurrency]} {paymentAmount}
          </span>
        </div>
      </div>
      <div className={styles.agencyInfoRow}>
        <div className={styles.infoItem}>
        {intl.get('order.agency_orders.payment.agency_info_paypal')}:{' '}
          <span className={styles.boldText}>{agencyInfo?.pay_pal}</span>
        </div>
      </div>

      <DSersAlert
        visible
        type="info"
        showIcon={false}
        description={alertDesc}
      />
    </div>
  );
});

export default InPaymentList;
