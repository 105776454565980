import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Icon } from 'antd';
import { saveAs } from 'file-saver';
import intl from 'react-intl-universal'

import { sleep } from 'routes/AgencyOrder/util';
import DSersAlert from '../DSersAlert';
import { getTaskInfo, getTasks, setTasks } from './utils';
import styles from './RollingDownloadResult.less';
import { AGENCY_EVENT_CHECK_EXPORT_ORDER_TASK } from '../constants';

const alertTypeMap = {
  1: 'info',
  2: 'success',
  3: 'error'
};

const saveFile = async (url, name) => {
  let response = await fetch(url);
  let blob = await response.blob();
  let objectUrl = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = objectUrl;
  a.download = name;
  a.click();
  a.remove();
};

const RollingDownloadResult = () => {
  const [allTasks, setAllTasks] = useState([]);

  const tasksRef = useRef(allTasks);

  const unmountRef = useRef(false);

  const checkTask = async () => {
    // 还有任务在执行 不再重复执行
    if (tasksRef.current?.find(i => ![2, 3].includes(i.status))) {
      return;
    }
    // 获取本地缓存的任务
    let cacheTasks = getTasks();
    // 同步到组件数据
    tasksRef.current = cacheTasks;

    setAllTasks(cacheTasks);

    // 过滤需要跑任务的数据
    cacheTasks = cacheTasks.filter(i => ![2, 3].includes(i.status));

    // setTasks(cacheTasks);
    while (cacheTasks.length && !unmountRef.current) {
      const taskRes = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const task of cacheTasks) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const taskInfo = await getTaskInfo({ taskId: task.id });
          if (taskInfo?.id) {
            taskRes.push(taskInfo);
            if (
              taskInfo.status === 2 &&
              taskInfo.file_url &&
              taskInfo.file_name
            ) {
              saveAs(taskInfo.file_url, taskInfo.file_name);
              // saveFile(taskInfo.file_url, taskInfo.file_name);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
      const processingTasks = tasksRef.current;

      processingTasks.forEach(i => {
        const resObj = taskRes.find(res => res?.id === i.id);
        if (resObj) {
          Object.assign(i, resObj);
        }
      });

      const currentCachedTasks = getTasks();

      currentCachedTasks.forEach(i => {
        const exsits = processingTasks.find(
          processingTask => processingTask.id === i.id
        );
        if (!exsits) {
          processingTasks.push(i);
        }
      });

      tasksRef.current = processingTasks;
      setAllTasks([...processingTasks]);
      setTasks(processingTasks);

      cacheTasks = processingTasks.filter(i => ![2, 3].includes(i.status));
      if (cacheTasks.length === 0) {
        setTasks([]);
        break;
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(5 * 1000);
    }
  };
  useEffect(() => {
    checkTask();
    window.EVENT.on(AGENCY_EVENT_CHECK_EXPORT_ORDER_TASK, checkTask);
    return () => {
      window.EVENT.off(AGENCY_EVENT_CHECK_EXPORT_ORDER_TASK, checkTask);
      unmountRef.current = true;
    };
  }, []);

  const taskStatus = useMemo(() => {
    // return 1
    if (!allTasks.length) return 0;
    console.log(
      allTasks.find(i => ![2, 3].includes(i.status)),
      'rolling status'
    );
    if (allTasks.find(i => ![2, 3].includes(i.status))) {
      return 1;
    }
    if (allTasks.find(i => i.status === 3)) return 3;
    return 2;
  }, [allTasks]);

  console.log(allTasks);
  const handleDownload = () => {
    console.log(allTasks);
    allTasks.forEach(i => {
      saveAs(i.file_url, i.file_name);
    });
  };
  const alertMessage = useMemo(() => {
    if (taskStatus === 1) {
      return (
        <div className={styles.rollingMessage}>
          <Icon style={{ fontSize: 24, marginRight: 16 }} type="sync" spin />
          {intl.get('order.agency_orders.exporting_orders')}
        </div>
      );
    }
    if (taskStatus === 2) {
      return (
        <div className={styles.rollingMessage}>
          <span>Export orders success</span>
          <a
            role="button"
            tabIndex="-1"
            style={{ marginLeft: 16 }}
            target="_blank"
            download={allTasks[0]?.file_name}
            href={allTasks[0]?.file_url}
            // onClick={handleDownload}
          >
            {intl.get('order.agency_orders.export_download_link_title')}
          </a>
        </div>
      );
    }
    if (taskStatus === 3) {
      return (
        <div className={styles.rollingMessage}>
          {allTasks[0]?.order_export_type === 2 && allTasks[0]?.err_code === 2
            ? intl.get('order.agency_orders.export_failed_empty')
            : intl.get('order.agency_orders.export_failed')}
        </div>
      );
    }
  }, [taskStatus]);

  const handleClose = () => {
    // closable自带过度效果 延迟执行等过度完毕
    setTimeout(() => {
      setAllTasks([]);
    }, 300);
  };

  console.log(taskStatus, '------------ task status');
  return (
    <DSersAlert
      closable={taskStatus !== 1}
      // closable={false}
      onClose={handleClose}
      showIcon={taskStatus !== 1}
      visible={taskStatus !== 0}
      type={alertTypeMap[taskStatus]}
      description={alertMessage}
      className={styles.globalAlert}
    />
  );
};

export default React.memo(RollingDownloadResult);
