import { getOrderShowData, supplyPlatformEquals } from "routes/AgencyOrder/util";
import { getOrderList } from "services/order";
import { Post } from "utils/request";

export const getAgencyPaymentGroupsFromSelectedOrders = (data, agencyAuthStatus) => {
  const agencys = [];
  const agencyGroupMap = {};
  const agencyCurrencyMap = {};

  data?.forEach(i => {
    i.childrens.forEach(child => {
      // item黑名单忽略
      if (child.is_black_list === 2) return;
      // item不是agency订单忽略
      if (!supplyPlatformEquals(child.platform_type, 5)) {
        return;
      }
      if(agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status) {
        return;
      }
      const agencySp = child.sp?.[0];
      // agency订单的第一个sp不对劲 忽略这个item
      if (
        agencySp?.status !== 3 ||
        !agencySp?.sale_id ||
        !agencySp?.platform_order_id
      ) {
        return;
      }
      const agencyId = agencySp.sale_id;
      if (!agencyGroupMap[agencyId]) {
        agencys.push(agencyId);
        agencyGroupMap[agencyId] = [{ ...i, paymentChildren: [{ ...child }] }];
        agencyCurrencyMap[agencyId] = [child.lineItemAgencyPaymentCurrency];
      } else {
        const agencyGroup = agencyGroupMap[agencyId];
        const orderExsits = agencyGroup.find(
          groupedOrder => groupedOrder.id === i.id
        );
        if (orderExsits) {
          orderExsits.paymentChildren.push({ ...child });
        } else {
          agencyGroup.push({ ...i, paymentChildren: [{ ...child }] });
        }
        agencyCurrencyMap[agencyId].push(child.lineItemAgencyPaymentCurrency);
      }
    });
  });

  const initedGroups = agencys.map(i => {
    return {
      data: agencyGroupMap[i],
      agency_id: i,
      agencyCurrencys: Array.from(new Set(agencyCurrencyMap[i]))
    };
  });

  return initedGroups;
};

export const couldShowPaymentConfirm = (data, agencyAuthStatus) => {
  const initedGroups = getAgencyPaymentGroupsFromSelectedOrders(data, agencyAuthStatus);
  if (!initedGroups.length) return false;
  for (const agencyGroup of initedGroups) {
    if (
      agencyGroup.agencyCurrencys.length !== 1 ||
      !agencyGroup.agencyCurrencys[0]
    ) {
      return false;
    }
  }
  return true;
};

export const shouldShowPaymentTip = (data, childId, agencyAuthStatus) => {
  return !!data?.find(i => {
    return i?.childrens?.find(child => {
      if (child.is_black_list === 2) return;
      // item不是agency订单忽略
      if (!supplyPlatformEquals(child.platform_type, 5)) {
        return;
      }
      const agencySp = child.sp?.[0];
      // agency订单的第一个sp不对劲 忽略这个item
      if (
        agencySp?.status !== 3 ||
        !agencySp?.sale_id ||
        !agencySp?.platform_order_id
      ) {
        return;
      }

      if(agencyAuthStatus && agencyAuthStatus !== child.agency_auth_status) {
        return;
      }

      if(childId && childId !== child.id) return;

      return child.agency_auth_status === 1;
    })
  })
}

export const getOrderDataByOrderId = async ({ orderIdArray, status, userInfo, types }) => {
  try {
    const params = {
      order_ids: orderIdArray,
      status: status,
      store_id: ''
    }

    const res = await getOrderList({ data: params });
    // console.log(res);
    if(res.code === 2000 && res?.data?.Orders?.orders?.length) {
      const orderShowData = getOrderShowData(res.data, status, types, userInfo);
      return orderShowData;
    }
    return [];
  } catch (error) {
    return [];
  }
}

// 根据订单数据获取payment detail （有坑，可能造成渲染错误，因为并不是订单快照而是实时订单数据）
export const getPaymentDetailByOrderShowData = ({data, filterAgencyId, agencyOrderIdArray = []}) => {

  const agencys = [];
  const agencyGroupMap = {};
  const agencyCurrencyMap = {};

  data?.forEach(i => {
    i.childrens.forEach(child => {
      // item黑名单忽略
      if (child.is_black_list === 2) return;
      // item不是agency订单忽略
      if (!supplyPlatformEquals(child.platform_type, 5)) {
        return;
      }
      const agencySp = child.sp?.[0];
      // agency订单的第一个sp不对劲 忽略这个item
      
      if (
        agencySp?.status < 3 ||
        agencySp?.sale_id !== `${filterAgencyId}` ||
        !agencyOrderIdArray.includes(agencySp?.platform_order_id)
      ) {
        return;
      }
      const agencyId = agencySp.sale_id;
      if (!agencyGroupMap[agencyId]) {
        agencys.push(agencyId);
        agencyGroupMap[agencyId] = [{ ...i, paymentChildren: [{ ...child }] }];
        agencyCurrencyMap[agencyId] = [child.lineItemAgencyPaymentCurrency];
      } else {
        const agencyGroup = agencyGroupMap[agencyId];
        const orderExsits = agencyGroup.find(
          groupedOrder => groupedOrder.id === i.id
        );
        if (orderExsits) {
          orderExsits.paymentChildren.push({ ...child });
        } else {
          agencyGroup.push({ ...i, paymentChildren: [{ ...child }] });
        }
        agencyCurrencyMap[agencyId].push(child.lineItemAgencyPaymentCurrency);
      }
    });
  });

  const initedGroups = agencys.map(i => {
    return {
      data: agencyGroupMap[i],
      agency_id: i,
      agencyCurrencys: Array.from(new Set(agencyCurrencyMap[i]))
    };
  }).filter(i => i.agency_id === filterAgencyId);
  
  return initedGroups[0];
}
